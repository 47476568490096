import { all } from 'redux-saga/effects'
import { credentialsSaga } from '../credentials'
import { authSaga } from '../auth'
import { apiSaga } from '../api'
import { toastrSaga } from '../toastr'
import { definitionSaga } from '../definition'
import { strategySaga } from '../strategy'
import { historySaga } from '../history'
import { backtestSaga } from '../backtest'
import { priceSaga } from '../price'
import { graphSaga } from '../graph'
import { homeSaga } from '../app/home'
import { dashboardSaga } from '../app/dashboard'
import { logSaga } from '../log'
import { creditsSaga } from '../credits'
import { contactSaga } from '../contact'
import { preferencesSaga } from '../preferences'
import { userSaga } from '../user'

export default function* root() {
  yield all([
    apiSaga(),
    toastrSaga(),
    homeSaga(),
    preferencesSaga(),
    creditsSaga(),
    credentialsSaga(),
    contactSaga(),
    definitionSaga(),
    strategySaga(),
    userSaga(),
    backtestSaga(),
    historySaga(),
    priceSaga(),
    graphSaga(),
    logSaga(),
    dashboardSaga(),
    authSaga()
  ])
}
