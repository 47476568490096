import React from 'react'

const AboutScreen = ({ active, hideAbout, i18next }) => (
  <div className='modal fade in' id='tac' style={{ display: (active ? 'block' : 'none') }}>
    <div className='modal-dialog'>
      <div className='modal-content'>
        <div className='modal-header'>
          <center>
            <img
              style={{ width: '60%' }}
              src='/assets/img/logo.png'
              alt='Coin Acrobat'
            />
          </center>
        </div>
        <div className='modal-body'>
          {i18next.t('app.about.line1')}
          <br />
          {i18next.t('app.about.line2')}
&nbsp;
          <a
            href='https://s3.eu-central-1.amazonaws.com/com-coinacrobat/web/T%26Cs.pdf'
            target='_blank'
            rel='noopener noreferrer'
          >
            <i className='fa fa-download' />
          </a>
          <br />
          {i18next.t('app.about.line3')}
          <br />
          <br />

          {i18next.t('app.about.contact')}
:
          <a href='mailto:support@coinacrobat.com'>support@coinacrobat.com</a>
          <hr />
          <small>
            {i18next.t('app.about.company')}
          </small>
        </div>
        <div className='modal-footer'>
          <button
            type='button'
            onClick={hideAbout}
            className='btn btn-primary center-block'
            data-dismiss='modal'
          >
            {i18next.t('app.about.close')}
          </button>
        </div>
      </div>
    </div>
  </div>
)

export default AboutScreen
