import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'

const DefinitionFormScreen = ({ handleSubmit, saveDefinition, i18next }) => (
  <div>
    <section className='content-header'>
      <h1>
        {i18next.t('app.definition.form.title')}
        <small>{i18next.t('form')}</small>
      </h1>
    </section>

    <section className='content'>
      <div className='row'>
        <div className='col-md-12'>
          <div className='box box-primary'>
            <form onSubmit={handleSubmit(saveDefinition)}>
              <Field name='id' component='input' type='hidden' />
              <div className='box-body'>
                <div className='form-group'>
                  <label htmlFor='name'>{i18next.t('app.definition.form.name')}</label>
                  <Field
                    name='name'
                    className='form-control'
                    component='input'
                    type='text'
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='description'>{i18next.t('app.definition.form.description')}</label>
                  <Field
                    name='description'
                    className='form-control'
                    component='input'
                    type='text'
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='classBody'>{i18next.t('app.definition.form.classBody')}</label>
                  <Field name='classBody' className='form-control' component='textarea' rows='20' type='text' />
                </div>
              </div>

              <div className='box-footer'>
                <Link to='/definitions' className='btn btn-default'>
                  {i18next.t('cancel')}
                </Link>
                <button type='submit' className='btn btn-info pull-right'>
                  {i18next.t('save')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
)

export default reduxForm({ form: 'definition' })(DefinitionFormScreen)
