import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import GraphScreen from './graph.screen'
import { graphLoadTimeRangeAction, graphSetLineVisibilityAction, graphSetTimeRangeAction } from './graph.module'
import i18n from '../translations/i18n'


class GraphContainer extends PureComponent {
  render() {
    return (
      <GraphScreen {...this.props} />
    )
  }
}

const mapStateToProps = (state) => ({
  i18next: i18n(),
  exchangePairs: state.price.exchangePairs,
  graphsData: state.graphs,
  priceData: state.price.data
})
const mapDispatchToProps = (dispatch) => ({
  setLineVisibility: (graphName, code, value) => dispatch(graphSetLineVisibilityAction({ graphName, code, value })),
  setTimeRange: (name, values) => dispatch(graphSetTimeRangeAction(name, values[0], values[1])),
  loadTimeRange: (name, values) => dispatch(graphLoadTimeRangeAction(name, values[0], values[1]))
})
export default connect(mapStateToProps, mapDispatchToProps)(GraphContainer)
