import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import SidebarScreen from './sidebar.screen'
import i18n from '../../translations/i18n'

class SidebarContainer extends PureComponent {
  render() {
    return (
      <SidebarScreen {...this.props} />
    )
  }
}

const mapStateToProps = (state) => ({
  roles: state.auth.roles,
  i18next: i18n(),
  jwt: state.auth.loggedFromAuth0.token
})

export default connect(mapStateToProps, null)(SidebarContainer)
