import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import TopScreen from './top.screen'
import { logoutAction } from '../../auth'
import i18n, { changeLanguage } from '../../translations/i18n'
import { showAboutAction } from '../home/home.module'

class TopContainer extends PureComponent {
  render() {
    return (
      <TopScreen {...this.props} />
    )
  }
}

const findCreditsDetail = (state) => {
  if (state.credits.userCreditsMap[state.auth.userId] && state.credits.userCreditsMap[state.auth.userId].balance) {
    return state.credits.userCreditsMap[state.auth.userId]
  }
  return {
    userStatus: 'PASSIVE',
    balance: 0,
    monthlyHistory: {}
  }
}

const isImpersonating = (state) => {
  if (state.auth.loginAsUserId) {
    return true
  }
  return false
}

const mapStateToProps = (state) => ({
  i18next: i18n(),
  impersonating: isImpersonating(state),
  fullName: state.users.me.name,
  avatarUrl: state.users.me.avatarUrl,
  creditsDetail: findCreditsDetail(state)
})

const mapDispatchToProps = (dispatch) => ({
  changeLanguage,
  logout: () => dispatch(logoutAction()),
  showAbout: () => dispatch(showAboutAction(true))
})

export default connect(mapStateToProps, mapDispatchToProps)(TopContainer)
