import React from 'react'
import { Link } from 'react-router-dom'
import Confirm from '../../confirm'

const DefinitionListScreen = ({
  definitions, loadDefinitions, changeStatusDefinition, deleteDefinition, i18next
}) => (
  <div>
    <section className='content'>
      {definitions.map(d => (
        <div key={d.id}>
          <Confirm
            id={`confirm-delete-${d.id}`}
            title='Confirm remove'
            body={`Do you really want to remove definition ${d.name}?`}
            confirmAction={() => deleteDefinition(d.id)}
          />
          <Confirm
            id={`confirm-status-${d.id}`}
            title='Confirm status change'
            body={`Do you really want to ${d.status === 'ACTIVE' ? 'deactivate' : 'activate'} ${d.name} definition?`}
            confirmAction={() => changeStatusDefinition(d)}
          />
        </div>))}

      <div className='row'>
        <div className='col-md-6'>
          <div className='box box-info'>
            <div className='box-header'>
              <h3 className='box-title'>{i18next.t('app.definition.list.title')}</h3>
              <div className='box-tools pull-right'>
                <button type='button' className='btn btn-box-tool' onClick={() => loadDefinitions()}>
                  <i className='fa fa-refresh' />
                </button>
              </div>
            </div>
            <div className='box-body'>
              <div className='table-responsive'>
                <table className='table no-margin'>
                  <thead>
                    <tr>
                      <th>{i18next.t('app.definition.list.name')}</th>
                      <th>{i18next.t('app.definition.list.status')}</th>
                      <th>{i18next.t('actions')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {definitions.map(d => (
                      <tr key={d.id}>
                        <td>{d.name}</td>
                        <td>
                          {d.status === 'ACTIVE' ? i18next.t('active') : i18next.t('notActive')}
                          <button
                            type='button'
                            data-toggle='modal'
                            data-target={`#confirm-status-${d.id}`}
                            className='btn btn-box-tool'
                          >
                            <i className='fa fa-exchange' />
                          </button>
                        </td>
                        <td>
                          <Link to={`/definitions/form/${d.id}`} className='btn btn-box-tool'>
                            <i className='fa fa-edit' />
                          </Link>
                          <button
                            type='button'
                            data-toggle='modal'
                            data-target={`#confirm-delete-${d.id}`}
                            className='btn btn-box-tool'
                          >
                            <i className='fa fa-times' />
                          </button>
                        </td>
                      </tr>))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='box-footer clearfix'>
              <Link
                to='/definitions/form'
                className='btn btn-sm btn-info btn-flat pull-left'
              >
                {i18next.t('new')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

export default DefinitionListScreen
