const en = {
  translations: {
    pageTitle: 'Coin Acrobat',
    actions: 'Actions',
    new: 'New',
    form: 'Form',
    cancel: 'Cancel',
    save: 'Save',
    upload: 'Upload',
    active: 'Active',
    notActive: 'Not Active',
    send: 'Send',
    langs: {
      en: 'English',
      cs: 'čeština'
    },
    exchangePairs: {
      btcusd: 'BTC/USD',
      btceur: 'BTC/EUR',
      eurusd: 'EUR/USD',
      xrpusd: 'XRP/USD',
      xrpeur: 'XRP/EUR',
      xrpbtc: 'XRP/BTC',
      ltcusd: 'LTC/USD',
      ltceur: 'LTC/EUR',
      ltcbtc: 'LTC/BTC',
      ethusd: 'ETH/USD',
      etheur: 'ETH/EUR',
      ethbtc: 'ETH/BTC',
      bchusd: 'BCH/USD',
      bcheur: 'BCH/EUR',
      bchbtc: 'BCH/BTC'
    },
    currencies: {
      usd: 'USD',
      eur: 'EUR',
      btc: 'BTC'
    },
    top: {
      credit: 'Credit',
      loggedAs: 'Admin logged as'
    },
    homepage: {
      login: 'Login',
      logout: 'Logout',
      about: 'About',
      language: 'Language',
      howItWorks: {
        title: 'How does it work?',
        answer1: 'The robot evaluates investment opportunities every minute',
        answer2: 'You choose your preferred strategy',
        answer3: `Each strategy is explained in detail, with examples and you have an opportunity to test it
        first before investing real money`,
        answer4: 'Due to high frequency, the robot can work with very small volumes, starting from $5',
        answer5: `The robot automates the operations the human would do, but gets never tired, works 24 hours a day,
        365 days a year, does not eat, sleep and has no emotions to affect the rational decisions`,
        answer6: 'Register to see how simple it can be – simple math behind each strategy',
        registerBtn: 'Register'
      },
      widgets: {
        priceStats: {
          titleSufix: ' moved',
          period: 'in last 24 hours'
        },
        opportunities: {
          title: 'INVESTMENT OPPORTUNITIES PER DAY',
          investment: 'Potential investment every minute'
        }
      }
    },
    app: {
      menu: {
        mainNavigation: {
          title: 'MAIN NAVIGATION',
          dashboard: 'Dashboard',
          credentials: 'Bitstamp Credentials',
          strategies: 'My Strategies',
          contact: 'Contact',
          howto: 'How to ...'
        },
        adminNavigation: {
          title: 'ADMIN NAVIGATION',
          definitions: 'Strategy Definitions',
          uploadHistory: 'Upload History',
          users: 'Users'
        }
      },
      confirm: {
        submit: 'Confirm',
        cancel: 'Cancel'
      },
      validation: {
        ok: 'OK',
        credentialsInvalid: {
          title: 'Error',
          body: `Your Bitstamp API key seems to be invalid.
Can you please ensure you copy/paste the entire key and that you activated the API key through email validation?
It is the "Activate" button in Bitstamp security management area.`
        }
      },
      about: {
        line1: 'CoinAcrobat is technology platform for cryptocurrency trading via third party market.',
        line2: 'Usage is regulated by terms and conditions.',
        line3: 'The platform is created, maintained and operated by Osomahe s.r.o.',
        contact: 'Contact',
        company: `Osomahe s.r.o. is a company registered in Krajský soud v Ostravě.
              Registration Number: C 69138
              Registered office: kpt. Nálepky 962/13, 779 00 Olomouc`,
        close: 'Close'
      },
      tac: {
        title: 'Coin Acrobat Terms and Conditions of Use',
        content: `<p>I agree to the Coin Acrobat Terms and Conditions.&nbsp;
<a href="https://s3.eu-central-1.amazonaws.com/com-coinacrobat/web/T%26Cs.pdf"
target="_blank" rel="noopener noreferrer"><i class="fa fa-download" /></a></p>`,
        agree: 'I Agree',
        logout: 'Logout'

      },
      dashboard: {
        title: 'Dashboard',
        credentials: {
          title: 'Credentials',
          submit: 'Save my credentials',
          description: `Your account is currently missing valid Bitstamp credentials.
          We need those to connect you to the market and start trading.
          Please provide valid API key in the attached form.
          Don't worry, it is not difficult. We also created a video to make it really easy for you.`
        },
        strategy: {
          title: 'Strategy',
          submit: 'Start strategy',
          description: `In order to launch your first strategy we need some information. It only takes two minutes.
Please watch the video to see how your first strategy earns you money and fill the information in the form.
Before launching your strategy, make sure your Bitstamp subaccount contains enough funds in corresponding currency.`
        },
        credits: {
          title: 'Subscription activation',
          description: `We would love to start executing your strategy, but your account is currently in passive mode.
          Is it possible you have no active subscription yet? If so, you can subscribe in a minute,
          clicking on the PayPal button here. For more information watch the video.`
        },
        notifications: {
          title: 'Notifications',
          description: `Would you like to receive a notification each time we make a transaction?
It is just two clicks away.`,
          accept: 'I would like to receive notification emails',
          submit: 'Activate'
        },
        summary: {
          title: 'Summary',
          balances: 'Balances',
          totalValue: 'Total value',
          loading: 'Loading...'
        }
      },
      credentials: {
        list: {
          title: 'My Bitstamp Credentials',
          accountAlias: 'Account Alias',
          customerId: 'Customer Id',
          status: {
            name: 'Status',
            values: {
              UNKNOWN: 'Unknown',
              VALID: 'Valid',
              INVALID: 'Invalid'
            }
          }
        },
        form: {
          title: 'Credentials',
          accountAlias: 'Account Alias',
          customerId: 'Customer Id',
          apiKey: 'API Key',
          secretKey: 'Secret Key'
        }
      },
      contact: {
        form: {
          contactUs: 'Contact us',
          emailAddress: 'Email address',
          emailPlaceholder: 'Enter your email to which we can reply to',
          selectTopic: 'Select topic',
          errorReport: 'Error report',
          generalQuestion: 'General Question',
          improvementSuggestions: 'Improvement suggestion',
          creditIssue: 'Credit system issue',
          other: 'Other',
          message: 'Message'
        }
      },
      credits: {
        title: 'Credits',
        summary: 'Summary',
        userStatus: {
          name: 'User Status',
          values: {
            ACTIVE: 'Active',
            PASSIVE: 'Passive',
            undefined: 'Unknown'
          }
        },
        currentBalance: 'Current Balance',
        userId: 'User ID',
        topupAmount: 'Top Up Amount',
        topup: 'Top Up',
        monthlyHistory: {
          title: 'Monthly Balance',
          month: 'Month',
          gain: 'Gain',
          spend: 'Spend',
          sum: 'Sum',
          total: 'Total'
        },
        tac: `<p>Coin Acrobat Terms and Conditions.&nbsp;
      <a href="https://s3.eu-central-1.amazonaws.com/com-coinacrobat/web/T%26Cs.pdf"
      target="_blank" rel="noopener noreferrer"><i class="fa fa-download" /></a></p>`,
        howTitle: 'How does credit system work?',
        howBody: `
          <p>In order to give you the top quality service, Coin Acrobat operations is paid through credit system.</p>
          <p>The credit is consumed by two events:</p>
          <ul>
            <li><b>Monthly subscription amount</b> - each month Coin Acrobat will consume 747.00 credits, covering
              the overall
              operations of the subscription account, historical data, simlation through back testing, etc.
            </li>
            <li><b>Tick amount</b> - each successfully executed tick (e.g. each minute, hour,... depending on your
              strategy setup)
              will consume 0.017 credits, covering the computing power toexecute the strategy script, communication
              with the coin market, etc.
            </li>
          </ul>
          <p>Your account will be in <span class='label label-danger'>passive</span> state whenever the credit
            is not sufficient for the monthly subscription
            amount or whenever you run out of credit to cover for ticks. Because we know sometimes things take
            longer than planned, we will let your
            strategy run to small negative to give you enough time to top up your credit. Whenever there is enough
            credit at your account to run the
            strategy, your account will be in <span class='label label-success'>active</span> state.</p>
          <p>To top up your credit, please use the PayPal subscription in the Top Up panel. To estimate how much
            your strategy setup will consume,
            we prepared a few calculation examples, helping you to choose the right subscription plan:</p>
          <ul>
            <li><b>1 running strategy, hourly frequency:</b> 747 + (24 * 30.5 * 0.017) ~ <b>759 credits </b> per
              month.
            </li>
            <li><b>1 running strategy, minute frequency:</b> 747 + (24 * 60 * 30.5 * 0.017) ~ <b>1 494
              credits </b> per month.
            </li>
            <li><b>2 running strategies, minute frequency:</b> 747 + 2 * (24 * 30.5 * 0.017) ~ <b>2 241
              credits </b> per month.
            </li>
            <li><b>5 running strategies, minute frequency:</b> 747 + 5 * (24 * 30.5 * 0.017) ~ <b>4 482
              credits </b> per month.
            </li>
          </ul>
        `,
        paypal: {
          821: '821 credits : €10.99 EUR - monthly',
          1494: '1494 credits : €19.98 EUR - monthly',
          2241: '2241 credits : €29.97 EUR - monthly',
          4482: '4482 credits : €59.94 EUR - monthly'
        }
      },
      strategy: {
        common: {
          name: 'Name',
          definition: 'Algorithm',
          frequency: {
            name: 'Frequency',
            values: {
              MINUTE: 'Minute',
              HOUR: 'Hour',
              DAY: 'Day',
              HOURS12: '12 hours',
              HOURS8: '8 hours',
              HOURS6: '6 hours',
              HOURS4: '4 hours'
            }
          },
          status: {
            name: 'Status',
            values: {
              RUNNING: 'Running',
              STOPPED: 'Stopped'
            }
          }
        },
        list: {
          title: 'My Strategies'
        },
        form: {
          title: 'Strategy',
          subTitle: 'Setup strategy',
          legend: 'Main',
          namePlaceholder: 'Strategy name',
          credentials: 'Bitstamp Credentials',
          parameters: 'Parameters',
          backTest: 'Backtest',
          start: 'Start',
          end: 'End',
          balance: 'balance',
          runBackTest: 'Run Backtest',
          downloadLog: 'Download full log'
        },
        summary: {
          deleteTitle: 'Confirm delete',
          deleteBody: 'Do you really want to remove strategy {{name}}?',
          statusChangeTitle: 'Confirm status change',
          startBody: 'Do you really want to start {{name}} strategy?',
          stopBody: 'Do you really want to stop {{name}} strategy?',
          stop: 'Stop',
          start: 'Start',
          detail: 'Detail',
          edit: 'Edit',
          delete: 'Delete',
          status: 'Status',
          algorithm: 'Algorithm',
          frequency: 'Frequency',
          totalValue: 'Total value',
          balances: 'Balances'
        }
      },
      definition: {
        list: {
          title: 'Strategy Definitions',
          name: 'Name',
          description: 'Description',
          status: 'Status'
        },
        form: {
          title: 'Strategy Definition',
          name: 'Name',
          description: 'Description',
          classBody: 'Class body'
        }
      },
      uploadHistory: {
        title: 'Tick history upload',
        exchangePair: 'Exchange pair',
        historyFile: 'History file',
        historyFileHelp: 'CSV file with format: epoch;price'
      },
      howto: {
        video01: 'Connect to Bitstamp',
        video02: 'First Trading strategy',
        video03: 'Account activation',
        youtube: 'Youtube play'
      },
      user: {
        list: {
          title: 'Users',
          id: 'Id',
          name: 'Name',
          email: 'Email',
          status: 'Status',
          balance: 'Balance',
          lastLogin: 'Last login',
          loginAs: 'Login as'
        }
      }
    }
  }
}

export default en
