import React from 'react'
import { Field, reduxForm } from 'redux-form'

const CreditsSearchScreen = ({
  handleSubmit, search, isAdmin, loggedUserId
}) => (
  (isAdmin
  && (
  <form onSubmit={handleSubmit(search)} className='sidebar-form'>
    <div className='input-group'>
      <Field type='text' component='input' name='userId' className='form-control' />
      <span className='input-group-btn'>
        <button type='submit' name='search' id='search-btn' className='btn btn-flat'>
          <i className='fa fa-search' />
        </button>
      </span>
    </div>
  </form>
  )
  )
  || (
    <span>{loggedUserId}</span>
  )
)

export default reduxForm({ form: 'creditSearch' })(CreditsSearchScreen)
