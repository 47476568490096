import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import CreditsTopupScreen from './credits-topup.screen'
import i18n from '../../translations/i18n'
import { creditsTopupAction } from '../credits.module'

class CreditsTopupContainer extends PureComponent {
  render() {
    return (
      <div>
        <CreditsTopupScreen {...this.props} />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  initialValues: { amountInCredits: 1024 },
  i18next: i18n(),
  isAdmin: state.auth.roles && state.auth.roles.includes('admin')
})

const mapDispatchToProps = (dispatch) => ({
  topupAction: (values) => dispatch(creditsTopupAction(values.amountInCredits))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreditsTopupContainer)
