import moment from 'moment'

const usdFormat = new Intl.NumberFormat([], {
  style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0
})
export const formatUsd = (number) => usdFormat.format(number)

const currencyNumberFormat = new Intl.NumberFormat([], { minimumFractionDigits: 0, maximumFractionDigits: 2 })
export const formatCurrencyNumber = (number) => currencyNumberFormat.format(number)

const currencyNumberBetterFormat = new Intl.NumberFormat(
  [],
  { minimumFractionDigits: 0, maximumSignificantDigits: 5 }
)
export const formatCurrencyNumberBetter = (number) => currencyNumberBetterFormat.format(number)

export const formatDateTime = (epoch) => moment.unix(epoch).format('lll')
export const formatDate = (epoch) => moment.unix(epoch).format('L')

export const formatDateTimeIso = (epoch) => moment.unix(epoch).format('YYYY-MM-DD HH:mm:ss')
export const formatDateTimeForInput = (dateTime) => dateTime.format('YYYY-MM-DD[T]HH:mm')

export const getFromLocalStorage = (key, defaultValue) => {
  const saved = localStorage.getItem(key)
  if (saved) {
    return JSON.parse(saved)
  }
  return defaultValue
}

export const saveToLocalStorage = (key, object) => {
  localStorage.setItem(key, JSON.stringify(object))
}

export const removeFromLocalStorage = (key) => {
  localStorage.removeItem(key)
}

function delay(millis) {
  const promise = new Promise(resolve => {
    setTimeout(() => resolve(true), millis)
  })
  return promise
}

export default delay
