import React from 'react'
import {
  Cell, Pie, PieChart, ResponsiveContainer, AreaChart, CartesianGrid, XAxis, YAxis, Tooltip, Area
} from 'recharts'
import moment from 'moment'

const formatNumber = (num, fractions = 4) => new Intl.NumberFormat(navigator.languages, { maximumFractionDigits: fractions }).format(num)

const colors = ['#6878aa', '#254170', '#9dcddb', '#a26769', '#e28766', '#5a7d7c', '#8cba80', '#9a879d',
  '#7a3b69', '#563440', '#9c0d38 ']

const RADIAN = Math.PI / 180

const renderLabel = (input) => {
  const {
    cx, cy, midAngle, innerRadius, outerRadius, valueInCurrency, currency, index
  } = input
  const radius = 20 + innerRadius + (outerRadius - innerRadius) * 1
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)
  return (
    <text x={x} y={y} fill={colors[index]} textAnchor={x > cx ? 'start' : 'end'}>
      {`${formatNumber(valueInCurrency)} ${currency}`}
    </text>
  )
}


const usdFormat = new Intl.NumberFormat([], {
  style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0
})
const formatUsd = (number) => `$${usdFormat.format(number)}`

const decimalFormat = new Intl.NumberFormat([], {
  style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 5
})
const formatTooltip = (value, name, props) => {
  if (value && name) {
    const key = name.substr(0, 3)
    return [`(${formatUsd(props.payload[name])})`, `${decimalFormat.format(props.payload[key])} ${key.toUpperCase()}`]
  }
  return []
}

const tooltipTitle = (epoch, data) => {
  let sum = 0
  data.forEach(item => {
    if (item.epochSecond === epoch) {
      Object.keys(item).filter(name => name.endsWith('InUsd')).forEach(name => {
        sum += item[name]
      })
    }
  })
  return `${moment.unix(epoch).format('YYYY-MM-DD HH:mm')} (${formatUsd(sum)})`
}
const getMaxY = (history) => {
  let max = 0
  history.data.filter(item => item.epochSecond > history.from).forEach(item => {
    const sum = Object.keys(item).filter(name => name.endsWith('InUsd')).map(name => item[name]).reduce((sum, x) => sum + x)
    if (sum > max) {
      max = sum
    }
  })
  return max * 1.1
}

const DashboardSummaryScreen = ({
  data, portfolio, history, summaryPeriod, changeSummaryPeriod, i18next
}) => {
  if (data && data.length > 0) {
    return (
      <div className='col-md-12'>
        <div className='box box-info'>
          <div className='box-header with-border'>
            <h3 className='box-title'>{i18next.t('app.dashboard.summary.title')}</h3>
          </div>
          <div className='box-body'>

            <div className='col-md-4'>
              <div className='col-md-12'>
                <ResponsiveContainer width='100%' height={250}>
                  <PieChart height={250}>
                    <Pie data={data} dataKey='valueInUsd' outerRadius='60%' isAnimationActive={false} label={renderLabel}>
                      {
                          data.map((entry, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <Cell key={`cell-${index}`} fill={colors[index]} />
                          ))
                        }
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='col-md-12'>
                <label className='col-md-6'>{i18next.t('app.dashboard.summary.balances')}</label>
                <div className='col-md-6' />
              </div>
              {data.map(item => (
                <div className='col-md-12' key={`summary-balances-${item.currency}`}>
                  <label className='col-md-6' />
                  <div className='col-md-6'>{`${item.currency.toUpperCase()}: ${formatNumber(item.valueInCurrency)}`}</div>
                </div>
              ))}
            </div>

            <div className='col-md-4'>
              <div className='col-md-12'>
                <label className='col-md-6'>{i18next.t('app.dashboard.summary.totalValue')}</label>
                <div className='col-md-6' />
              </div>
              <div className='col-md-12'>
                <label className='col-md-6' />
                <div className='col-md-6'>
                  USD:&nbsp;
                  {formatNumber(portfolio.usd, 2)}
                </div>
              </div>
              <div className='col-md-12'>
                <label className='col-md-6' />
                <div className='col-md-6'>
                  EUR: &nbsp;
                  {formatNumber(portfolio.eur, 2)}
                </div>
              </div>
              <div className='col-md-12'>
                <label className='col-md-6' />
                <div className='col-md-6'>
                    BTC: &nbsp;
                  {formatNumber(portfolio.btc, 5)}
                </div>
              </div>
            </div>
            { history && history.data && history.data.length > 1 && (
            <div className='col-md-8' style={{ paddingTop: '1em' }}>
              <div className='col-md-12'>
                <div className='box-header with-border'>
                  <div className='btn-group pull-right' id='realtime' data-toggle='btn-toggle'>
                    <button
                      type='button'
                      className={summaryPeriod === '1D' ? 'btn btn-default btn-xs active' : 'btn btn-default btn-xs'}
                      data-toggle={summaryPeriod === '1D' ? 'on' : 'off'}
                      onClick={() => changeSummaryPeriod('1D')}
                    >
                      1D
                    </button>
                    <button
                      type='button'
                      className={summaryPeriod === '1W' ? 'btn btn-default btn-xs active' : 'btn btn-default btn-xs'}
                      data-toggle={summaryPeriod === '1W' ? 'on' : 'off'}
                      onClick={() => changeSummaryPeriod('1W')}
                    >
                      1W
                    </button>
                    <button
                      type='button'
                      className={summaryPeriod === '1M' ? 'btn btn-default btn-xs active' : 'btn btn-default btn-xs'}
                      data-toggle={summaryPeriod === '1M' ? 'on' : 'off'}
                      onClick={() => changeSummaryPeriod('1M')}
                    >
                      1M
                    </button>
                    <button
                      type='button'
                      className={summaryPeriod === '3M' ? 'btn btn-default btn-xs active' : 'btn btn-default btn-xs'}
                      data-toggle={summaryPeriod === '3M' ? 'on' : 'off'}
                      onClick={() => changeSummaryPeriod('3M')}
                    >
                      3M
                    </button>
                  </div>
                </div>
                <ResponsiveContainer width='100%' height={150}>
                  <AreaChart
                    height={150}
                    data={history.data}
                    margin={{
                      top: 10, right: 30, left: 0, bottom: 0
                    }}
                  >
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis
                      type='number'
                      allowDataOverflow
                      dataKey='epochSecond'
                      tickFormatter={(epoch) => moment.unix(epoch).format('YYYY-MM-DD')}
                      tickCount={10}
                      domain={[history.from, history.to]}
                    />
                    <YAxis
                      type='number'
                      allowDataOverflow
                      domain={[0, getMaxY(history)]}
                      tickFormatter={formatUsd}
                    />
                    <Tooltip separator=' ' labelFormatter={(epoch) => tooltipTitle(epoch, history.data)} formatter={formatTooltip} />
                    {
                      history.currencies.map((currency, index) => (
                        <Area
                          key={`history-area-${currency}`}
                          type='monotone'
                          dataKey={`${currency}InUsd`}
                          stackId='1'
                          stroke={colors[index]}
                          fill={colors[index]}
                        />
                      ))
                    }
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>
            )}
          </div>
        </div>
      </div>
    )
  } if (data) {
    return (<div />)
  }
  return (
    <div className='col-md-12'>
      <div className='box box-info'>
        <div className='box-header with-border'>
          <h3 className='box-title'>{i18next.t('app.dashboard.summary.title')}</h3>
        </div>
        <div className='box-body'>
          <div className='col-md-12'>
            <center>
              <i className='fa fa-spinner fa-spin' />
              &nbsp;
              {i18next.t('app.dashboard.summary.loading')}
            </center>
          </div>
        </div>
      </div>
    </div>
  )
}
export default DashboardSummaryScreen
