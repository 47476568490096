import React from 'react'
import { reduxForm, Field } from 'redux-form'
import ReactPlayer from 'react-player'

const DashboardCredentialsScreen = ({
  active, handleSubmit, submitAction, i18next, inProgress
}) => active && (
  <form onSubmit={handleSubmit(submitAction)}>
    <div className='col-md-12'>
      <div className='small-box bg-yellow'>
        <div className='inner'>
          <div className='box-header bg-yellow'>
            <h4 style={{ margin: 0 }}>
              <i className='fa fa-warning' />
&nbsp;
              {i18next.t('app.dashboard.credentials.title')}
            </h4>
          </div>
          <div className='col-md-4'>
            {i18next.t('app.dashboard.credentials.description')}
          </div>
          <div className='col-md-4'>
            <div className='form-group'>
              <Field
                name='customerId'
                className='form-control'
                component='input'
                type='text'
                placeholder='Customer ID'
                required
              />
            </div>
            <div className='form-group'>
              <Field
                name='apiKey'
                className='form-control'
                component='input'
                type='text'
                placeholder='API key'
                required
              />
            </div>
            <div className='form-group'>
              <Field
                name='secretKey'
                className='form-control'
                component='input'
                type='text'
                placeholder='Secret key'
                required
              />
            </div>
          </div>
          <div className='col-md-4'>
            <ReactPlayer
              url='https://www.youtube.com/watch?v=m3W2_84lwSI&list=PLLDFInTMdfLA_0dGj6TLEcVOZUYTTeoyg'
              width='250px'
              height='133px'
              controls
            />
          </div>

        </div>

        <span className='small-box-footer' style={{ clear: 'both' }}>
          <button
            type='submit'
            className='btn btn-success btn-flat small-box-footer'
            disabled={inProgress}
            style={{ padding: '0.1em 1em' }}
          >
            {inProgress
              ? <i className='fa fa-spinner fa-spin' />
              : <span>{i18next.t('app.dashboard.credentials.submit')}</span>}
          </button>
        </span>
      </div>
    </div>

  </form>
)


export default reduxForm({
  form: 'dashboard-credentials'
})(DashboardCredentialsScreen)
