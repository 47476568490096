import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'
import { BacktestForm, BacktestInfo } from '../../backtest'
import { Graph } from '../../graph'
import { Log } from '../../log'

const getParams = (readForm, definitions) => {
  if (definitions && readForm && readForm.definitionId) {
    const definition = definitions.find(d => d.id === readForm.definitionId)
    if (definition) {
      return definition.params
    }
  }
  return []
}

const getDefinitionDescription = (readForm, definitions) => {
  if (definitions && readForm && readForm.definitionId) {
    const definition = definitions.find(d => d.id === readForm.definitionId)
    if (definition) {
      return definition.description
    }
  }
  return undefined
}

const DefinitionParamField = ({ definitionParam, definitionId }) => {
  if (definitionParam.possibleValues && definitionParam.possibleValues.length > 0) {
    return (
      <div className='form-group'>
        <label htmlFor={`params.${definitionId}.${definitionParam.key}`}>{definitionParam.name}</label>
        <Field
          id={`params.${definitionId}.${definitionParam.key}`}
          name={`params.${definitionId}.${definitionParam.key}`}
          className='form-control'
          component='select'
          value={definitionParam.value}
          type='select'
        >
          {definitionParam.possibleValues.map(val => <option key={val} value={val}>{val}</option>)}
        </Field>
        <p className='help-block'>{definitionParam.description}</p>
      </div>
    )
  }
  return (
    <div className='form-group'>
      <label htmlFor={`params.${definitionId}.${definitionParam.key}`}>{definitionParam.name}</label>
      <Field
        id={`params.${definitionId}.${definitionParam.key}`}
        name={`params.${definitionId}.${definitionParam.key}`}
        className='form-control'
        component='input'
        value={definitionParam.value}
        type='text'
      />
      <p className='help-block'>{definitionParam.description}</p>
    </div>
  )
}

const StrategyFormScreen = ({
  definitions, credentials, i18next, readForm, credentialsChange, handleSubmit, saveStrategy
}) => (
  <div>
    <section className='content-header'>
      <h1>
        {i18next.t('app.strategy.form.title')}
        <small>{i18next.t('app.strategy.form.subTitle')}</small>
      </h1>
    </section>

    <section className='content'>
      <p>{}</p>
      <div className='row'>
        <div className='col-md-4'>
          <div className='box box-primary'>
            <form>
              <Field name='id' component='input' type='hidden' />
              <Field name='definitionId' component='input' type='hidden' />
              <div className='box-body'>
                <fieldset className='form-group'>
                  <legend>{i18next.t('app.strategy.form.legend')}</legend>
                  <div className='form-group'>
                    <label htmlFor='definitionId'>{i18next.t('app.strategy.common.definition')}</label>
                    <Field
                      name='definitionId'
                      className='form-control'
                      component='select'
                    >
                      {definitions.map(d => <option key={d.id} value={d.id}>{d.name}</option>)}
                    </Field>
                    <p className='help-block'>{getDefinitionDescription(readForm, definitions)}</p>
                  </div>
                  <div className='form-group'>
                    <label htmlFor='name'>{i18next.t('app.strategy.common.name')}</label>
                    <Field
                      name='name'
                      className='form-control'
                      component='input'
                      placeholder={i18next.t('app.strategy.form.namePlaceholder')}
                      type='text'
                    />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='frequency'>{i18next.t('app.strategy.common.frequency.name')}</label>
                    <Field name='frequency' className='form-control' component='select'>
                      <option value='MINUTE'>{i18next.t('app.strategy.common.frequency.values.MINUTE')}</option>
                      <option value='HOUR'>{i18next.t('app.strategy.common.frequency.values.HOUR')}</option>
                      <option value='DAY'>{i18next.t('app.strategy.common.frequency.values.DAY')}</option>
                      <option value='HOURS12'>{i18next.t('app.strategy.common.frequency.values.HOURS12')}</option>
                      <option value='HOURS8'>{i18next.t('app.strategy.common.frequency.values.HOURS8')}</option>
                      <option value='HOURS6'>{i18next.t('app.strategy.common.frequency.values.HOURS6')}</option>
                      <option value='HOURS4'>{i18next.t('app.strategy.common.frequency.values.HOURS4')}</option>
                    </Field>
                  </div>
                  <div className='form-group'>
                    <label htmlFor='credentialsId'>{i18next.t('app.strategy.form.credentials')}</label>
                    <Field
                      name='credentialsId'
                      className='form-control'
                      component='select'
                      onChange={(event) => credentialsChange(event.target.value)}
                    >
                      <option />
                      {credentials.map(cr => <option key={cr.id} value={cr.id}>{cr.accountAlias}</option>)}
                    </Field>
                  </div>
                </fieldset>
                <fieldset className='form-group'>
                  <legend>{i18next.t('app.strategy.form.parameters')}</legend>
                  {getParams(readForm, definitions)
                    .map(dp => (
                      <DefinitionParamField
                        key={dp.key}
                        definitionParam={dp}
                        definitionId={readForm.definitionId}
                      />
                    ))}
                </fieldset>

                <BacktestForm />

              </div>
              <div className='box-footer'>
                <Link to='/strategies' className='btn btn-default'>
                  {i18next.t('cancel')}
                </Link>
                <button
                  type='button'
                  onClick={handleSubmit(saveStrategy)}
                  className='btn btn-info pull-right'
                >
                  {i18next.t('save')}
                </button>
              </div>
            </form>
          </div>
        </div>

        <BacktestInfo />

        <div className='col-md-8'>
          <Graph name='backtest' />
        </div>
        <div className='col-md-8'>
          <Log name='backtest' />
        </div>
      </div>

    </section>
  </div>
)

export default reduxForm({ form: 'strategy' })(StrategyFormScreen)
