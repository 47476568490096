import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import CreditsPaypalScreen from './credits-paypal.screen'
import i18n from '../../translations/i18n'

class CreditsPaypalContainer extends PureComponent {
  render() {
    return (
      <div>
        <CreditsPaypalScreen {...this.props} />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  i18next: i18n(),
  invoice: `${state.auth.userId}::${Date.now()}`
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(CreditsPaypalContainer)
