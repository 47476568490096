import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import HomeScreen from './home.screen'
import { loginAction } from '../../auth'
import i18n, { changeLanguage } from '../../translations/i18n'
import { showAboutAction } from './home.module'


class HomeContainer extends PureComponent {
  render() {
    return (
      <HomeScreen {...this.props} />
    )
  }
}

const mapStateToProps = (state) => ({
  i18next: i18n(),
  priceStats: state.home.priceStats
})

const mapDispatchToProps = (dispatch) => ({
  login: () => dispatch(loginAction()),
  changeLanguage,
  showAbout: () => dispatch(showAboutAction(true))
})

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer)
