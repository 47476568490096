import moment from 'moment'
import {
  all, put, select, takeLatest
} from 'redux-saga/effects'


// ------------------------------------
// Constants
// ------------------------------------
const LOG_INIT = 'LOG_INIT'
const LOG_CLEAR = 'LOG_CLEAR'
const LOG_SET_LEVEL_VISIBILITY = 'LOG_SET_LEVEL_VISIBILITY'
const LOG_ADD_RECORDS = 'LOG_ADD_RECORDS'
const LOG_ADD_TRADES = 'LOG_ADD_TRADES'
const LOG_SELECT_EPOCH = 'LOG_SELECT_EPOCH'

// ------------------------------------
// Actions
// ------------------------------------
export const logInitAction = (payload) => ({
  type: LOG_INIT,
  payload
})
export const logClearAction = (name) => ({
  type: LOG_CLEAR,
  payload: {
    name
  }
})
export const logSetLevelVisibilityAction = (payload) => ({
  type: LOG_SET_LEVEL_VISIBILITY,
  payload
})
export const logAddTradesAction = (logName, tradeData) => ({
  type: LOG_ADD_TRADES,
  payload: {
    logName,
    tradeData
  }
})
export const logAddRecordsAction = (logName, logRecords) => ({
  type: LOG_ADD_RECORDS,
  payload: {
    logName,
    logRecords
  }
})
export const logSelectEpochAction = (logName, epoch) => ({
  type: LOG_SELECT_EPOCH,
  payload: {
    logName,
    epoch
  }
})

// ------------------------------------
// Sagas
// ------------------------------------
function* logSelectEpochSaga(action) {
  const { logName, epoch } = action.payload
  const { frequency, onSelectedEpochChange } = yield select((state) => state.logs[logName])
  if (onSelectedEpochChange) {
    const from = moment.unix(epoch).subtract(1, frequency === 'MINUTE' ? 'hours' : 'days').unix()
    const to = moment.unix(epoch).add(1, frequency === 'MINUTE' ? 'hours' : 'days').unix()
    yield put(onSelectedEpochChange(from, to))
  }
}

export function* logSaga() {
  yield all([
    yield takeLatest(LOG_SELECT_EPOCH, logSelectEpochSaga)
  ])
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}

// ------------------------------------
// Action Handlers
// ------------------------------------


const ACTION_HANDLERS = {
  [LOG_INIT]: (state, action) => {
    const newState = Object.assign({}, state)
    newState[action.payload.name] = {
      ...action.payload,
      visibleLevels: ['TRANSACTION', 'ERROR', 'WARN', 'INFO'],
      selectedEpoch: action.payload.to,
      records: [],
      trades: []
    }
    return newState
  },
  [LOG_CLEAR]: (state, action) => {
    const newState = {}
    Object.keys(state)
      .filter(key => key !== action.payload.name)
      .forEach(key => {
        newState[key] = Object.assign({}, state[key])
      })
    return newState
  },
  [LOG_SET_LEVEL_VISIBILITY]: (state, action) => {
    const newState = Object.assign({}, state)
    const { logName, level, value } = action.payload
    const logState = newState[logName]
    if (value && !logState.visibleLevels.includes(level)) {
      logState.visibleLevels.push(level)
    } else if (!value && logState.visibleLevels.includes(level)) {
      const index = logState.visibleLevels.indexOf(level)
      logState.visibleLevels.splice(index, 1)
    }
    return newState
  },
  [LOG_ADD_RECORDS]: (state, action) => {
    const newState = Object.assign({}, state)
    const { logName, logRecords } = action.payload
    newState[logName].records = logRecords
    return newState
  },
  [LOG_ADD_TRADES]: (state, action) => {
    const newState = Object.assign({}, state)
    const { logName, tradeData } = action.payload
    newState[logName].trades = tradeData
    return newState
  },
  [LOG_SELECT_EPOCH]: (state, action) => {
    const newState = Object.assign({}, state)
    const { logName, epoch } = action.payload
    const { frequency } = newState[logName]
    newState[logName].selectedEpochFrom = moment
      .unix(epoch)
      .subtract(1, frequency === 'MINUTE' ? 'hours' : 'days')
      .unix()
    newState[logName].selectedEpochTo = moment.unix(epoch).add(1, frequency === 'MINUTE' ? 'hours' : 'days').unix()
    return newState
  }
}

export default function (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
