import React from 'react'
import { Graph } from '../../graph'
import { Log } from '../../log'
import StrategySummary from '../summary'

const StrategyDetailScreen = ({ strategy }) => (
  <div>

    <section className='content'>

      <StrategySummary strategy={strategy} />
      <div className='col-md-12'>
        <Graph name='strategy' />
      </div>
      <div className='col-md-12'>
        <Log name='strategy' strategyId={strategy.id} />
      </div>
    </section>
  </div>
)
export default StrategyDetailScreen
