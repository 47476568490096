import React from 'react'
import ReactPlayer from 'react-player'
import i18n from '../translations/i18n'

const HowtoScreen = () => {
  const i18next = i18n()
  return (
    <div>
      <section className='content'>
        <div className='row'>

          <div className='col-md-6'>
            <div className='box'>
              <div className='box-header with-border'>
                <h3 className='box-title'>{i18next.t('app.howto.video01')}</h3>
                <a
                  href='https://www.youtube.com/watch?v=m3W2_84lwSI&list=PLLDFInTMdfLA_0dGj6TLEcVOZUYTTeoyg'
                  target='_blank'
                  className='btn btn-xs btn-default pull-right'
                  style={{ marginRight: '5px' }}
                  rel='noopener noreferrer'
                  title={i18next.t('app.howto.youtube')}
                >
                  <i className='fa fa-youtube-play' />
                </a>
              </div>
              <div className='box-body no-padding'>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='pad'>
                      <ReactPlayer
                        width='100%'
                        height='300px'
                        url='https://www.youtube.com/watch?v=m3W2_84lwSI&list=PLLDFInTMdfLA_0dGj6TLEcVOZUYTTeoyg'
                        controls
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-6'>
            <div className='box'>
              <div className='box-header with-border'>
                <h3 className='box-title'>{i18next.t('app.howto.video02')}</h3>
                <a
                  href='https://www.youtube.com/watch?v=ptk050dteRI&list=PLLDFInTMdfLA_0dGj6TLEcVOZUYTTeoyg'
                  target='_blank'
                  className='btn btn-xs btn-default pull-right'
                  style={{ marginRight: '5px' }}
                  rel='noopener noreferrer'
                  title={i18next.t('app.howto.youtube')}
                >
                  <i className='fa fa-youtube-play' />
                </a>
              </div>
              <div className='box-body no-padding'>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='pad'>
                      <ReactPlayer
                        width='100%'
                        height='300px'
                        url='https://www.youtube.com/watch?v=ptk050dteRI&list=PLLDFInTMdfLA_0dGj6TLEcVOZUYTTeoyg'
                        controls
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-6'>
            <div className='box'>
              <div className='box-header with-border'>
                <h3 className='box-title'>{i18next.t('app.howto.video03')}</h3>
                <a
                  href='https://www.youtube.com/watch?v=aUfjtWEhSEI&list=PLLDFInTMdfLA_0dGj6TLEcVOZUYTTeoyg'
                  target='_blank'
                  className='btn btn-xs btn-default pull-right'
                  style={{ marginRight: '5px' }}
                  rel='noopener noreferrer'
                  title={i18next.t('app.howto.youtube')}
                >
                  <i className='fa fa-youtube-play' />
                </a>
              </div>
              <div className='box-body no-padding'>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='pad'>
                      <ReactPlayer
                        width='100%'
                        height='300px'
                        url='https://www.youtube.com/watch?v=aUfjtWEhSEI&list=PLLDFInTMdfLA_0dGj6TLEcVOZUYTTeoyg'
                        controls
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    </div>
  )
}

export default HowtoScreen
