import {
  all, put, takeLatest, takeEvery, select
} from 'redux-saga/effects'
import { LOCATION_CHANGE } from 'react-router-redux'
import { LOGIN_SUCCESS } from '../auth'
import { callGetApiAction, callPostApiAction } from '../api'
import { credentialsLoadAction } from '../credentials/credentials.module'

// ------------------------------------
// Constants
// ------------------------------------
const CREDITS_LOAD_OK = 'CREDITS_LOAD_OK'
const CREDITS_SEARCH = 'CREDITS_SEARCH'
const CREDITS_SEARCH_OK = 'CREDITS_SEARCH_OK'
const CREDITS_TOPUP = 'CREDITS_TOPUP'
const CREDITS_TOPUP_OK = 'CREDITS_TOPUP_OK'
const CREDITS_HOW_VISIBILITY_CHANGE = 'CREDITS_HOW_VISIBILITY_CHANGE'

// ------------------------------------
// Actions
// ------------------------------------
const creditsLoadOkAction = (payload) => ({
  type: CREDITS_LOAD_OK,
  payload
})
export const creditsSearchAction = (userId) => ({
  type: CREDITS_SEARCH,
  payload: { userId }
})
const creditsSearchOkAction = (payload) => ({
  type: CREDITS_SEARCH_OK,
  payload
})
export const creditsTopupAction = (amountInCredits) => ({
  type: CREDITS_TOPUP,
  payload: { amountInCredits }
})
const creditsTopupOkAction = (payload) => ({
  type: CREDITS_TOPUP_OK,
  payload
})
export const creditsHowVisibilityChangeAction = () => ({
  type: CREDITS_HOW_VISIBILITY_CHANGE
})

// ------------------------------------
// Sagas
// ------------------------------------
function* creditsLoadSaga() {
  yield put(callGetApiAction('/api/credits', creditsLoadOkAction))
}

function* creditsSearchSaga(action) {
  const { userId } = action.payload
  yield put(callGetApiAction(`/api/credits?userId=${userId}`, creditsSearchOkAction))
}

function* creditsTopupSaga(action) {
  const userId = yield select((state) => state.credits.selectedUserId || state.auth.userId)
  const body = {
    userId,
    amountInCredits: action.payload.amountInCredits
  }
  yield put(callPostApiAction('/api/credits', body, creditsTopupOkAction))
}

function* refreshSaga(action) {
  const { pathname } = action.payload
  if (pathname === '/credits') {
    yield put(credentialsLoadAction())
  }
}

export function* creditsSaga() {
  yield all([
    yield takeLatest(LOGIN_SUCCESS, creditsLoadSaga),
    yield takeLatest(CREDITS_SEARCH, creditsSearchSaga),
    yield takeLatest(CREDITS_TOPUP, creditsTopupSaga),
    yield takeEvery(LOCATION_CHANGE, refreshSaga)
  ])
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [CREDITS_LOAD_OK]: (state, action) => {
    const clone = Object.assign({}, state)
    clone.userCreditsMap[action.payload.userId] = action.payload
    return clone
  },
  [CREDITS_SEARCH]: (state, action) => {
    const clone = Object.assign({}, state, { selectedUserId: action.payload.userId })
    clone.userCreditsMap[action.payload.userId] = action.payload
    return clone
  },
  [CREDITS_SEARCH_OK]: (state, action) => {
    const clone = Object.assign({}, state)
    clone.userCreditsMap[action.payload.userId] = action.payload
    return clone
  },
  [CREDITS_HOW_VISIBILITY_CHANGE]: (state) => Object.assign({}, state, { howIsHidden: !state.howIsHidden })
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  selectedUserId: undefined,
  userCreditsMap: {},
  howIsHidden: true
}

export default function (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
