import React, { PureComponent } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'

import StrategyFormScreen from './strategy-form.screen'
import { credentialsChangeAction, strategySaveAction } from '../strategy.module'
import i18n from '../../translations/i18n'
import { formatDateTimeForInput } from '../../common'

class StrategyFormContainer extends PureComponent {
  render() {
    return (
      <div>
        <StrategyFormScreen {...this.props} />
      </div>
    )
  }
}

const getParams = (state) => {
  const params = {}
  state.definitions.forEach(d => {
    params[d.id] = {}
    d.params.forEach(dp => {
      params[d.id][dp.key] = dp.value
    })
  })
  return params
}

const getReadForm = (state) => {
  if (state.form && state.form.strategy && state.form.strategy.values) {
    return state.form.strategy.values
  }
  return undefined
}

const findBalance = (state, currency, credentialsId) => {
  const credentials = state.credentials.list.find(cr => cr.id === credentialsId)
  if (credentials) {
    return credentials.wallet[currency].total
  }
  return 0
}
const getBalances = (state, credentialsId) => {
  const balances = {}
  state.price.currencies.forEach(c => {
    balances[c.code] = credentialsId ? findBalance(state, c.code, credentialsId) : 0
  })
  return balances
}

const getInitialValues = (state, ownProps) => {
  const initialValues = {
    definitionId: state.definitions[0].id,
    frequency: 'MINUTE',
    params: getParams(state),
    backtest: {
      start: formatDateTimeForInput(moment().subtract(1, 'months')),
      end: formatDateTimeForInput(moment()),
      balances: getBalances(state)
    },
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
  }
  if (ownProps.match.params.id) {
    const strategy = state.strategy.list.find(item => item.id === ownProps.match.params.id)
    initialValues.id = strategy.id
    initialValues.definitionId = strategy.definitionId
    initialValues.name = strategy.name
    initialValues.credentialsId = strategy.credentialsId
    initialValues.frequency = strategy.frequency
    initialValues.backtest.balances = getBalances(state, strategy.credentialsId)
    if (initialValues.params[strategy.definitionId]) {
      Object.keys(initialValues.params[strategy.definitionId]).forEach(key => {
        initialValues.params[strategy.definitionId][key] = strategy.params[key]
      })
    }
  }

  return initialValues
}

const mapStateToProps = (state, ownProps) => ({
  i18next: i18n(),
  initialValues: getInitialValues(state, ownProps),
  readForm: getReadForm(state),
  definitions: state.definitions,
  credentials: state.credentials.list
})

const mapDispatchToProps = (dispatch) => ({
  saveStrategy: values => dispatch(strategySaveAction(values)),
  credentialsChange: (credentialsId) => dispatch(credentialsChangeAction(credentialsId))
})
export default connect(mapStateToProps, mapDispatchToProps)(StrategyFormContainer)
