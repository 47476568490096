import React from 'react'
import { Field, reduxForm } from 'redux-form'

const HistoryFormScreen = ({
  exchangePairs, fileToUploadChange, handleSubmit, uploadHistory, i18next
}) => (
  <div>
    <section className='content-header'>
      <h1>{i18next.t('app.uploadHistory.title')}</h1>
    </section>

    <section className='content'>
      <div className='row'>
        <div className='col-md-4'>
          <div className='box box-primary'>
            <form onSubmit={handleSubmit(uploadHistory)}>
              <Field name='id' component='input' type='hidden' />
              <div className='box-body'>
                <div className='form-group'>
                  <label htmlFor='exchangePair'>{i18next.t('app.uploadHistory.exchangePair')}</label>
                  <Field name='exchangePair' className='form-control' component='select' required>
                    <option />
                    {exchangePairs.map(ep => (
                      <option key={ep.code} value={ep.code}>{ep.name}</option>
                    ))}
                  </Field>
                </div>
                <div className='form-group'>
                  <label htmlFor='historyFile'>{i18next.t('app.uploadHistory.historyFile')}</label>
                  <input
                    name='historyFile'
                    className='form-control'
                    type='file'
                    onChange={event => fileToUploadChange(event.target.files[0])}
                    required
                  />
                  <p className='help-block'>{i18next.t('app.uploadHistory.historyFileHelp')}</p>
                </div>
              </div>

              <div className='box-footer'>
                <button type='submit' className='btn btn-info pull-right'>
                  {i18next.t('upload')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
)

export default reduxForm({ form: 'history' })(HistoryFormScreen)
