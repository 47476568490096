import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { CredentialsForm, CredentialsList } from '../credentials'
import PrivateRoute from '../auth/private-route'
import { Home } from './home'
import Callback from '../auth/callback'
import { Dashboard } from './dashboard'
import { Main } from './main'
import { DefinitionForm, DefinitionList } from '../definition'
import { StrategyForm, StrategyList, StrategyDetail } from '../strategy'
import { HistoryForm } from '../history'
import { CreditsOverview } from '../credits'
import { Contact } from '../contact'
import Howto from '../howto'
import { UserList } from '../user'

const Routes = () => (
  <Switch>
    <Route exact path='/' component={Home} />
    <Route path='/callback' component={Callback} />
    <Main>
      <Switch>
        <PrivateRoute exact path='/dashboard' component={Dashboard} />
        <PrivateRoute exact path='/credits' component={CreditsOverview} />
        <PrivateRoute exact path='/credentials' component={CredentialsList} />
        <PrivateRoute path='/credentials/form/:id?' component={CredentialsForm} />
        <PrivateRoute exact path='/strategies' component={StrategyList} />
        <PrivateRoute path='/strategies/form/:id?' component={StrategyForm} />
        <PrivateRoute path='/strategies/:id' component={StrategyDetail} />
        <PrivateRoute exact path='/contact' component={Contact} />
        <PrivateRoute exact path='/definitions' component={DefinitionList} />
        <PrivateRoute path='/definitions/form/:id?' component={DefinitionForm} />
        <PrivateRoute exact path='/history' component={HistoryForm} />
        <PrivateRoute exact path='/howto' component={Howto} />
        <PrivateRoute exact path='/users' component={UserList} />
      </Switch>
    </Main>
    <Redirect path='*' to='/' />
  </Switch>
)
export default Routes
