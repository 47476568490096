import React from 'react'

const classCollapsed = 'box box-default box-solid collapsed-box'
const classVisible = 'box box-default box-solid'

const CreditsHowScreen = ({ handleCollapseClick, howIsHidden, i18next }) => (
  <div className='col-md-9'>
    <div className={howIsHidden ? classCollapsed : classVisible}>
      <div className='box-header with-border'>
        <h3 className='box-title'>{i18next.t('app.credits.howTitle')}</h3>

        <div className='box-tools pull-right'>
          <button type='button' className='btn btn-box-tool' onClick={handleCollapseClick}>
            <i className={howIsHidden ? 'fa fa-plus' : 'fa fa-minus'} />
          </button>
        </div>
      </div>
      <div
        className='box-body'
        dangerouslySetInnerHTML={{
          __html: i18next.t('app.credits.howBody', { interpolation: { escapeValue: false } })
        }}
      />
    </div>
  </div>
)

export default CreditsHowScreen
