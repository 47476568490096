export function callGetApi(req) {
  const headers = new Headers()
  if (req.token) {
    headers.append('Authorization', `Bearer ${req.token}`)
  }
  if (req.loginAsUserId) {
    headers.append('CA-Logged-As', req.loginAsUserId)
  }
  return fetch(req.path, { headers })
    .then(response => {
      if (!response.ok) {
        return Promise.reject(response)
      }
      return response.json()
    })
}

export function callPostApi(req) {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  if (req.token) {
    headers.append('Authorization', `Bearer ${req.token}`)
  }
  if (req.loginAsUserId) {
    headers.append('CA-Logged-As', req.loginAsUserId)
  }
  return fetch(req.path, {
    method: 'post',
    headers,
    body: JSON.stringify(req.body)
  }).then(response => {
    if (!response.ok) {
      return Promise.reject(response)
    }
    const location = response.headers.get('Location')
    if (location) {
      const index = location.lastIndexOf('/')
      return location.substring(index + 1)
    }
    return undefined
  })
}

export function callPutApi(req) {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  if (req.token) {
    headers.append('Authorization', `Bearer ${req.token}`)
  }
  if (req.loginAsUserId) {
    headers.append('CA-Logged-As', req.loginAsUserId)
  }
  return fetch(req.path, {
    method: 'put',
    headers,
    body: JSON.stringify(req.body)
  }).then(response => {
    if (!response.ok) {
      return Promise.reject(response)
    }
    return response
  })
}

export function callDeleteApi(req) {
  const headers = new Headers()
  if (req.token) {
    headers.append('Authorization', `Bearer ${req.token}`)
  }
  if (req.loginAsUserId) {
    headers.append('CA-Logged-As', req.loginAsUserId)
  }
  return fetch(req.path, {
    method: 'delete',
    headers
  }).then(response => response)
}
