import React, { PureComponent } from 'react'
import moment from 'moment'
import LogSliderScreen from './log-slider.screen'
import { formatCurrencyNumberBetter, formatDateTimeIso } from '../common'

const downloadBacktestFile = (token, backtestId) => {
  const headers = new Headers()
  headers.append('Authorization', `Bearer ${token}`)
  fetch(`/api/backtests/${backtestId}/logs/file`, { headers })
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = `backtest-${backtestId}.log`
      document.body.appendChild(a)
      a.click()
      a.remove()
    })
}

const downloadStrategyFile = (token, strategyId) => {
  const headers = new Headers()
  headers.append('Authorization', `Bearer ${token}`)
  fetch(`/api/strategies/${strategyId}/logs/file`, { headers })
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = `strategy-${strategyId}-${moment().format('YYYY-MM-DD')}.log`
      document.body.appendChild(a)
      a.click()
      a.remove()
    })
}

const createLogs = (data) => {
  const {
    visibleLevels, selectedEpochFrom, selectedEpochTo, records, trades
  } = data
  const result = []
  const filtered = records
    .filter(r => r.epochSecond >= selectedEpochFrom && r.epochSecond <= selectedEpochTo)
    .filter(r => visibleLevels.includes(r.level))

  result.push(...filtered)


  if (visibleLevels.includes('TRANSACTION')) {
    const filteredTrades = trades
      .map(t => ({
        epochSecond: t.epochSecond,
        level: 'TRANSACTION',
        // eslint-disable-next-line max-len
        message: `${t.operation}[${t.exchangePair}] of ${formatCurrencyNumberBetter(t.commodityUnits)} for ${formatCurrencyNumberBetter(t.price * t.commodityUnits)} price: ${formatCurrencyNumberBetter(t.price)}`
      }))
    result.push(...filteredTrades)
  }

  return result
    .sort((a, b) => a.epochSecond - b.epochSecond)
    .map(r => (`${formatDateTimeIso(r.epochSecond)} [${r.level}] ${r.message}`))
}

class LogScreen extends PureComponent {
  render() {
    const {
      name,
      logLevels,
      logsData,
      setLevelVisibility,
      selectEpoch,
      backtestId,
      strategyId,
      jwt,
      i18next
    } = this.props

    if (logsData[name] === undefined) return (<div />)

    const { from, to, visibleLevels } = logsData[name]

    return (
      <div className='box box-primary'>
        <div className='box-body'>
          <fieldset className='form-group'>
            <div className='form-group' style={{ textAlign: 'center' }}>
              {logLevels.map(level => (
                <div key={`checkbox-${level}`} style={{ display: 'inline', margin: '0.5em' }}>
                  <label htmlFor='level'>
                    <input
                      id='level'
                      name={level}
                      type='checkbox'
                      checked={visibleLevels.includes(level)}
                      onChange={(event) => setLevelVisibility(name, event.target.name, event.target.checked)}
                    />
                    &nbsp;
                    {level}
                  </label>
                </div>
              ))}
            </div>
            <div className='form-group' style={{ display: 'inline', width: '5%', float: 'left' }}>
              <LogSliderScreen
                name={name}
                from={from}
                to={to}
                onAfterChange={value => selectEpoch(name, value)}
              />
            </div>
            <div className='form-group' style={{ display: 'inline', width: '95%', float: 'left' }}>
              <textarea
                name='backtestLogs'
                className='form-control'
                readOnly
                style={{ background: 'white' }}
                rows='20'
                value={createLogs(logsData[name]).join('\n')}
              />
              { name === 'backtest'
              && <a onClick={() => downloadBacktestFile(jwt, backtestId)} style={{ cursor: 'pointer' }}>{i18next.t('app.strategy.form.downloadLog')}</a> }
              { name === 'strategy'
              && <a onClick={() => downloadStrategyFile(jwt, strategyId)} style={{ cursor: 'pointer' }}>{i18next.t('app.strategy.form.downloadLog')}</a> }
            </div>
          </fieldset>
        </div>
      </div>
    )
  }
}

export default LogScreen
