import { put } from 'redux-saga/effects'
import { callGetApiAction } from '../../api'

// ------------------------------------
// Constants
// ------------------------------------

const HOME_PRICE_STATS_OK = 'HOME_PRICE_STATS_OK'
const SHOW_ABOUT = 'SHOW_ABOUT'

// ------------------------------------
// Actions
// ------------------------------------
const homePriceStatsOkAction = (payload) => ({
  type: HOME_PRICE_STATS_OK,
  payload
})
export const showAboutAction = (show) => ({
  type: SHOW_ABOUT,
  payload: {
    showAbout: show
  }
})

// ------------------------------------
// Sagas
// ------------------------------------
export function* homeSaga() {
  yield put(callGetApiAction('/api/prices/stats', homePriceStatsOkAction))
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  priceStats: {
    commodity: 'BTC',
    paymentCurrency: 'USD',
    diff24h: 0
  },
  showAbout: false
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [HOME_PRICE_STATS_OK]: (state, action) => {
    const maxValue = Math.max(...Array.from(action.payload, ps => ps.diff24h))
    const maxItem = action.payload.find(ps => ps.diff24h === maxValue)

    return Object.assign({}, state, { priceStats: maxItem })
  },
  [SHOW_ABOUT]: (state, action) => Object.assign({}, state, action.payload)
}

export default function (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
