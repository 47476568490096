import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import DashboardCreditsScreen from './dashboard-credits.screen'
import i18n from '../../../translations/i18n'

class DashboardCreditsContainer extends PureComponent {
  render() {
    return (
      <DashboardCreditsScreen {...this.props} />
    )
  }
}

const getShouldActivate = (state) => {
  if (state.credits.userCreditsMap[state.auth.userId] && state.credits.userCreditsMap[state.auth.userId].userStatus) {
    return state.credits.userCreditsMap[state.auth.userId].userStatus === 'PASSIVE'
  }
  return false
}

const mapStateToProps = (state) => ({
  active: getShouldActivate(state),
  invoice: `${state.auth.userId}::${Date.now()}`,
  i18next: i18n()
})

export default connect(mapStateToProps, null)(DashboardCreditsContainer)
