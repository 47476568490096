import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import ValidationModalScreen from './validation-modal.screen'
import i18n from '../../../translations/i18n'
import { validationMessageCloseAction } from '../main.module'

class ValidationModalContainer extends PureComponent {
  render() {
    return (
      <ValidationModalScreen {...this.props} />
    )
  }
}

const mapStateToProps = (state) => ({
  active: state.main.validationModal.active,
  messageKey: state.main.validationModal.messageKey,
  i18next: i18n()
})

const mapDispatchToProps = (dispatch) => ({
  close: () => (dispatch(validationMessageCloseAction()))
})

export default connect(mapStateToProps, mapDispatchToProps)(ValidationModalContainer)
