import React from 'react'
import Notifications from 'react-notification-system-redux'

import Top from '../top'
import Sidebar from '../sidebar'
import { TaC } from '../../preferences'
import About from '../about'
import ValidationModal from './validation-modal'

import '../../styles/bootstrap/css/bootstrap.css'
import '../../styles/bootstrap/js/bootstrap'
import '../../styles/font-awesome/css/font-awesome.css'
import '../../styles/Ionicons/css/ionicons.css'
import '../../styles/AdminLTE/css/AdminLTE.css'
import '../../styles/AdminLTE/js/adminlte'
import '../../styles/skin-blue-light.css'
import '../../styles/google-fonts.css'
import '../../styles/cookieconsent.min.css'
import '../../styles/cookieconsent.min'
import '../../styles/auth0.min'

const MainScreen = ({ notifications, children }) => (
  <div className='hold-transition skin-blue-light sidebar-mini'>
    <div className='wrapper'>
      <ValidationModal />
      <Notifications notifications={notifications} />
      <Top />
      <Sidebar />
      <div className='content-wrapper' style={{ minHeight: '1000px' }}>
        <TaC />
        <About />
        {children}
      </div>
    </div>
  </div>
)

export default MainScreen
