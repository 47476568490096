import React from 'react'

const ValidationModalScreen = ({
  active, messageKey, close, i18next
}) => (
  <div className='modal fade in' id='tac' style={{ display: (active ? 'block' : 'none') }}>
    <div className='modal-dialog'>
      <div className='modal-content'>
        <div className='modal-header'>
          <center>
            <h2><i style={{ color: '#f39c12' }} className='fa fa-warning' /></h2>
          </center>
        </div>
        <div className='modal-body'>
          {i18next.t(`app.validation.${messageKey}.body`)}
        </div>
        <div className='modal-footer'>
          <button
            type='button'
            onClick={close}
            className='btn btn-warning center-block'
            data-dismiss='modal'
          >
            {i18next.t('app.validation.ok')}
          </button>
        </div>
      </div>
    </div>
  </div>
)

export default ValidationModalScreen
