import React from 'react'
import { Field, reduxForm } from 'redux-form'

const CreditsTopupScreen = ({
  handleSubmit, topupAction, i18next, isAdmin
}) => (
  isAdmin && (

    <div className='col-md-4'>
      <div className='box box-primary'>
        <form onSubmit={handleSubmit(topupAction)}>
          <div className='box-body'>
            <div className='form-group'>
              <label htmlFor='amountInCredits'>{i18next.t('app.credits.topupAmount')}</label>
              <Field type='number' component='input' name='amountInCredits' className='form-control' />
            </div>
          </div>
          <div className='box-footer'>
            <button type='submit' className='btn btn-info pull-right'>{i18next.t('app.credits.topup')}</button>
          </div>
        </form>
      </div>
    </div>
  )
)

export default reduxForm({ form: 'creditTopup' })(CreditsTopupScreen)
