import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import CreditsSearchScreen from './credits-search.screen'
import i18n from '../../translations/i18n'
import { creditsSearchAction } from '../credits.module'

class CreditsSearchContainer extends PureComponent {
  render() {
    return (
      <div>
        <CreditsSearchScreen {...this.props} />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  initialValues: { userId: state.credits.selectedUserId || state.auth.userId },
  i18next: i18n(),
  isAdmin: state.auth.roles && state.auth.roles.includes('admin'),
  loggedUserId: state.auth.userId
})

const mapDispatchToProps = (dispatch) => ({
  search: (values) => dispatch(creditsSearchAction(values.userId))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreditsSearchContainer)
