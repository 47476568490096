import React from 'react'
import { Redirect, Route } from 'react-router-dom'

const PrivateRouteScreen = ({ isAuthenticated, component: Component, ...props }) => (
  <Route
    {...props}
    render={props => (isAuthenticated
      ? <Component {...props} />
      : (<Redirect to={{ pathname: '/', state: { from: props.location } }} />))
        }
  />
)

export default PrivateRouteScreen
