import {
  all, put, select, takeEvery
} from 'redux-saga/effects'
import { callPostApiAction } from '../api'

// ------------------------------------
// Constants
// ------------------------------------
const HISTORY_UPLOAD = 'HISTORY_UPLOAD'
const HISTORY_UPLOAD_OK = 'HISTORY_UPLOAD_OK'

const FILE_TO_UPLOAD_CHOOSE = 'FILE_TO_UPLOAD_CHOOSE'


// ------------------------------------
// Actions
// ------------------------------------
export const historyUploadAction = (exchangePair) => ({
  type: HISTORY_UPLOAD,
  payload: {
    exchangePair
  }
})
const historyUploadOkAction = () => ({
  type: HISTORY_UPLOAD_OK
})
export const fileToUploadChooseAction = (fileBase64) => ({
  type: FILE_TO_UPLOAD_CHOOSE,
  payload: {
    fileBase64
  }
})

// ------------------------------------
// Sagas
// ------------------------------------
function* historyUploadSaga(action) {
  const historyFile = yield select((state) => state.history.fileToUpload)
  const body = {
    exchangePair: action.payload.exchangePair,
    historyFile
  }
  yield put(callPostApiAction('/api/prices/history', body, historyUploadOkAction))
}

export function* historySaga() {
  yield all([
    yield takeEvery(HISTORY_UPLOAD, historyUploadSaga)
  ])
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [FILE_TO_UPLOAD_CHOOSE]: (state, action) => Object.assign({}, state, { fileToUpload: action.payload.fileBase64 })
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  fileToUpload: ''
}

export default function (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
