import React, { PureComponent } from 'react'
import {
  CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis
} from 'recharts'
import moment from 'moment/moment'
import GraphSliderScreen from './graph-slider.screen'
import { formatCurrencyNumber, formatDateTime } from '../common'

const colorsData = ['#4daf4a', '#377eb8', '#e41a1c', '#984ea3', '#ff7f00', '#cccc33',
  '#a65628', '#f781bf', '#33ff33', '#000000', '#66ccff ']

const colorsPrices = ['#6878aa', '#254170', '#9dcddb', '#a26769', '#e28766', '#5a7d7c', '#8cba80', '#9a879d',
  '#7a3b69', '#563440', '#9c0d38 ']


const getColor = (lineName, index) => {
  if (lineName === 'buy') return '#00ff00'
  if (lineName === 'sell') return '#ff0000'
  return colorsData[index % colorsData.length]
}

const strokeWidth = (lineName) => {
  if (lineName === 'buy' || lineName === 'sell') {
    return 0
  }
  return 1
}
const dotStyle = (lineName, index) => {
  if (lineName === 'buy' || lineName === 'sell') {
    return { stroke: getColor(lineName, index), strokeWidth: 2 }
  }
  return false
}

class GraphScreen extends PureComponent {
  getMinMaxY(lineData) {
    const { name, graphsData } = this.props
    if (lineData && lineData.length > 0) {
      const graph = graphsData[name]
      const allValues = []
      lineData.filter(item => item.epochSecond > graph.from && item.epochSecond < graph.to).forEach(item => {
        graph.visibleLines.forEach(line => {
          const value = item[line]
          if (value) {
            allValues.push(value)
          }
        })
      })
      const sorted = allValues.sort((a, b) => a - b)
      return [sorted[0], sorted[sorted.length - 1]]
    }

    return ['auto', 'auto']
  }

  render() {
    const {
      i18next,
      name,
      exchangePairs,
      graphsData,
      setLineVisibility,
      setTimeRange,
      loadTimeRange
    } = this.props

    if (graphsData[name] === undefined) return (<div />)
    const {
      showPrices, lineNames, lineData, visibleLines, fromMin, from, to, toMax
    } = graphsData[name]

    return (
      <div className='box box-primary'>
        <div className='box-body'>
          <fieldset className='form-group'>
            <div className='form-group' style={{ textAlign: 'center' }}>
              {lineNames.filter(lineName => !exchangePairs.map(ep => ep.code).includes(lineName)).map(lineName => (
                <div key={`checkbox-data-${lineName}`} style={{ display: 'inline', margin: '0.5em' }}>
                  <label htmlFor={lineName}>
                    <input
                      id={lineName}
                      name={lineName}
                      type='checkbox'
                      checked={visibleLines.includes(lineName)}
                      onChange={(event) => setLineVisibility(name, event.target.name, event.target.checked)}
                    />
                    &nbsp;
                    {lineName}
                  </label>
                </div>
              ))}
            </div>
            <div className='form-group' style={{ textAlign: 'center' }}>
              {showPrices && exchangePairs && exchangePairs.map(ep => (
                <div key={`checkbox-exchangePair-${ep.code}`} style={{ display: 'inline', margin: '0.5em' }}>
                  <label htmlFor={ep.code}>
                    <input
                      id={ep.code}
                      name={ep.code}
                      type='checkbox'
                      checked={visibleLines.includes(ep.code)}
                      onChange={(event) => setLineVisibility(name, event.target.name, event.target.checked)}
                    />
                    &nbsp;
                    {i18next.t(`exchangePairs.${ep.code}`)}
                  </label>
                </div>
              ))}
            </div>
            <ResponsiveContainer width='100%' height={400}>
              <LineChart
                data={lineData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
              >
                <XAxis
                  type='number'
                  allowDataOverflow
                  dataKey='epochSecond'
                  tickFormatter={(epoch) => moment.unix(epoch).format('YYYY-MM-DD HH:mm')}
                  tickCount={10}
                  domain={[from, to]}
                />
                <YAxis
                  type='number'
                  allowDataOverflow
                  domain={this.getMinMaxY(lineData)}
                  tickFormatter={formatCurrencyNumber}
                />
                <CartesianGrid />
                <Tooltip labelFormatter={formatDateTime} />
                <Legend />
                {lineNames
                  .filter(lineName => !exchangePairs.map(ep => ep.code).includes(lineName))
                  .map((lineName, index) => (
                    <Line
                      key={`line-${lineName}`}
                      type='monotone'
                      stroke={getColor(lineName, index)}
                      connectNulls
                      hide={!graphsData[name].visibleLines.includes(lineName)}
                      dataKey={lineName}
                      strokeWidth={strokeWidth(lineName)}
                      dot={dotStyle(lineName, index)}
                      activeDot={dotStyle(lineName, index)}
                      isAnimationActive={false}
                    />
                  ))}
                {showPrices && exchangePairs
                  .filter(ep => graphsData[name].visibleLines.includes(ep.code))
                  .map((ep, index) => (
                    <Line
                      key={`line-exchange-pair-${ep.code}`}
                      type='monotone'
                      stroke={colorsPrices[index % colorsPrices.length]}
                      connectNulls
                      dataKey={ep.code}
                      strokeWidth={1}
                      dot={false}
                      activeDot={false}
                      isAnimationActive={false}
                    />
                  ))}
              </LineChart>
            </ResponsiveContainer>
            <GraphSliderScreen
              name={name}
              fromMin={fromMin}
              from={from}
              to={to}
              toMax={toMax}
              onChange={setTimeRange}
              onAfterChange={loadTimeRange}
            />
          </fieldset>
        </div>
      </div>
    )
  }
}

export default GraphScreen
