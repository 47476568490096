import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import DefinitionListScreen from './definition-list.screen'
import {
  definitionActivateAction, definitionDeactivateAction, definitionDeleteAction,
  definitionLoadAction
} from '../definition.module'
import i18n from '../../translations/i18n'

class DefinitionListContainer extends PureComponent {
  render() {
    return (
      <DefinitionListScreen {...this.props} />
    )
  }
}

const mapStateToProps = (state) => ({
  definitions: state.definitions,
  i18next: i18n()
})

const mapDispatchToProps = (dispatch) => ({
  deleteDefinition: (id) => dispatch(definitionDeleteAction(id)),
  loadDefinitions: () => dispatch(definitionLoadAction()),
  changeStatusDefinition: definition => {
    const definitionDeactive = definitionDeactivateAction(definition.id)
    const definitionActive = definitionActivateAction(definition.id)

    dispatch(definition.status === 'ACTIVE' ? definitionDeactive : definitionActive)
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(DefinitionListContainer)
