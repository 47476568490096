import { all, put, takeEvery } from 'redux-saga/effects'
import { callGetApiAction } from '../api/index'
import { getFromLocalStorage, saveToLocalStorage } from '../common'


// ------------------------------------
// Constants
// ------------------------------------

const PRICE_CURRENCIES_LOAD_OK = 'PRICE_CURRENCIES_LOAD_OK'
const PRICE_EXCHANGE_PAIRS_LOAD_OK = 'PRICE_EXCHANGE_PAIRS_LOAD_OK'
const PRICE_DATA_LOAD = 'PRICE_DATA_LOAD'

// ------------------------------------
// Actions
// ------------------------------------
const priceCurrenciesLoadOkAction = (payload) => ({
  type: PRICE_CURRENCIES_LOAD_OK,
  payload
})
const priceExchangePairsLoadOkAction = (payload) => ({
  type: PRICE_EXCHANGE_PAIRS_LOAD_OK,
  payload
})
export const priceDataLoadAction = (exchangePair, from, to, okAction) => ({
  type: PRICE_DATA_LOAD,
  payload: {
    exchangePair,
    from,
    to,
    okAction
  }
})


// ------------------------------------
// Sagas
// ------------------------------------
function* priceLoadSaga(action) {
  const {
    exchangePair, from, to, okAction
  } = action.payload
  yield put(callGetApiAction(`/api/prices?exchangePair=${exchangePair}&from=${from}&to=${to}`, okAction))
}

function* priceExchangePairsLoadOkSaga(action) {
  yield saveToLocalStorage('exchangePairs', action.payload)
}

function* priceCurrenciesLoadOkSaga(action) {
  yield saveToLocalStorage('currencies', action.payload)
}

export function* priceSaga() {
  yield put(callGetApiAction('/api/prices/exchange-pairs', priceExchangePairsLoadOkAction))
  yield put(callGetApiAction('/api/prices/currencies', priceCurrenciesLoadOkAction))
  yield all([
    yield takeEvery(PRICE_DATA_LOAD, priceLoadSaga),
    yield takeEvery(PRICE_EXCHANGE_PAIRS_LOAD_OK, priceExchangePairsLoadOkSaga),
    yield takeEvery(PRICE_CURRENCIES_LOAD_OK, priceCurrenciesLoadOkSaga)
  ])
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  currencies: getFromLocalStorage('currencies', []),
  exchangePairs: getFromLocalStorage('exchangePairs', [])
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [PRICE_CURRENCIES_LOAD_OK]: (state, action) => Object.assign({}, state, { currencies: action.payload }),
  [PRICE_EXCHANGE_PAIRS_LOAD_OK]: (state, action) => Object.assign({}, state, { exchangePairs: action.payload })
}

export default function (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
