import React from 'react'
import { Link } from 'react-router-dom'
import About from '../about'

const colorByType = (type) => {
  switch (type) {
    case 'info':
      return 'bg-aqua'
    case 'success':
      return 'bg-green'
    case 'warning':
      return 'bg-yellow'
    case 'error':
      return 'bg-red'
    default:
      return 'bg-aqua'
  }
}

const iconByType = (type) => {
  switch (type) {
    case 'info':
      return 'fa-envelope-o'
    case 'success':
      return 'fa-thumbs-o-up'
    case 'warning':
      return 'fa-warning'
    case 'error':
      return 'fa-warning'
    default:
      return 'fa-envelope-o'
  }
}

const HomeScreen = ({
  location, login, showAbout, priceStats, i18next, changeLanguage
}) => {
  const params = new URLSearchParams(location.search)
  const messageText = params.get('message')
  const messageType = params.get('type')
  const messageStyle = messageText && messageType
  const action = params.get('action')
  if (action === 'signup') {
    login()
  }
  return (
    <div className='hold-transition skin-blue-light layout-top-nav'>

      <div className='wrapper'>

        <About />
        <header className='main-header'>
          <nav className='navbar navbar-static-top'>
            <div className='container'>
              <div className='navbar-header'>
                <Link to='/' className='navbar-brand'>
                  <img
                    src='/assets/img/logo-small.png'
                    alt='Coin Acrobat'
                    style={{ width: '1.5em', display: 'inline' }}
                  />
                  <b>Coin</b>
Acrobat
                </Link>
                <button
                  type='button'
                  className='navbar-toggle collapsed'
                  data-toggle='collapse'
                  data-target='#navbar-collapse'
                >
                  <i className='fa fa-bars' />
                </button>
              </div>

              <div className='navbar-custom-menu'>
                <ul className='nav navbar-nav'>
                  <li>
                    <a style={{ cursor: 'pointer' }} onClick={login} title={i18next.t('homepage.login')}>
                      <span className='glyphicon glyphicon-log-in'>&nbsp;</span>
                      <span className='hidden-xs'>{i18next.t('homepage.login')}</span>
                    </a>
                  </li>
                  <li>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      style={{ cursor: 'pointer' }}
                      data-toggle='dropdown'
                      aria-expanded='false'
                      title={i18next.t('homepage.language')}
                    >
                      <i className='fa fa-language' />
                    </a>
                    <ul className='dropdown-menu'>
                      <li>
                        <a style={{ cursor: 'pointer' }} onClick={() => changeLanguage('en')}>
                          {i18next.t('langs.en')}
                        </a>
                      </li>
                      <li>
                        <a style={{ cursor: 'pointer' }} onClick={() => changeLanguage('cs')}>
                          {i18next.t('langs.cs')}
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a
                      style={{ cursor: 'pointer' }}
                      onClick={showAbout}
                      data-toggle='control-sidebar'
                      title={i18next.t('homepage.about')}
                    >
                      <span className='fa fa-info-circle' />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>

        <div className='content-wrapper'>
          <div className='container'>

            {
              messageStyle && (
                <section className='content'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='col-md-3' />
                      <div className='col-md-6 col-sm-6 col-xs-12'>
                        <div className={`info-box ${colorByType(messageType)}`}>
                          <span className='info-box-icon'><i className={`fa ${iconByType(messageType)}`} /></span>
                          <div className='info-box-content'>
                            <span className='info-box-number'>{messageText}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

              )
            }
            {
              !messageStyle && (
                <section className='content'>
                  <div className='row'>
                    <div className='box box-default'>

                      <div className='box-body'>
                        <div
                          id='carousel-example-generic'
                          className='carousel slide'
                          data-ride='carousel'
                        >
                          <div className='carousel-inner'>
                            <div className='item active'>
                              <img
                                src='/assets/img/banner3.jpg'
                                alt='Coin Acrobat - the comprehensive investment automation'
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>


                    <div className='col-md-8'>
                      <div className='box'>

                        <div className='box-body no-padding'>
                          <div className='row'>
                            <div className='col-md-12'>
                              <div className='pad'>
                                <iframe
                                  title='What is CoinAcrobat'
                                  width='100%'
                                  height='360px'
                                  src=''
                                  data-src='https://www.youtube.com/embed/iTeBF629hN8'
                                  frameBorder='0'
                                  allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                                  allowFullScreen
                                >
                                  What is CoinAcrobat
                                </iframe>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='col-md-4'>
                      <div className='info-box bg-yellow'>
                        <span className='info-box-icon'><i className='ion ion-ios-pricetag-outline' /></span>

                        <div className='info-box-content'>
                          <span
                            className='info-box-text'
                          >
                            {priceStats && priceStats.commodity}
                            {' vs. '}
                            {priceStats && priceStats.paymentCurrency}
                            {i18next.t('homepage.widgets.priceStats.titleSufix')}
                          </span>
                          <span className='info-box-number'>
                            {priceStats && (priceStats.diff24h * 100).toFixed(1)}
%
                          </span>
                          <span className='progress-description'>
                            {i18next.t('homepage.widgets.priceStats.period')}
                          </span>
                        </div>
                      </div>
                      <div className='info-box bg-green'>
                        <span className='info-box-icon'>
                          <i
                            className='ion ion-ios-heart-outline'
                          />
                        </span>
                        <div className='info-box-content'>
                          <span className='info-box-text'>{i18next.t('homepage.widgets.opportunities.title')}</span>
                          <span className='info-box-number'>1,440</span>
                          <span className='progress-description'>
                            {i18next.t('homepage.widgets.opportunities.investment')}
                          </span>
                        </div>
                      </div>
                    </div>


                    <div className='col-md-4'>
                      <div className='box'>
                        <div className='box-header with-border'>
                          <h1 className='box-title'><b>{i18next.t('homepage.howItWorks.title')}</b></h1>
                          <div className='box-tools pull-right'>

                            <button
                              type='button'
                              onClick={login}
                              className='btn btn-block btn-primary'
                            >
                              {i18next.t('homepage.howItWorks.registerBtn')}
                            </button>
                          </div>
                        </div>
                        <div className='box-body no-padding'>
                          <div className='row'>
                            <div className='col-md-12'>
                              <div className='pad'>
                                <div>
                                  <ul className='list-group list-group-unbordered'>
                                    <li className='list-group-item'>{i18next.t('homepage.howItWorks.answer1')}</li>
                                    <li className='list-group-item'>{i18next.t('homepage.howItWorks.answer2')}</li>
                                    <li className='list-group-item'>{i18next.t('homepage.howItWorks.answer3')}</li>
                                    <li className='list-group-item'>{i18next.t('homepage.howItWorks.answer4')}</li>
                                    <li className='list-group-item'>{i18next.t('homepage.howItWorks.answer5')}</li>
                                    <li className='list-group-item'>{i18next.t('homepage.howItWorks.answer6')}</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )
            }
          </div>
        </div>
      </div>
      <script>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        fbq('track', 'ViewContent');
      </script>
    </div>
  )
}

export default HomeScreen
