import React from 'react'
import { reduxForm, Field } from 'redux-form'
import ReactPlayer from 'react-player'

const DashboardStrategyScreen = ({
  active, exchangePairs, paymentCurrency, handleSubmit, submitAction, i18next
}) => active && (

  <form onSubmit={handleSubmit(submitAction)}>
    <div className='col-md-12'>
      <div className='small-box bg-yellow'>
        <div className='inner'>
          <div className='box-header bg-yellow'>
            <h4 style={{ margin: 0 }}>
              <i className='fa fa-thumbs-o-up' />
&nbsp;
              {i18next.t('app.dashboard.strategy.title')}
            </h4>
          </div>
          <div className='col-md-4'>
            {i18next.t('app.dashboard.strategy.description')}
          </div>
          <div className='col-md-4'>
            <div className='form-group'>
              <Field name='exchangePair' className='form-control' component='select'>
                {exchangePairs && exchangePairs.map(ep => (
                  <option key={ep.code} value={ep.code}>{i18next.t(`exchangePairs.${ep.code}`)}</option>
                ))}
              </Field>
            </div>
            <div className='form-group'>
              <Field
                name='tradingValue'
                className='form-control'
                component='input'
                type='number'
                placeholder='10000'
                style={{ width: '85%', display: 'inline' }}
              />
              {' '}
              <span style={{ float: 'right', padding: '6px 0' }}><strong>{i18next.t(`currencies.${paymentCurrency}`)}</strong></span>
            </div>
            <div className='form-group'>
              <Field name='frequency' className='form-control' component='select'>
                <option value='MINUTE'>{i18next.t('app.strategy.common.frequency.values.MINUTE')}</option>
                <option value='HOUR'>{i18next.t('app.strategy.common.frequency.values.HOUR')}</option>
                <option value='DAY'>{i18next.t('app.strategy.common.frequency.values.DAY')}</option>
              </Field>
            </div>
          </div>
          <div className='col-md-4'>
            <ReactPlayer url='https://www.youtube.com/watch?v=ptk050dteRI&list=PLLDFInTMdfLA_0dGj6TLEcVOZUYTTeoyg' width='250px' height='133px' controls />
          </div>

        </div>

        <span className='small-box-footer' style={{ clear: 'both' }}>
          <button
            type='submit'
            className='btn btn-success btn-flat small-box-footer'
            style={{ padding: '0.1em 1em' }}
          >
            {i18next.t('app.dashboard.strategy.submit')}
          </button>
        </span>
      </div>
    </div>

  </form>
)

export default reduxForm({ form: 'dashboard-strategy' })(DashboardStrategyScreen)
