import React from 'react'


const STATUSES = {
  DONE: 'info-box bg-green',
  NONE: 'info-box bg-yellow',
  IN_PROGRESS: 'info-box bg-yellow',
  TIMEOUT: 'info-box bg-red',
  ERROR: 'info-box bg-red'
}

const BacktestInfoScreen = ({ backtest, isActive }) => (
  <div className='col-md-8'>
    {isActive

    && (
    <div className={STATUSES[backtest.status]}>
      <span className='info-box-icon'><i className={backtest.status === 'IN_PROGRESS' ? 'fa fa-spinner fa-spin' : 'fa fa-tasks'} /></span>

      <div className='info-box-content'>
        <TitleSpan backtest={backtest} />
        <WalletBar wallet={backtest.finalWallet} />

        <div className='progress'>
          <div className='progress-bar' style={{ width: `${backtest.progress * 100}%` }} />
        </div>
      </div>
    </div>
    )
    }
  </div>
)

const WalletBar = ({ wallet }) => {
  if (wallet) {
    const currencies = Object.keys(wallet).filter(key => wallet[key] !== 0)
    if (currencies.length > 0) {
      return (
        <span className='info-box-text'>
          {currencies.map(curr => (
            <span key={curr}>
              {curr}
:
              {' '}
              {wallet[curr].toLocaleString()}
              <br />
            </span>
          ))}
        </span>
      )
    }
  }
  return (<span className='info-box-text' />)
}

const TitleSpan = ({ backtest }) => {
  switch (backtest.status) {
    case 'DONE':
      return (<span className='info-box-number'>Results</span>)
    case 'NONE':
      return (<span className='info-box-number'>Starting</span>)
    case 'IN_PROGRESS':
      return (
        <span className='info-box-number'>
Progess&nbsp;
          {(backtest.progress * 100).toLocaleString()}
%
        </span>
      )
    case 'TIMEOUT':
      return (<span className='info-box-number'>Timeout</span>)
    case 'ERROR':
      return (<span className='info-box-number'>Error</span>)
    default:
      return (<span className='info-box-number'>Uknown</span>)
  }
}

export default BacktestInfoScreen
