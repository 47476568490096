import {
  all, put, select, takeLatest
} from 'redux-saga/effects'
import { isInRole, LOGIN_SUCCESS } from '../auth'
import {
  callGetApiAction
} from '../api'

// ------------------------------------
// Constants
// ------------------------------------
const USERS_LOAD = 'USERS_LOAD'
const USERS_LOAD_OK = 'USERS_LOAD_OK'
const USER_ME_OK = 'USER_ME_OK'

// ------------------------------------
// Actions
// ------------------------------------
export const userLoadAction = () => ({
  type: USERS_LOAD
})
const userLoadOkAction = (payload) => ({
  type: USERS_LOAD_OK,
  payload
})
const userMeOkAction = (payload) => ({
  type: USER_ME_OK,
  payload
})

// ------------------------------------
// Sagas
// ------------------------------------
function* userLoadSaga() {
  const rolesAvailable = yield select(state => state.auth.roles)
  if (isInRole(rolesAvailable, 'admin')) {
    yield put(callGetApiAction('/api/users', userLoadOkAction))
  }
}
function* userMeSaga() {
  yield put(callGetApiAction('/api/users/me', userMeOkAction))
}

export function* userSaga() {
  yield all([
    yield takeLatest(LOGIN_SUCCESS, userLoadSaga),
    yield takeLatest(LOGIN_SUCCESS, userMeSaga),
    yield takeLatest(USERS_LOAD, userLoadSaga)
  ])
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [USERS_LOAD_OK]: (state, action) => Object.assign({}, state, { all: [...action.payload] }),
  [USER_ME_OK]: (state, action) => Object.assign({}, state, { me: action.payload })
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  all: [],
  me: {}
}

export default function (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
