import {
  all, call, put, select, takeEvery
} from 'redux-saga/effects'
import {
  callDeleteApi, callGetApi, callPostApi, callPutApi
} from './api.services'
import { toastrErrorAction, toastrSuccessAction, toastrWarningAction } from '../toastr'
import { logoutAction } from '../auth'
import { validationMessageOpenAction } from '../app/main/main.module'

// ------------------------------------
// Constants
// ------------------------------------
const CALL_GET_API = 'CALL_GET_API'
const CALL_POST_API = 'CALL_POST_API'
const CALL_PUT_API = 'CALL_PUT_API'
const CALL_DELETE_API = 'CALL_DELETE_API'

// ------------------------------------
// Actions
// ------------------------------------
export const callGetApiAction = (path, okAction, notFoundAction) => ({
  type: CALL_GET_API,
  payload: {
    path,
    okAction,
    notFoundAction
  }
})
export const callPostApiAction = (path, body, okAction, errorAction) => ({
  type: CALL_POST_API,
  payload: {
    path,
    body,
    okAction,
    errorAction
  }
})
export const callPutApiAction = (path, body, okAction) => ({
  type: CALL_PUT_API,
  payload: {
    path,
    body,
    okAction
  }
})
export const callDeleteApiAction = (path, id, okAction) => ({
  type: CALL_DELETE_API,
  payload: {
    path,
    id,
    okAction
  }
})

// ------------------------------------
// Sagas
// ------------------------------------
const getToken = (state) => state.auth.loggedFromAuth0.token

function* callGetApiSaga(action) {
  const token = yield select(getToken)
  const loginAsUserId = yield select(state => state.auth.loginAsUserId)
  const req = { path: action.payload.path, token, loginAsUserId }
  try {
    const data = yield call(callGetApi, req)
    yield put(action.payload.okAction(data))
  } catch (e) {
    if (e.status === 403 || e.status === 401) {
      yield put(toastrWarningAction('User session expired'))
      yield put(logoutAction())
    } else if (e.status === 404 && action.payload.notFoundAction) {
      yield put(action.payload.notFoundAction())
    } else {
      yield put(toastrErrorAction('Loading of data failed'))
    }
  }
}

function getValidationMessageKey(response) {
  return response.json().then(body => body.validationMessage)
}

function* callPostApiSaga(action) {
  const token = yield select(getToken)
  const loginAsUserId = yield select(state => state.auth.loginAsUserId)
  const req = {
    path: action.payload.path, body: action.payload.body, token, loginAsUserId
  }
  try {
    const id = yield call(callPostApi, req)
    yield put(action.payload.okAction({ ...action.payload.body, id }))
    yield put(toastrSuccessAction('Successfully created.'))
  } catch (e) {
    if (e.status === 403 || e.status === 401) {
      yield put(toastrWarningAction('User session expired'))
      yield put(logoutAction())
    } else if (e.status === 400) {
      const messageKey = yield call(getValidationMessageKey, e)
      yield put(validationMessageOpenAction(messageKey))
    } else {
      yield put(toastrErrorAction('Create operation failed'))
    }
    if (action.payload.errorAction) {
      yield put(action.payload.errorAction())
    }
  }
}

function* callPutApiSaga(action) {
  const token = yield select(getToken)
  const loginAsUserId = yield select(state => state.auth.loginAsUserId)
  const req = {
    path: action.payload.path, body: action.payload.body, token, loginAsUserId
  }
  try {
    yield call(callPutApi, req)
    yield put(action.payload.okAction(action.payload.body))
    yield put(toastrSuccessAction('Successfully updated.'))
  } catch (e) {
    if (e.status === 403 || e.status === 401) {
      yield put(toastrWarningAction('User session expired'))
      yield put(logoutAction())
    } else {
      yield put(toastrErrorAction('Update operation failed'))
    }
  }
}

function* callDeleteApiSaga(action) {
  const token = yield select(getToken)
  const loginAsUserId = yield select(state => state.auth.loginAsUserId)
  const req = { path: action.payload.path + action.payload.id, token, loginAsUserId }
  try {
    yield call(callDeleteApi, req)
    yield put(action.payload.okAction(action.payload.id))
    yield put(toastrSuccessAction('Successfully deleted.'))
  } catch (e) {
    if (e.status === 403 || e.status === 401) {
      yield put(toastrWarningAction('User session expired'))
      yield put(logoutAction())
    } else {
      yield put(toastrErrorAction('Delete operation failed'))
    }
  }
}

export function* apiSaga() {
  yield all([
    yield takeEvery(CALL_GET_API, callGetApiSaga),
    yield takeEvery(CALL_POST_API, callPostApiSaga),
    yield takeEvery(CALL_PUT_API, callPutApiSaga),
    yield takeEvery(CALL_DELETE_API, callDeleteApiSaga)
  ])
}
