import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import CredentialsListScreen from './credentials-list.screen'
import { credentialsDeleteAction, credentialsLoadAction } from '../credentials.module'
import i18n from '../../translations/i18n'

class CredentialsListContainer extends PureComponent {
  render() {
    return (
      <CredentialsListScreen {...this.props} />
    )
  }
}

const mapStateToProps = (state) => ({
  credentials: state.credentials.list,
  i18next: i18n()
})

const mapDispatchToProps = (dispatch) => ({
  deleteCredentials: (id) => dispatch(credentialsDeleteAction(id)),
  loadCredentials: () => dispatch(credentialsLoadAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(CredentialsListContainer)
