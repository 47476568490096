import React from 'react'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import { createLogger } from 'redux-logger'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'react-router-redux'
import createSagaMiddleware from 'redux-saga'
import { applyMiddleware, createStore } from 'redux'

import i18n from './translations/i18n'
import AppContainer from './app/app.container'
import reducer from './store/reducers'
import saga from './store/sagas'
import Routes from './app/routes'

const loggerMiddleware = createLogger()
const sagaMiddleware = createSagaMiddleware()
const history = createBrowserHistory()
const store = createStore(reducer, applyMiddleware(sagaMiddleware, routerMiddleware(history), loggerMiddleware))


sagaMiddleware.run(saga)

const render = () => {
  ReactDOM.render(
    <I18nextProvider i18n={i18n()}>
      <AppContainer store={store} history={history}>
        <Routes />
      </AppContainer>
    </I18nextProvider>,
    document.getElementById('root')
  )
}

render()
