import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import MainScreen from './main.screen'

class MainContainer extends PureComponent {
  render() {
    return (
      <MainScreen {...this.props} />
    )
  }
}

const mapStateToProps = (state) => ({
  notifications: state.notifications
})

export default connect(mapStateToProps, null)(MainContainer)
