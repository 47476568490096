import {
  all, put, takeEvery, takeLatest
} from 'redux-saga/effects'
import { LOCATION_CHANGE } from 'react-router-redux'
import { LOGIN_SUCCESS } from '../auth'
import { callGetApiAction, callPostApiAction } from '../api'

const PREFERENCES_LOAD = 'PREFERENCES_LOAD'
const PREFERENCES_LOAD_OK = 'PREFERENCES_LOAD_OK'
const TAC_ACCEPTED = 'TAC_ACCEPTED'
const TAC_ACCEPTED_OK = 'TAC_ACCEPTED_OK'
const NOTIFICATIONS_SETUP = 'NOTIFICATIONS_SETUP'
const NOTIFICATIONS_SETUP_OK = 'NOTIFICATIONS_SETUP_OK'

// ------------------------------------
// Actions
// ------------------------------------
export const preferencesLoadAction = () => ({
  type: PREFERENCES_LOAD
})
const preferencesLoadOkAction = (payload) => ({
  type: PREFERENCES_LOAD_OK,
  payload
})
export const tacAcceptedAction = () => ({
  type: TAC_ACCEPTED
})
const tacAcceptedOkAction = () => ({
  type: TAC_ACCEPTED_OK
})
export const notificationsSetupAction = (email, lang) => ({
  type: NOTIFICATIONS_SETUP,
  payload: {
    email,
    language: lang
  }
})
const notificationsSetupOkAction = (payload) => ({
  type: NOTIFICATIONS_SETUP_OK,
  payload
})
// ------------------------------------
// Sagas
// ------------------------------------
function* preferencesLoadSaga() {
  yield put(callGetApiAction('/api/preferences', preferencesLoadOkAction))
}

function* tacAcceptedSaga() {
  yield put(callPostApiAction('/api/preferences/tac-accepted', {}, tacAcceptedOkAction))
  window.fbq('track', 'CompleteRegistration')
}

function* notificationsSetupSaga(action) {
  yield put(callPostApiAction('/api/preferences/notification-email', action.payload, notificationsSetupOkAction))
}

function* refreshSaga(action) {
  const { pathname } = action.payload
  if (pathname === '/dashboard') {
    yield put(preferencesLoadAction())
  }
}

export function* preferencesSaga() {
  yield all([
    yield takeLatest(LOGIN_SUCCESS, preferencesLoadSaga),
    yield takeLatest(PREFERENCES_LOAD, preferencesLoadSaga),
    yield takeEvery(TAC_ACCEPTED, tacAcceptedSaga),
    yield takeEvery(NOTIFICATIONS_SETUP, notificationsSetupSaga),
    yield takeEvery(LOCATION_CHANGE, refreshSaga)
  ])
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [PREFERENCES_LOAD_OK]: (state, action) => Object.assign({}, action.payload),
  [TAC_ACCEPTED_OK]: (state) => Object.assign({}, state, { acceptedTaC: true }),
  [NOTIFICATIONS_SETUP_OK]: (state, action) => Object.assign({}, state, { notificationEmail: action.payload.email })
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  acceptedTaC: true,
  notificationEmail: 'abc@efd.com'
}

export default function (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
