import i18n from 'i18next'
import Cache from 'i18next-localstorage-cache'
import LanguageDetector from 'i18next-browser-languagedetector'

import en from './langs/en'
import cs from './langs/cs'

const options = {
  fallbackLng: 'en',
  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',
  interpolation: {
    escapeValue: false
  },
  react: {
    wait: true
  },
  resources: {
    en,
    cs
  }
}

const i18nTranslations = i18n
  .use(Cache)
  .use(LanguageDetector)
  .init(options)

export const changeLanguage = (lng) => {
  i18nTranslations.changeLanguage(lng)
}

export default () => i18nTranslations
