import React from 'react'
import { Field, reduxForm } from 'redux-form'

const BacktestFormScreen = ({
  currencies, isActive, activate, deactivate, run, handleSubmit, i18next
}) => (

  <fieldset className='form-group'>
    <legend>
      {i18next.t('app.strategy.form.backTest')}
      <div className='box-tools pull-right'>
        {!isActive && (
          <button
            type='button'
            className='btn btn-box-tool'
            onClick={activate}
          >
            <i
              className='fa fa-chevron-down'
            />
          </button>
        )}
        {isActive && (
          <button
            type='button'
            className='btn btn-box-tool'
            onClick={deactivate}
          >
            <i
              className='fa fa-chevron-up'
            />
          </button>
        )}
      </div>
    </legend>
    {isActive && (
      <div>
        <div className='form-group'>
          <label htmlFor='backtest.start'>{i18next.t('app.strategy.form.start')}</label>
          <Field
            name='backtest.start'
            className='form-control'
            component='input'
            type='datetime-local'
          />
        </div>
        <div className='form-group'>
          <label htmlFor='backtest.end'>{i18next.t('app.strategy.form.end')}</label>
          <Field
            name='backtest.end'
            className='form-control'
            component='input'
            type='datetime-local'
          />
        </div>
        {currencies.map(c => (
          <div key={c.code} className='form-group'>
            <label htmlFor={`backtest.balances.${c.code}`}>
              {c.name}
              {' '}
              {i18next.t('app.strategy.form.balance')}
            </label>
            <Field
              name={`backtest.balances.${c.code}`}
              className='form-control'
              component='input'
              type='number'
            />
          </div>
        ))}
        <button
          type='button'
          onClick={handleSubmit(run)}
          className='btn btn-sm btn-success pull-right'
        >
          {i18next.t('app.strategy.form.runBackTest')}
        </button>
      </div>
    )}

  </fieldset>
)

export default reduxForm({ form: 'strategy' })(BacktestFormScreen)
