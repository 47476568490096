import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { routerReducer } from 'react-router-redux'
import { reducer as notifications } from 'react-notification-system-redux'

import { credentialsReducers } from '../credentials'
import { authReducers } from '../auth'
import { definitionReducers } from '../definition'
import { strategyReducers } from '../strategy'
import { historyReducers } from '../history'
import { backtestReducers } from '../backtest'
import { priceReducers } from '../price'
import { graphReducers } from '../graph'
import { homeReducers } from '../app/home'
import { dashboardReducers } from '../app/dashboard'
import { logReducers } from '../log'
import { creditsReducers } from '../credits'
import { contactReducers } from '../contact'
import { preferencesReducers } from '../preferences'
import { mainReducers } from '../app/main'
import { userReducers } from '../user'

const appReducer = combineReducers({
  form: formReducer,
  routing: routerReducer,
  notifications,
  home: homeReducers,
  main: mainReducers,
  auth: authReducers,
  preferences: preferencesReducers,
  credits: creditsReducers,
  contact: contactReducers,
  dashboard: dashboardReducers,
  credentials: credentialsReducers,
  definitions: definitionReducers,
  strategy: strategyReducers,
  backtest: backtestReducers,
  history: historyReducers,
  price: priceReducers,
  graphs: graphReducers,
  logs: logReducers,
  users: userReducers
})
export default appReducer
