import React from 'react'
import { Link } from 'react-router-dom'
import Confirm from '../../confirm'

const StrategyListScreen = ({
  strategies, definitions, loadStrategies, changeStatusStrategy, deleteStrategy, i18next
}) => (
  <div>
    <section className='content'>
      {strategies.map(s => (
        <div key={s.id}>
          <Confirm
            id={`confirm-delete-${s.id}`}
            title={i18next.t('app.strategy.summary.deleteTitle')}
            body={i18next.t('app.strategy.summary.deleteBody', { name: s.name })}
            confirmAction={() => deleteStrategy(s.id)}
          />
          <Confirm
            id={`confirm-status-${s.id}`}
            title={i18next.t('app.strategy.summary.statusChangeTitle')}
            body={
              s.status === 'RUNNING'
                ? i18next.t('app.strategy.summary.stopBody', { name: s.name })
                : i18next.t('app.strategy.summary.startBody', { name: s.name })}
            confirmAction={() => changeStatusStrategy(s)}
            i18next={i18next}
          />
        </div>
      ))}

      <div className='row'>
        <div className='col-md-6'>
          <div className='box box-info'>
            <div className='box-header'>
              <h3 className='box-title'>{i18next.t('app.strategy.list.title')}</h3>
              <div className='box-tools pull-right'>
                <button type='button' className='btn btn-box-tool' onClick={() => loadStrategies()}>
                  <i className='fa fa-refresh' />
                </button>
              </div>
            </div>
            <div className='box-body'>
              <div className='table-responsive'>
                <table className='table no-margin'>
                  <thead>
                    <tr>
                      <th>{i18next.t('app.strategy.common.name')}</th>
                      <th>{i18next.t('app.strategy.common.definition')}</th>
                      <th>{i18next.t('app.strategy.common.frequency.name')}</th>
                      <th>{i18next.t('app.strategy.common.status.name')}</th>
                      <th>{i18next.t('actions')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {strategies.map(s => (
                      <tr key={s.id}>
                        <td>{s.name}</td>
                        <td>
                          {definitions
                          && definitions.find(d => d.id === s.definitionId)
                          && definitions.find(d => d.id === s.definitionId).name}
                        </td>
                        <td>{i18next.t(`app.strategy.common.frequency.values.${s.frequency}`)}</td>
                        <td>
                          <span className={s.status === 'RUNNING' ? 'label label-success' : 'label label-danger'}>
                            {i18next.t(`app.strategy.common.status.values.${s.status}`)}
                          </span>
                        </td>
                        <td>
                          <button
                            type='button'
                            data-toggle='modal'
                            data-target={`#confirm-status-${s.id}`}
                            className='btn btn-box-tool'
                            title={s.status === 'RUNNING'
                              ? i18next.t('app.strategy.summary.stop')
                              : i18next.t('app.strategy.summary.stop')}
                          >
                            {s.status === 'RUNNING' ? <i className='fa fa-stop' /> : <i className='fa fa-play' />}
                          </button>
                          {definitions && definitions.find(d => d.id === s.definitionId)
                          && (
                          <Link
                            to={`/strategies/form/${s.id}`}
                            className='btn btn-box-tool'
                            title={i18next.t('app.strategy.summary.edit')}
                          >
                            <i className='fa fa-edit' />
                          </Link>
                          )
                          }

                          <button
                            type='button'
                            data-toggle='modal'
                            data-target={`#confirm-delete-${s.id}`}
                            className='btn btn-box-tool'
                            title={i18next.t('app.strategy.summary.delete')}
                          >
                            <i className='fa fa-times' />
                          </button>
&nbsp;&nbsp;&nbsp;
                          <Link
                            to={`/strategies/${s.id}`}
                            className='btn btn-xs btn-info btn-flat'
                            title={i18next.t('app.strategy.summary.detail')}
                          >
                            Detail
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='box-footer clearfix'>
              <Link to='/strategies/form' className='btn btn-sm btn-info btn-flat pull-left'>
                {i18next.t('new')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

export default StrategyListScreen
