import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import DashboardCredentialsScreen from './dashboard-credentials.screen'
import i18n from '../../../translations/i18n'
import { credentialsSaveAction } from '../../../credentials/credentials.module'

class DashboardCredentialsContainer extends PureComponent {
  render() {
    return (
      <DashboardCredentialsScreen {...this.props} />
    )
  }
}

const getShouldCreateCredentials = (state) => {
  if (state.credentials.list) {
    const list = state.credentials.list.filter(cr => cr.status !== 'INVALID')
    return list.length === 0
  }
  return false
}

const mapStateToProps = (state) => ({
  active: getShouldCreateCredentials(state),
  inProgress: state.credentials.inProgress,
  i18next: i18n()
})

const mapDispatchToProps = (dispatch) => ({
  submitAction: (values) => (
    dispatch(credentialsSaveAction(null, 'Default', values.customerId, values.apiKey, values.secretKey))
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardCredentialsContainer)
