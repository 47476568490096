import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import AboutScreen from './about.screen'
import i18n from '../../translations/i18n'
import { showAboutAction } from '../home/home.module'

class AboutContainer extends PureComponent {
  render() {
    return (
      <AboutScreen {...this.props} />
    )
  }
}

const mapStateToProps = (state) => ({
  active: state.home.showAbout,
  i18next: i18n()
})

const mapDispatchToProps = (dispatch) => ({
  hideAbout: () => dispatch(showAboutAction(false))
})

export default connect(mapStateToProps, mapDispatchToProps)(AboutContainer)
