import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import StrategyDetailScreen from './strategy-detail.screen'
import i18n from '../../translations/i18n'

class StrategyDetailContainer extends PureComponent {
  render() {
    return (
      <StrategyDetailScreen {...this.props} />
    )
  }
}

const getDefinition = (state, strategy) => {
  if (strategy && strategy.definitionId) {
    return state.definitions.find(d => d.id === strategy.definitionId)
  }
  return undefined
}

const mapStateToProps = (state, ownProps) => ({
  strategy: state.strategy.list.find(s => s.id === ownProps.match.params.id),
  definition: getDefinition(state, state.strategy.list.find(s => s.id === ownProps.match.params.id)),
  i18next: i18n()
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(StrategyDetailContainer)
