import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import DashboardStrategyScreen from './dashboard-strategy.screen'
import i18n from '../../../translations/i18n'
import { getTradingValue } from '../dashboard.module'
import { strategyCreateStartAction } from '../../../strategy/strategy.module'

class DashboardStrategyContainer extends PureComponent {
  render() {
    return (
      <DashboardStrategyScreen {...this.props} />
    )
  }
}

const getShouldCreateStrategy = (state) => {
  // true - when there is no strategy and user has single credentials or credentials named Default
  if (state.credentials.list) {
    if (state.strategy.list.length === 0) {
      if (state.credentials.list.filter(cr => cr.status === 'VALID').length === 1) {
        return true
      }
      if (state.credentials.list.find(cr => cr.status === 'VALID' && cr.accountAlias === 'Default')) {
        return true
      }
    }
  }
  return false
}

const getPaymentCurrency = (state) => {
  if (state.form && state.form['dashboard-strategy'] && state.form['dashboard-strategy'].values) {
    const exchangePairCode = state.form['dashboard-strategy'].values.exchangePair
    if (exchangePairCode && state.price.exchangePairs && state.price.exchangePairs.find(ep => ep.code === exchangePairCode)) {
      return state.price.exchangePairs.find(ep => ep.code === exchangePairCode).paymentCurrency
    }
  }
  return undefined
}

const mapStateToProps = (state) => ({
  initialValues: {
    exchangePair: 'btcusd',
    tradingValue: getTradingValue(state, 'btcusd'),
    frequency: 'MINUTE',
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
  },
  active: getShouldCreateStrategy(state),
  exchangePairs: state.price.exchangePairs,
  paymentCurrency: getPaymentCurrency(state),
  i18next: i18n()
})

const mapDispatchToProps = (dispatch) => ({
  submitAction: (values) => (
    dispatch(strategyCreateStartAction({ ...values }))
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardStrategyContainer)
