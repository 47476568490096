import {
  all, put, takeEvery, takeLatest
} from 'redux-saga/effects'
import { push } from 'react-router-redux'
import { LOGIN_SUCCESS } from '../auth'
import {
  callDeleteApiAction, callGetApiAction, callPostApiAction, callPutApiAction
} from '../api'

// ------------------------------------
// Constants
// ------------------------------------
const DEFINITION_LOAD = 'DEFINITION_LOAD'
const DEFINITION_LOAD_OK = 'DEFINITION_LOAD_OK'

const DEFINITION_CREATE = 'DEFINITION_CREATE'
const DEFINITION_CREATE_OK = 'DEFINITION_CREATE_OK'

const DEFINITION_UPDATE = 'DEFINITION_UPDATE'
const DEFINITION_UPDATE_OK = 'DEFINITION_UPDATE_OK'

const DEFINITION_ACTIVATE = 'DEFINITION_ACTIVATE'
const DEFINITION_ACTIVATE_OK = 'DEFINITION_ACTIVATE_OK'

const DEFINITION_DEACTIVATE = 'DEFINITION_DEACTIVATE'
const DEFINITION_DEACTIVATE_OK = 'DEFINITION_DEACTIVATE_OK'

const DEFINITION_DELETE = 'DEFINITION_DELETE'
const DEFINITION_DELETE_OK = 'DEFINITION_DELETE_OK'

// ------------------------------------
// Actions
// ------------------------------------
export const definitionLoadAction = () => ({
  type: DEFINITION_LOAD
})
const definitionLoadOkAction = (payload) => ({
  type: DEFINITION_LOAD_OK,
  payload
})
export const definitionSaveAction = (id, name, description, classBody) => ({
  type: id ? DEFINITION_UPDATE : DEFINITION_CREATE,
  payload: {
    id,
    name,
    description,
    classBody
  }
})
const definitionCreateOkAction = (payload) => ({
  type: DEFINITION_CREATE_OK,
  payload
})
const definitionUpdateOkAction = (payload) => ({
  type: DEFINITION_UPDATE_OK,
  payload
})
export const definitionActivateAction = (id) => ({
  type: DEFINITION_ACTIVATE,
  payload: {
    id
  }
})
const definitionActivateOkAction = (payload) => ({
  type: DEFINITION_ACTIVATE_OK,
  payload
})
export const definitionDeactivateAction = (id) => ({
  type: DEFINITION_DEACTIVATE,
  payload: {
    id
  }
})
const definitionDeactivateOkAction = (payload) => ({
  type: DEFINITION_DEACTIVATE_OK,
  payload
})
export const definitionDeleteAction = (id) => ({
  type: DEFINITION_DELETE,
  payload: {
    id
  }
})
const definitionDeleteOkAction = (id) => ({
  type: DEFINITION_DELETE_OK,
  payload: {
    id
  }
})

// ------------------------------------
// Sagas
// ------------------------------------
function* definitionLoadSaga() {
  yield put(callGetApiAction('/api/definitions', definitionLoadOkAction))
}

function* definitionCreateSaga(action) {
  yield put(callPostApiAction('/api/definitions', action.payload, definitionCreateOkAction))
}

function* definitionListPageSaga() {
  yield put(push('/definitions'))
}

function* definitionUpdateSaga(action) {
  yield put(callPutApiAction(`/api/definitions/${action.payload.id}`, action.payload, definitionUpdateOkAction))
}

function* definitionActivateSaga(action) {
  yield put(callPutApiAction(
    `/api/definitions/${action.payload.id}/activate`,
    action.payload, definitionActivateOkAction
  ))
}

function* definitionDeactivateSaga(action) {
  yield put(callPutApiAction(
    `/api/definitions/${action.payload.id}/deactivate`,
    action.payload, definitionDeactivateOkAction
  ))
}

function* definitionDeleteSaga(action) {
  yield put(callDeleteApiAction('/api/definitions/', action.payload.id, definitionDeleteOkAction))
}

export function* definitionSaga() {
  yield all([
    yield takeLatest(LOGIN_SUCCESS, definitionLoadSaga),
    yield takeLatest(DEFINITION_LOAD, definitionLoadSaga),
    yield takeEvery(DEFINITION_CREATE, definitionCreateSaga),
    yield takeEvery(DEFINITION_CREATE_OK, definitionListPageSaga),
    yield takeEvery(DEFINITION_UPDATE, definitionUpdateSaga),
    yield takeEvery(DEFINITION_UPDATE_OK, definitionListPageSaga),
    yield takeEvery(DEFINITION_ACTIVATE, definitionActivateSaga),
    yield takeEvery(DEFINITION_DEACTIVATE, definitionDeactivateSaga),
    yield takeEvery(DEFINITION_DELETE, definitionDeleteSaga)
  ])
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [DEFINITION_LOAD_OK]: (state, action) => [...action.payload],
  [DEFINITION_DELETE_OK]: (state, action) => state.filter(cr => cr.id !== action.payload.id),
  [DEFINITION_CREATE_OK]: (state, action) => [...state, action.payload],
  [DEFINITION_UPDATE_OK]: (state, action) => state.map(cr => {
    if (cr.id === action.payload.id) {
      return { ...cr, ...action.payload }
    }
    return cr
  }),
  [DEFINITION_DEACTIVATE_OK]: (state, action) => state.map(cr => {
    if (cr.id === action.payload.id) {
      return { ...cr, status: 'NOT_ACTIVE' }
    }
    return cr
  }),
  [DEFINITION_ACTIVATE_OK]: (state, action) => state.map(cr => {
    if (cr.id === action.payload.id) {
      return { ...cr, status: 'ACTIVE' }
    }
    return cr
  })
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = []

export default function (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
