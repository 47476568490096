import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import CreditsHowScreen from './credits-how.screen'
import i18n from '../../translations/i18n'
import { creditsHowVisibilityChangeAction } from '../credits.module'

class CreditsHowContainer extends PureComponent {
  render() {
    return (
      <div>
        <CreditsHowScreen {...this.props} />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  i18next: i18n(),
  howIsHidden: state.credits.howIsHidden
})

const mapDispatchToProps = (dispatch) => ({
  handleCollapseClick: () => dispatch(creditsHowVisibilityChangeAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(CreditsHowContainer)
