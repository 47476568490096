import React from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'react-router-redux'
import { translate } from 'react-i18next'

const AppContainer = ({ store, history, children }) => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      {children}
    </ConnectedRouter>
  </Provider>
)

export default translate('translations')(AppContainer)
