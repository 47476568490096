import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'

const CredentialsFormScreen = ({
  handleSubmit, saveCredentials, inProgress, i18next
}) => (
  <div>
    <section className='content-header'>
      <h1>
        {i18next.t('app.credentials.form.title')}
        <small>{i18next.t('form')}</small>
      </h1>
    </section>

    <section className='content'>
      <div className='row'>
        <div className='col-md-6'>
          <div className='box box-primary'>
            <form onSubmit={handleSubmit(saveCredentials)}>
              <Field name='id' component='input' type='hidden' />
              <div className='box-body'>
                <div className='form-group'>
                  <label htmlFor='accountAlias'>{i18next.t('app.credentials.form.accountAlias')}</label>
                  <Field
                    name='accountAlias'
                    className='form-control'
                    component='input'
                    type='text'
                    required
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='customerId'>{i18next.t('app.credentials.form.customerId')}</label>
                  <Field
                    name='customerId'
                    className='form-control'
                    component='input'
                    type='text'
                    required
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='apiKey'>{i18next.t('app.credentials.form.apiKey')}</label>
                  <Field
                    name='apiKey'
                    className='form-control'
                    component='input'
                    type='text'
                    required
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='secretKey'>{i18next.t('app.credentials.form.secretKey')}</label>
                  <Field
                    name='secretKey'
                    className='form-control'
                    component='input'
                    type='text'
                    required
                  />
                </div>
              </div>

              <div className='box-footer'>
                <Link to='/credentials' className='btn btn-default'>{i18next.t('cancel')}</Link>
                <button type='submit' className='btn btn-info pull-right' disabled={inProgress}>
                  {inProgress ? <i className='fa fa-spinner fa-spin' /> : <span>{i18next.t('save')}</span>}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
)

export default reduxForm({ form: 'credentials' })(CredentialsFormScreen)
