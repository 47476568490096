import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import LogScreen from './log.screen'
import { logSelectEpochAction, logSetLevelVisibilityAction } from './log.module'
import i18n from '../translations/i18n'


class LogContainer extends PureComponent {
  render() {
    return (
      <LogScreen {...this.props} />
    )
  }
}

const mapStateToProps = (state) => ({
  i18next: i18n(),
  logLevels: ['TRANSACTION', 'ERROR', 'WARN', 'INFO', 'DEBUG'],
  logsData: state.logs,
  backtestId: state.backtest.info.id,
  jwt: state.auth.loggedFromAuth0.token
})
const mapDispatchToProps = (dispatch) => ({
  selectEpoch: (name, value) => dispatch(logSelectEpochAction(name, value)),
  setLevelVisibility: (logName, level, value) => dispatch(logSetLevelVisibilityAction({ logName, level, value }))
})
export default connect(mapStateToProps, mapDispatchToProps)(LogContainer)
