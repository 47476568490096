import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { isInRole } from '../../auth'

const downloadFile = (token) => {
  const headers = new Headers()
  headers.append('Authorization', `Bearer ${token}`)
  fetch('/api/reports', { headers })
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = `reports-${moment().format('YYYY-MM-DD[T]HHmm')}.xlsx`
      document.body.appendChild(a)
      a.click()
      a.remove()
    })
}

const ListItem = ({ rolesAvailable, roleRequired, children }) => {
  if (isInRole(rolesAvailable, roleRequired)) {
    return children
  }
  return null
}

const SidebarScreen = ({ roles, jwt, i18next }) => (
  <aside className='main-sidebar'>
    <section className='sidebar'>

      <ul className='sidebar-menu' data-widget='tree'>
        <li className='header'>{i18next.t('app.menu.mainNavigation.title')}</li>
        <ListItem rolesAvailable={roles} roleRequired='user'>
          <li>
            <Link to='/dashboard'>
              <i className='fa fa-dashboard' />
              <span>
                {i18next.t('app.menu.mainNavigation.dashboard')}
              </span>
            </Link>
          </li>
          <li>
            <Link to='/credentials'>
              <i className='fa fa-key' />
              <span>
                {i18next.t('app.menu.mainNavigation.credentials')}
              </span>
            </Link>
          </li>
          <li>
            <Link to='/strategies'>
              <i className='fa fa-play' />
              <span>
                {i18next.t('app.menu.mainNavigation.strategies')}
              </span>
            </Link>
          </li>
          <li>
            <Link to='/contact'>
              <i className='fa fa-envelope-o' />
              <span>
                {i18next.t('app.menu.mainNavigation.contact')}
              </span>
            </Link>
          </li>
          <li>
            <Link to='/howto'>
              <i className='fa fa-question' />
              <span>
                {i18next.t('app.menu.mainNavigation.howto')}
              </span>
            </Link>
          </li>
        </ListItem>
        <ListItem rolesAvailable={roles} roleRequired='admin'>
          <li className='header'>{i18next.t('app.menu.adminNavigation.title')}</li>
          <li>
            <Link to='/definitions'>
              <i className='fa fa-gear' />
              <span>
                {i18next.t('app.menu.adminNavigation.definitions')}
              </span>
            </Link>
          </li>
          <li>
            <Link to='/history'>
              <i className='fa fa-upload' />
              <span>
                {i18next.t('app.menu.adminNavigation.uploadHistory')}
              </span>
            </Link>
          </li>
          <li>
            <a onClick={() => downloadFile(jwt)} style={{ cursor: 'pointer' }}>
              <i className='fa fa-file-excel-o' />
              <span>
            Report
              </span>
            </a>
          </li>
          <li>
            <Link to='/users'>
              <i className='fa fa-users' />
              <span>
                {i18next.t('app.menu.adminNavigation.users')}
              </span>
            </Link>
          </li>
        </ListItem>
      </ul>
    </section>
  </aside>
)

export default SidebarScreen
