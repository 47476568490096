import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PrivateRouteScreen from './private-route.screen'

class PrivateRouteContainer extends PureComponent {
  render() {
    return (
      <PrivateRouteScreen {...this.props} />
    )
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps, null)(PrivateRouteContainer)
