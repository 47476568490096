import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import TacScreen from './tac.screen'
import i18n from '../../translations/i18n'
import { tacAcceptedAction } from '../preferences.module'
import { logoutAction } from '../../auth'

class TacContainer extends PureComponent {
  render() {
    return (
      <TacScreen {...this.props} />
    )
  }
}

const mapStateToProps = (state) => ({
  showTac: !state.preferences.acceptedTaC,
  i18next: i18n()
})

const mapDispatchToProps = (dispatch) => ({
  agree: () => dispatch(tacAcceptedAction()),
  logout: () => dispatch(logoutAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(TacContainer)
