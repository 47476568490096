import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import DashboardSummaryScreen from './dashboard-summary.screen'
import i18n from '../../../translations/i18n'
import { summaryPeriodChangeAction } from '../dashboard.module'

class DashboardSummaryContainer extends PureComponent {
  render() {
    return (
      <DashboardSummaryScreen {...this.props} />
    )
  }
}

const getData = (state) => {
  const { summary } = state.dashboard
  if (summary) {
    const data = []
    const currencies = state.price.currencies.map(currency => currency.code)
    if (summary && summary.balances) {
      Object.keys(summary.balances).forEach(key => {
        if (summary.balances[key] > 0.0001) {
          if (currencies.includes(key)) {
            data.push({
              currency: key.toUpperCase(),
              valueInCurrency: summary.balances[key],
              valueInUsd: summary.balancesInUsd[key]
            })
          }
        }
      })
    }
    return data
  }
  return undefined
}

const getFromByPeriod = (period, to) => {
  switch (period) {
    case '1D':
      return moment.unix(to).subtract(1, 'days').unix()
    case '1W':
      return moment.unix(to).subtract(1, 'weeks').unix()
    case '1M':
      return moment.unix(to).subtract(1, 'months').unix()
    default:
      return moment.unix(to).subtract(3, 'months').unix()
  }
}

const getHistoryData = (state) => {
  const { summary } = state.dashboard
  const currenciesAll = state.price.currencies.map(currency => currency.code)
  if (currenciesAll && summary && summary.historyInUsd && summary.historyInUsd.length > 1) {
    const history = []
    for (let i = 0; i < summary.history.length; i += 1) {
      const wallet = summary.history[i]
      const walletInUsd = summary.historyInUsd[i]
      const historyItem = { epochSecond: wallet.epochSecond }
      Object.keys(wallet)
        .filter(key => currenciesAll.includes(key))
        .filter(key => wallet[key] > 0.0001)
        .forEach(key => {
          historyItem[key] = wallet[key]
          historyItem[`${key}InUsd`] = walletInUsd[key]
        })
      history.push(historyItem)
    }

    const to = history[summary.historyInUsd.length - 1].epochSecond
    let from = getFromByPeriod(state.dashboard.summaryPeriod, to)
    if (from < history[0].epochSecond) {
      from = history[0].epochSecond
    }
    const currenciesUsed = new Set()
    history.forEach(item => Object.keys(item)
      .filter(name => currenciesAll.includes(name))
      .forEach(name => currenciesUsed.add(name)))

    return {
      from, to, data: history, currencies: [...currenciesUsed]
    }
  }
  return {}
}

const mapStateToProps = (state) => ({
  data: getData(state),
  history: getHistoryData(state),
  portfolio: state.dashboard.summary && state.dashboard.summary.portfolio,
  summaryPeriod: state.dashboard.summaryPeriod,
  i18next: i18n()
})

const mapDispatchToProps = (dispatch) => ({
  changeSummaryPeriod: (period) => dispatch(summaryPeriodChangeAction(period))
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardSummaryContainer)
