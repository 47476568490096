import React from 'react'
import i18n from '../translations/i18n'

const ConfirmScreen = ({
  id, title, body, confirmAction
}) => {
  const i18next = i18n()
  return (
    <div className='modal fade' id={id}>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
              <span aria-hidden='true'>&times;</span>
            </button>
            <h4 className='modal-title'>{title}</h4>
          </div>
          <div className='modal-body'>
            <p>{body}</p>
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-default pull-left' data-dismiss='modal'>{i18next && i18next.t('app.confirm.cancel')}</button>
            <button type='button' onClick={confirmAction} className='btn btn-primary' data-dismiss='modal'>
              {i18next && i18next.t('app.confirm.submit')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmScreen
