import React from 'react'
import { reduxForm } from 'redux-form'
import { Graph } from '../../graph'
import DashboardCredentials from './form-credentials'
import DashboardStrategy from './form-strategy'
import DashboardCredits from './form-credits'
import DashboardNotifications from './form-notifications'
import StrategySummary from '../../strategy/summary'
import DashboardSummary from './summary'


const DashboardScreen = ({ strategies }) => (
  <div style={{ marginTop: '1em' }}>

    <DashboardCredentials />

    <DashboardStrategy />

    <DashboardCredits />

    <DashboardNotifications />

    <DashboardSummary />

    <div className='col-md-12'>
      <Graph name='dashboard' />
    </div>
    {strategies.map(s => (
      <div key={s.id}>
        <StrategySummary strategy={s} showDetail />
      </div>
    ))}
  </div>
)


export default reduxForm({
  form: 'dashboard-credentials'
})(DashboardScreen)
