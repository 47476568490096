import React from 'react'
import { Link } from 'react-router-dom'

const statusLabel = (status) => {
  switch (status) {
    case 'ACTIVE':
      return 'label-success'
    case 'PASSIVE':
      return 'label-danger'
    default:
      return 'label-warning'
  }
}

const UserListScreen = ({
  users, loadUsers, i18next
}) => (
  <div>
    <section className='content'>

      <div className='row'>
        <div className='col-md-12'>
          <div className='box box-info'>
            <div className='box-header'>
              <h3 className='box-title'>{i18next.t('app.user.list.title')}</h3>
              <div className='box-tools pull-right'>
                <button type='button' className='btn btn-box-tool' onClick={() => loadUsers()}>
                  <i className='fa fa-refresh' />
                </button>
              </div>
            </div>
            <div className='box-body'>
              <div className='table-responsive'>
                <table className='table no-margin'>
                  <thead>
                    <tr>
                      <th>{i18next.t('app.user.list.id')}</th>
                      <th>{i18next.t('app.user.list.name')}</th>
                      <th>{i18next.t('app.user.list.email')}</th>
                      <th>{i18next.t('app.user.list.status')}</th>
                      <th>{i18next.t('app.user.list.balance')}</th>
                      <th>{i18next.t('app.user.list.lastLogin')}</th>
                      <th>{i18next.t('actions')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map(u => (
                      <tr key={u.id}>
                        <td>{u.id}</td>
                        <td>{u.name}</td>
                        <td>{u.email}</td>
                        <td>
                          <span className={`label ${statusLabel(u.status)}`}>
                            {i18next.t(`app.credits.userStatus.values.${u.status}`)}
                          </span>
                        </td>
                        <td>{u.balance}</td>
                        <td>{u.lastLoginAt}</td>
                        <td>
                          <Link to={`/impersonate/${u.id}`} target='_blank' className='btn btn-xs btn-info btn-flat'>
                            {i18next.t('app.user.list.loginAs')}
                          </Link>
                        </td>
                      </tr>))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

export default UserListScreen
