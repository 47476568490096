import React from 'react'
import 'rc-slider/assets/index.css'
import Slider from 'rc-slider'

const { Range } = Slider

const GraphSliderScreen = ({
  name, fromMin, toMax, from, to, onChange, onAfterChange
}) => (
  <Range
    min={fromMin}
    max={toMax}
    defaultValue={[from, to]}
    onChange={value => onChange(name, value)}
    onAfterChange={value => onAfterChange(name, value)}
  />
)

export default GraphSliderScreen
