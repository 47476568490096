import moment from 'moment'
import {
  all, put, select, takeLatest
} from 'redux-saga/effects'
import { change } from 'redux-form'
import { getFromLocalStorage, saveToLocalStorage } from '../../common'
import { LOGIN_SUCCESS } from '../../auth'
import { GRAPH_SET_LINE_VISIBILITY, graphInitAction, graphSetLineVisibilityAction } from '../../graph/graph.module'
import { CREDENTIALS_LOAD_OK } from '../../credentials/credentials.module'
import { STRATEGY_LOAD_OK } from '../../strategy/strategy.module'
import { callGetApiAction } from '../../api'

const SUMMARY_LOAD = 'SUMMARY_LOAD'
const SUMMARY_LOAD_OK = 'SUMMARY_LOAD_OK'

const SUMMARY_PERIOD_CHANGE = 'SUMMARY_PERIOD_CHANGE'

// ------------------------------------
// Actions
// ------------------------------------
const summaryLoadOkAction = (payload) => ({
  type: SUMMARY_LOAD_OK,
  payload
})
export const summaryPeriodChangeAction = (period) => ({
  type: SUMMARY_PERIOD_CHANGE,
  payload: {
    period
  }
})

// ------------------------------------
// Sagas
// ------------------------------------
function* dashboardInitSaga() {
  const selected = yield select((state) => state.dashboard.selectedExchangePairs)
  const initPayload = {
    name: 'dashboard',
    showPrices: true,
    fromMin: moment().subtract(1, 'years').unix(),
    from: moment().subtract(3, 'months').unix(),
    to: moment().unix(),
    toMax: moment().unix()
  }
  yield put(graphInitAction(initPayload))
  yield all(selected.map(code => put(graphSetLineVisibilityAction({
    graphName: 'dashboard', code, value: true
  }))))
}

function* getSummarySaga() {
  yield put(callGetApiAction('/api/summaries?period=3M', summaryLoadOkAction))
}

const getDefaultCredentials = (state) => {
  // true - when there is no strategy and user has single credentials or credentials named Default
  if (state.credentials.list) {
    if (state.strategy.list.length === 0) {
      const list = state.credentials.list.filter(cr => cr.status !== 'INVALID')
      if (list.length === 1) {
        return list[0]
      }
      const crDefault = state.credentials.list.find(cr => cr.status !== 'INVALID' && cr.accountAlias === 'Default')
      if (crDefault) {
        return crDefault
      }
    }
  }
  return undefined
}

export function getTradingValue(state, exchangePair) {
  const credentials = getDefaultCredentials(state)
  if (credentials && credentials.totalValues) {
    return Number(credentials.totalValues[exchangePair.substr(3)].toPrecision(2))
  }
  return 10000
}

function* dashboardTradingValueSaga(action) {
  if (action.meta.form === 'dashboard-strategy' && action.meta.field === 'exchangePair') {
    const tradingValue = yield select(state => getTradingValue(state, action.payload))
    yield put(change('dashboard-strategy', 'tradingValue', tradingValue))
  }
}

function* summaryPeriodChangeActionSaga(action) {
  yield saveToLocalStorage('summary-period', action.payload.period)
  // yield put(summaryLoadAction())
}

export function* dashboardSaga() {
  yield all([
    yield takeLatest(LOGIN_SUCCESS, dashboardInitSaga),
    yield takeLatest(LOGIN_SUCCESS, getSummarySaga),
    yield takeLatest(SUMMARY_LOAD, getSummarySaga),
    yield takeLatest(SUMMARY_PERIOD_CHANGE, summaryPeriodChangeActionSaga),
    yield takeLatest('@@redux-form/CHANGE', dashboardTradingValueSaga),
    yield takeLatest(CREDENTIALS_LOAD_OK, () => dashboardTradingValueSaga(
      { meta: { form: 'dashboard-strategy', field: 'exchangePair' }, payload: 'btcusd' }
    )),
    yield takeLatest(STRATEGY_LOAD_OK, () => dashboardTradingValueSaga(
      { meta: { form: 'dashboard-strategy', field: 'exchangePair' }, payload: 'btcusd' }
    ))
  ])
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  selectedExchangePairs: getFromLocalStorage('dashboard-prices', ['btcusd']),
  summary: undefined,
  summaryPeriod: getFromLocalStorage('summary-period', '3M')
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [GRAPH_SET_LINE_VISIBILITY]: (state, action) => {
    const { graphName, code, value } = action.payload
    if (graphName === 'dashboard') {
      const newState = Object.assign({}, state)
      if (value && !newState.selectedExchangePairs.includes(code)) {
        newState.selectedExchangePairs.push(code)
      } else if (!value && newState.selectedExchangePairs.includes(code)) {
        const index = newState.selectedExchangePairs.indexOf(code)
        newState.selectedExchangePairs.splice(index, 1)
      }
      saveToLocalStorage('dashboard-prices', newState.selectedExchangePairs)
      return newState
    }
    return state
  },
  [SUMMARY_LOAD_OK]: (state, action) => Object.assign({}, state, { summary: action.payload }),
  [SUMMARY_PERIOD_CHANGE]: (state, action) => Object.assign({}, state, { summaryPeriod: action.payload.period })
}

export default function (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
