import moment from 'moment'
import {
  all, call, fork, put, select, take, takeEvery, takeLatest
} from 'redux-saga/effects'
import { LOCATION_CHANGE, push } from 'react-router-redux'
import Auth from './auth'
import delay, { getFromLocalStorage, removeFromLocalStorage, saveToLocalStorage } from '../common'

// ------------------------------------
// Constants
// ------------------------------------
const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_AS_USER = 'LOGIN_AS_USER'
export const RENEW_TOKEN = 'RENEW_TOKEN'
export const RENEW_TOKEN_OK = 'RENEW_TOKEN_OK'
export const LOGOUT = 'LOGOUT'

// ------------------------------------
// Actions
// ------------------------------------
export const loginAction = () => ({
  type: LOGIN
})
export const loginSuccessAction = (authResult) => ({
  type: LOGIN_SUCCESS,
  payload: {
    ...authResult
  }
})
export const loginAsUserAction = (userId) => ({
  type: LOGIN_AS_USER,
  payload: {
    userId
  }
})
const renewTokenAction = () => ({
  type: RENEW_TOKEN
})
export const renewTokenOkAction = (authResult) => ({
  type: RENEW_TOKEN_OK,
  payload: {
    ...authResult
  }
})
export const logoutAction = () => ({
  type: LOGOUT
})

// ------------------------------------
// Sagas
// ------------------------------------
function loginSaga() {
  const auth = new Auth()
  auth.login()
}

function* loginSuccessSaga(action) {
  const userId = yield select(state => state.auth.userId)
  let pathName = getFromLocalStorage(`caLocation::${userId}`, '/dashboard')
  if (pathName.startsWith('/callback') || pathName.startsWith('/strategies') || pathName.includes('form')) {
    pathName = '/dashboard'
  }
  yield put(push(pathName))
  saveToLocalStorage('authPayload', action.payload)
  saveToLocalStorage('authExpiration', moment().add(action.payload.expiresIn, 'seconds').unix())
  yield call(delay, 5 * 60 * 1000)
  yield put(renewTokenAction())
}

function* renewTokenOkSaga(action) {
  saveToLocalStorage('authPayload', action.payload)
  saveToLocalStorage('authExpiration', moment().add(action.payload.expiresIn, 'seconds').unix())
  yield call(delay, 5 * 60 * 1000)
  yield put(renewTokenAction())
}

function* renewTokenSaga() {
  const auth = new Auth()
  const renewTokenChannel = auth.renewToken()
  const authResult = yield take(renewTokenChannel)
  if (authResult) {
    yield put(renewTokenOkAction(authResult))
  }
}

function* logoutSaga() {
  removeFromLocalStorage('authPayload')
  removeFromLocalStorage('authExpiration')
  yield put(push('/'))
  const auth = new Auth()
  auth.logout()
}

function* localStorageLoginSaga() {
  const { pathname } = window.location
  if (pathname.startsWith('/impersonate/')) {
    const loginAsUserId = pathname.substr(13)
    yield put(loginAsUserAction(decodeURI(loginAsUserId)))
  }
  const authExpiration = getFromLocalStorage('authExpiration', -1)
  if (authExpiration && moment().unix() < authExpiration) {
    const authPayload = getFromLocalStorage('authPayload')
    yield put(loginSuccessAction(authPayload))
  }
}

function* locationChangeSaga(action) {
  const { pathname } = action.payload
  const userId = yield select(state => state.auth.userId)
  saveToLocalStorage(`caLocation::${userId}`, pathname)
}

export function isInRole(rolesAvailable, roleRequired) {
  if (roleRequired === 'admin'
    && document.cookie.split(';').filter((item) => item.trim().startsWith('loginAs=')).length) {
    return false
  }
  if (rolesAvailable.includes(roleRequired)) {
    return true
  }
  return false
}

export function* authSaga() {
  yield all([
    fork(localStorageLoginSaga),
    yield takeLatest(LOGIN, loginSaga),
    yield takeEvery(LOGIN_SUCCESS, loginSuccessSaga),
    yield takeEvery(RENEW_TOKEN, renewTokenSaga),
    yield takeEvery(RENEW_TOKEN_OK, renewTokenOkSaga),
    yield takeEvery(LOGOUT, logoutSaga),
    yield takeEvery(LOCATION_CHANGE, locationChangeSaga)
  ])
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isAuthenticated: false,
  loggedFromAuth0: {
    token: null,
    roles: [],
    userId: null
  },
  roles: [],
  userId: null,
  loginAsUserId: null
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [LOGIN_SUCCESS]: (state, action) => ({
    isAuthenticated: true,
    loggedFromAuth0: {
      token: action.payload.idToken,
      roles: action.payload.idTokenPayload['https://coinacrobat.com/roles'],
      userId: action.payload.idTokenPayload.sub
    },
    roles: state.loginAsUserId ? ['user'] : action.payload.idTokenPayload['https://coinacrobat.com/roles'],
    userId: state.loginAsUserId ? state.loginAsUserId : action.payload.idTokenPayload.sub,
    loginAsUserId: state.loginAsUserId
  }),
  [LOGIN_AS_USER]: (state, action) => Object.assign({}, state, {
    roles: ['user'],
    userId: action.payload.userId,
    loginAsUserId: action.payload.userId
  }),
  [RENEW_TOKEN_OK]: (state, action) => ({
    isAuthenticated: true,
    loggedFromAuth0: {
      token: action.payload.idToken,
      roles: action.payload.idTokenPayload['https://coinacrobat.com/roles'],
      userId: action.payload.idTokenPayload.sub
    },
    roles: state.loginAsUserId ? ['user'] : action.payload.idTokenPayload['https://coinacrobat.com/roles'],
    userId: state.loginAsUserId ? state.loginAsUserId : action.payload.idTokenPayload.sub,
    loginAsUserId: state.loginAsUserId
  }),
  [LOGOUT]: () => initialState
}


export default function (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
