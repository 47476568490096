import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { change } from 'redux-form'
import DashboardNotificationsScreen from './dashboard-notifications.screen'
import i18n from '../../../translations/i18n'
import { notificationsSetupAction } from '../../../preferences/preferences.module'

class DashboardNotificationsContainer extends PureComponent {
  render() {
    return (
      <DashboardNotificationsScreen {...this.props} />
    )
  }
}

const getShouldSetupNotifications = (state) => {
  if (state.preferences.notificationEmail) {
    return false
  }
  return true
}
const isEmail = (email = null) => {
  // eslint-disable-next-line max-len
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(email)
}
const getCanActivate = (state) => {
  if (state.form['dashboard-notifications'] && state.form['dashboard-notifications'].values) {
    if (state.form['dashboard-notifications'].values.accept) {
      return isEmail(state.form['dashboard-notifications'].values.email)
    }
  }
  return false
}

const mapStateToProps = (state) => ({
  initialValues: { accept: false, language: navigator.language.substr(0, 2) },
  active: getShouldSetupNotifications(state),
  canActivate: getCanActivate(state),
  i18next: i18n()
})

const mapDispatchToProps = (dispatch) => ({
  submitAction: (values) => (dispatch(notificationsSetupAction(values.email, values.language))),
  switchAccept: (checked) => (dispatch(change('dashboard-notifications', 'accept', checked)))
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardNotificationsContainer)
