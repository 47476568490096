import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import i18n from '../../translations/i18n'
import CreditsOverviewScreen from './credits-overview.screen'

class CreditsOverviewContainer extends PureComponent {
  render() {
    return (
      <CreditsOverviewScreen {...this.props} />
    )
  }
}

const findCreditsDetail = (state) => {
  if (state.credits.selectedUserId && state.credits.userCreditsMap[state.credits.selectedUserId]
    && state.credits.userCreditsMap[state.credits.selectedUserId].balance) {
    return state.credits.userCreditsMap[state.credits.selectedUserId]
  } if (state.credits.userCreditsMap[state.auth.userId] && state.credits.userCreditsMap[state.auth.userId].balance) {
    return state.credits.userCreditsMap[state.auth.userId]
  }
  return {
    userStatus: 'PASSIVE',
    balance: 0,
    monthlyHistory: {}
  }
}

const mapStateToProps = (state) => ({
  i18next: i18n(),
  creditsDetail: findCreditsDetail(state),
  roles: state.auth.roles
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(CreditsOverviewContainer)
