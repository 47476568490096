import { all, put, takeLatest } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import { callPostApiAction } from '../api'

// ------------------------------------
// Constants
// ------------------------------------
const CONTACT_SEND = 'CONTACT_SEND'
const CONTACT_SEND_OK = 'CONTACT_SEND_OK'

// ------------------------------------
// Actions
// ------------------------------------
export const contactSendAction = (senderEmail, subject, body) => ({
  type: CONTACT_SEND,
  payload: {
    senderEmail,
    subject,
    body
  }
})
const contactSendOkAction = () => ({
  type: CONTACT_SEND_OK
})

// ------------------------------------
// Sagas
// ------------------------------------
function* contactSendSaga(action) {
  yield put(callPostApiAction('/api/contacts', action.payload, contactSendOkAction))
}

function* contactSendOkSaga() {
  yield put(push('/dashboard'))
}

export function* contactSaga() {
  yield all([
    yield takeLatest(CONTACT_SEND, contactSendSaga),
    yield takeLatest(CONTACT_SEND_OK, contactSendOkSaga)
  ])
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}

export default function (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
