import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import StrategyListScreen from './strategy-list.screen'
import {
  strategyDeleteAction,
  strategyLoadAction,
  strategyStartAction,
  strategyStopAction
} from '../strategy.module'
import i18n from '../../translations/i18n'

class StrategyListContainer extends PureComponent {
  render() {
    return (
      <StrategyListScreen {...this.props} />
    )
  }
}

const mapStateToProps = (state) => ({
  strategies: state.strategy.list,
  definitions: state.definitions,
  i18next: i18n()
})

const mapDispatchToProps = (dispatch) => ({
  deleteStrategy: id => dispatch(strategyDeleteAction(id)),
  loadStrategies: () => dispatch(strategyLoadAction()),
  changeStatusStrategy: (strategy) => {
    const strategyStop = strategyStopAction(strategy.id)
    const strategyStart = strategyStartAction(strategy.id)

    dispatch(strategy.status === 'RUNNING' ? strategyStop : strategyStart)
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(StrategyListContainer)
