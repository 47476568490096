const cs = {
  translations: {
    pageTitle: 'Coin Acrobat',
    actions: 'Akce',
    new: 'Nový',
    form: 'Formulář',
    cancel: 'Zrušit',
    save: 'Uložit',
    upload: 'Nahrát',
    active: 'Aktivni',
    notActive: 'Neaktivní',
    send: 'Odeslat',
    langs: {
      en: 'English',
      cs: 'čeština'
    },
    top: {
      credit: 'Kredit',
      loggedAs: 'Admin přihášen jako'
    },
    homepage: {
      login: 'Přihlášení',
      logout: 'Odhlásit',
      about: 'O nás',
      language: 'Jazyk',
      howItWorks: {
        title: 'Jak to funguje?',
        answer1: 'Robot vyhodnocuje investiční příležitosti každou minutu',
        answer2: 'Sami si vyberete, jaká strategie se vám zamlouvá',
        answer3: `Každá strategie obsahuje detailní popis, včetně příkladů jejího použití a Vy máte možnost ji
nejprve otestovat, dříve než začnete investovat opravdové peníze`,
        answer4: 'Díky velmi častému vyhodnocení situace může robot pracovat is velmi malými částkami od $5',
        answer5: `Robot provádí stejné operace, jaké by prováděl investor, ale nikdy se neunaví,
 pracuje 24 hodin denně, 365 dní v roce, nejí, nespí a nemá emoce, které by ovlivňovaly racionální úsudek`,
        answer6: 'Zaregistrujte se a uvidíte, jak je to jednoduché - jednoduchá matematika v pozadí každé strategie',
        registerBtn: 'Registrace'
      },
      widgets: {
        priceStats: {
          titleSufix: ' změnily hodnotu o',
          period: 'za posledních 24 hodin'
        },
        opportunities: {
          title: 'INVESTIČNÍ PŘÍLEŽITOSTÍ DENNĚ',
          investment: 'Možnost provést operaci každou minutu'
        }
      }
    },
    app: {
      menu: {
        mainNavigation: {
          title: 'HLAVNÍ NABÍDKA',
          dashboard: 'Přehled',
          credentials: 'Přístupové údaje k Bitstamp',
          strategies: 'Moje strategie',
          contact: 'Kontakt',
          howto: 'Jak na ...'
        },
        adminNavigation: {
          title: 'ADMINISTRAČNÍ NABÍDKA',
          definitions: 'Definice strategií',
          uploadHistory: 'Nahrání historie',
          users: 'Uživatelé'
        }
      },
      confirm: {
        submit: 'Potvrdit',
        cancel: 'Zrušit'
      },
      validation: {
        ok: 'OK',
        credentialsInvalid: {
          title: 'Error',
          body: `Vaše přístupové údaje byly odmítnuty. Můžete se prosím ujistit,
          že jste zkopírovali opravdu celý klíč a také že jste API klíč aktivovali pomocí emailové validace?
          Je schovaná za zeleným "Activate" tlačítkem v administračním rozhraní Bitstampu.`
        }
      },
      about: {
        line1: 'CoinAcrobat je technologická platforma pro řízení směny kryptoměn na burze třetí strany.',
        line2: 'Použití je upraveno smluvními podmínkami.',
        line3: 'Platforma je vytvářena a provozována společností Osomahe s.r.o.',
        contact: 'Kontakt',
        company: `Osomahe s.r.o. je vedená u Krajského soudu v Ostravě
pod spisovou značkou C 69138 se sídlem kpt. Nálepky 962/13, 779 00 Olomouc.`,
        close: 'Zavřít'
      },
      tac: {
        title: 'Podmínky užití Coin Acrobat',
        content: `<p>Souhlasím se smluvními podmínkami a podmínkami užití Coin Acrobat.&nbsp;
      <a href="https://s3.eu-central-1.amazonaws.com/com-coinacrobat/web/T%26Cs.pdf"
      target="_blank" rel="noopener noreferrer"><i class="fa fa-download" /></a></p>`,
        agree: 'Souhlasím',
        logout: 'Odhlásit'

      },
      dashboard: {
        title: 'Přehled',
        credentials: {
          title: 'Přístupové klíče k burze Bitstamp',
          submit: 'Uložit',
          description: `Vašemu účtu schází platné přístupové údaje k Bitstampu.
          Potřebujeme je abychom Vás připojili k obchodovací platformě a začali pro vás uskutečňovat transakce.
          Nebojte se, je to jednoduché. Vytvořili jsme pro Vás video, které vás povede abyste se v tom neztratili.`
        },
        strategy: {
          title: 'Strategie',
          submit: 'Spustit strategii',
          description: `Abychom se mohli pustit do obchodování, potřebujeme ještě několik informací.
          Nezabere to víc než dvě minuty. Podívejte se na video, které ukazuje jak Vám bude Vaše první strategie
          vydělávat a vyplňte základní informace. Než strategii spustíte, ujistěte se, že váš Bitstamp subaccount
          disponuje dostatečnými prostředky v odpovídající měně.`
        },
        credits: {
          title: 'Aktivace předplatného',
          description: `Moc rádi bychom Vám spustili strategii, ale Váš účet je v pasivním stavu.
Je možné, že ještě nemáte aktivováno předplatné? Pokud ano, můžete si jej aktivovat jedním kliknutím hned vedle,
na tlačítko PayPalu. Pro podrobnější informace mrkněte na přiložené video.`
        },
        notifications: {
          title: 'Notifikace',
          description: 'Chtěli byste dostávat email pokaždé, když pro Vás uskutečníme obchod? Stačí pár kliknutí.',
          accept: 'Přeji si dostávat emailové zprávy',
          submit: 'Aktivovat'
        },
        summary: {
          title: 'Přehled',
          balances: 'Aktuální zůstatky',
          totalValue: 'Celková hodnota',
          loading: 'Načítání...'
        }
      },
      credentials: {
        list: {
          title: 'Přístupové klíče k burze Bitstamp',
          accountAlias: 'Název účtu',
          customerId: 'ID zákazníka',
          status: {
            name: 'Stav',
            values: {
              UNKNOWN: 'Neznámý',
              VALID: 'Platný',
              INVALID: 'Neplatný'
            }
          }
        },
        form: {
          title: 'Přístupové údaje',
          accountAlias: 'Název účtu',
          customerId: 'ID zákazníka',
          apiKey: 'API klíč',
          secretKey: 'Tajný klíč'
        }
      },
      contact: {
        form: {
          contactUs: 'Kontaktujte nás',
          emailAddress: 'Emailová adresa',
          emailPlaceholder: 'Adresa, na kterou Vám můžeme odpovědět',
          selectTopic: 'Téma',
          errorReport: 'Nahlášení chyby',
          generalQuestion: 'Obecná otázka',
          improvementSuggestions: 'Návrh na zlepšení',
          creditIssue: 'Problém s kredity',
          other: 'Jiný',
          message: 'Zpráva'
        }
      },
      credits: {
        title: 'Kredit',
        summary: 'Přehled',
        userStatus: {
          name: 'Stav uživatele',
          values: {
            ACTIVE: 'Aktivní',
            PASSIVE: 'Pasivní',
            undefined: 'Neznámý'
          }
        },
        currentBalance: 'Stav účtu',
        userId: 'ID uživatele',
        topupAmount: 'Navýšit stav účtu',
        topup: 'Navýšit',
        monthlyHistory: {
          title: 'Měsíční přehled',
          month: 'Měsíc',
          gain: 'Příjmy',
          spend: 'Útrata',
          sum: 'Celkem',
          total: 'Celkem'
        },
        tac: `<p>Podmínky užití Coin Acrobat.&nbsp;
<a href="https://s3.eu-central-1.amazonaws.com/com-coinacrobat/web/T%26Cs.pdf"
target="_blank" rel="noopener noreferrer"><i class="fa fa-download" /></a></p>`,
        howTitle: 'Jak fungují kredity?',
        howBody: `
    <p>Abychom Vám mohli nabídnout kvalitní služby, je třeba za ně zaplatit pomocí systému kreditů.</p>
    <p>Kredit se spotřebovává ve dvou situacích:</p>
    <ul>
      <li><b>Měsíční předplatné</b> - každý měsíc spotřebuje Coin Acrobat 747.00 kreditů, kterými pokryjeme
náklady na provozování Vašeho účtu, včetně přístupu k celé škále historických dat včetně možnosti otestovat
si libovolnou strategii právě na nich kolikrát chcete s libovolnými parametry.
      </li>
      <li><b>Sazba za tik</b> - každý úspěšně provedený tik (např. každou minutu, hodinu... v závislosti na tom,
jak časté tikání si ve své strategii nastavíte) spotřebuje 0.017 kreditů. Tato částka je použita k pokrytí
nákladů na výkon serverů, provádějících samotnou strategii, na komunikaci s burzou atd.
      </li>
    </ul>
    <p>Váš účet se přepne do <span class='label label-danger'>Pasivního</span> stavu v případě, kdy stav vašeho
kreditů nebude dostatečný pro pokrytí měsíčního předplatného nebo v případě, kdy spotřebujete veškerý
kredit potřebný pro vykonání tiků. Protože víme, že občas něco trvá přeci jen trochu déle, necháme Váš
kreditový účet klesnout i do menšího mínusu, abyste měli dostatek času kredit doplnit. Pokud je stav kreditu
dostatečný k provedení strategie, váš účet se automaticky přepne do stavu
<span class='label label-success'>Aktivní</span>.</p>
    <p>Pro navýšení vašeho stavu kreditů, prosím použijte PayPal panel. Abychom Vám pomohli odhadnout, která
varianta předplatného je pro Vás ta pravé, připravili jsme několik propočtů:</p>
    <ul>
      <li><b>1 běžící strategie, tik každou hodinu:</b> 747 + (24 * 30.5 * 0.017) ~ <b>759 kreditů </b> měsíčně.
      </li>
      <li><b>1 běžící strategie, tik každou minutu:</b> 747 + (24 * 60 * 30.5 * 0.017) ~ <b>1 494 kreditů </b> měsíčně.
      </li>
      <li><b>2 běžící strategie, tik každou minutu:</b> 747 + 2 * (24 * 30.5 * 0.017) ~ <b>2 241 kreditů </b> měsíčně.
      </li>
      <li><b>5 běžících strategií, tik každou minutu:</b> 747 + 5 * (24 * 30.5 * 0.017) ~ <b>4 482 kreditů </b> měsíčně.
      </li>
    </ul>

        `,
        paypal: {
          821: '821 kreditů : €10.99 EUR - měsíčně',
          1494: '1494 kreditů : €19.98 EUR - měsíčně',
          2241: '2241 kreditů : €29.97 EUR - měsíčně',
          4482: '4482 kreditů : €59.94 EUR - měsíčně'
        }
      },
      strategy: {
        common: {
          name: 'Název',
          frequency: {
            name: 'Frekvence',
            values: {
              MINUTE: 'Minuta',
              HOUR: 'Hodina',
              DAY: 'Den',
              HOURS12: '12 hodin',
              HOURS8: '8 hodin',
              HOURS6: '6 hodin',
              HOURS4: '4 hodiny'
            }
          },
          status: {
            name: 'Stav',
            values: {
              RUNNING: 'Běžící',
              STOPPED: 'Zastavená'
            }
          }
        },
        list: {
          title: 'Moje strategie'
        },
        form: {
          title: 'Strategie',
          definition: 'Algoritmus',
          subTitle: 'Nastavení parametrů strategie',
          legend: 'Hlavní',
          namePlaceholder: 'Název strategie',
          credentials: 'Přístupové údaje k Bitstamp',
          parameters: 'Parametry',
          backTest: 'Testování na historických datech',
          start: 'Začátek',
          end: 'Konec',
          balance: 'stav konta',
          runBackTest: 'Spustit test',
          downloadLog: 'Stáhnout celý log'
        },
        summary: {
          deleteTitle: 'Odstranění',
          deleteBody: 'Chcete odstranit strategii {{name}}?',
          statusChangeTitle: 'Změna stavu',
          startBody: 'Chcete spustit {{name}} strategii?',
          stopBody: 'Chcete zastavit {{name}} strategii?',
          stop: 'Zastavit',
          start: 'Spustit',
          detail: 'Detail',
          edit: 'Upravit',
          delete: 'Odstranit',
          status: 'Stav',
          algorithm: 'Algoritmus',
          frequency: 'Frekvence',
          totalValue: 'Celková hodnota',
          balances: 'Aktuální zůstatky'
        }
      },
      definition: {
        list: {
          title: 'Definice strategií',
          name: 'Název',
          description: 'Popis',
          status: 'Stav'
        },
        form: {
          title: 'Definice strategie',
          name: 'Název',
          description: 'Popis',
          classBody: 'Class body'
        }
      },
      uploadHistory: {
        title: 'Nahrání historie ticků',
        exchangePair: 'Směnný pár',
        historyFile: 'Soubor s historickými daty',
        historyFileHelp: 'CSV soubor ve formátu: epoch;price'
      },
      howto: {
        video01: 'Připojení na Bitstamp',
        video02: 'První strategie',
        video03: 'Aktivace účtu',
        youtube: 'Přehrát na Youtube'
      },
      user: {
        list: {
          title: 'Uživatelé',
          id: 'Id',
          name: 'Jméno',
          email: 'Email',
          status: 'Stav',
          balance: 'Kredity',
          lastLogin: 'Poslední přihlášení',
          loginAs: 'Prihlásit se jako'
        }
      }
    }
  }
}

export default cs
