// ------------------------------------
// Constants
// ------------------------------------

const VALIDATION_MESSAGE_OPEN = 'VALIDATION_MESSAGE_OPEN'
const VALIDATION_MESSAGE_CLOSE = 'VALIDATION_MESSAGE_CLOSE'

// ------------------------------------
// Actions
// ------------------------------------
export const validationMessageOpenAction = (messageKey) => ({
  type: VALIDATION_MESSAGE_OPEN,
  payload: {
    messageKey
  }
})
export const validationMessageCloseAction = () => ({
  type: VALIDATION_MESSAGE_CLOSE
})

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  validationModal: {
    active: false,
    messageKey: undefined
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [VALIDATION_MESSAGE_OPEN]: (state, action) => {
    const validationModal = { active: true, messageKey: action.payload.messageKey }
    return Object.assign({}, state, { validationModal })
  },
  [VALIDATION_MESSAGE_CLOSE]: (state) => Object
    .assign({}, state, { validationModal: initialState.validationModal })
}

export default function (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
