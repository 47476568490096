import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import ContactFormScreen from './contact-form.screen'
import i18n from '../../translations/i18n'
import { contactSendAction } from '../contact.module'

class ContactFormContainer extends PureComponent {
  render() {
    return (
      <div>
        <ContactFormScreen {...this.props} />
      </div>
    )
  }
}

const mapStateToProps = () => ({
  initialValues: {},
  i18next: i18n()
})

const mapDispatchToProps = (dispatch) => ({
  send: (values) => (
    dispatch(contactSendAction(values.senderEmail, values.subject, values.body))
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactFormContainer)
