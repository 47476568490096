import moment from 'moment'
import { LOCATION_CHANGE } from 'react-router-redux'
import {
  all, call, put, select, takeEvery
} from 'redux-saga/effects'
import { callGetApiAction, callPostApiAction } from '../api/index'
import { graphAddDataAction, graphClearAction, graphInitAction } from '../graph/graph.module'
import delay from '../common'
import {
  logAddRecordsAction, logAddTradesAction, logClearAction, logInitAction
} from '../log/log.module'

// ------------------------------------
// Constants
// ------------------------------------

const BACKTEST_RESET = 'BACKTEST_RESET'
const BACKTEST_SET_STATUS = 'BACKTEST_SET_STATUS'
const BACKTEST_START = 'BACKTEST_START'
const BACKTEST_START_OK = 'BACKTEST_START_OK'
const BACKTEST_LOAD = 'BACKTEST_LOAD'
const BACKTEST_LOAD_OK = 'BACKTEST_LOAD_OK'
const BACKTEST_GRAPH = 'BACKTEST_GRAPH'
const BACKTEST_GRAPH_OK = 'BACKTEST_GRAPH_OK'
const BACKTEST_GRAPH_TIME_RANGE_CHANGED = 'BACKTEST_GRAPH_TIME_RANGE_CHANGED'
const BACKTEST_LOG = 'BACKTEST_LOG'
const BACKTEST_LOG_OK = 'BACKTEST_LOG_OK'
const BACKTEST_TRADE = 'BACKTEST_TRADE'
const BACKTEST_TRADE_OK = 'BACKTEST_TRADE_OK'

// ------------------------------------
// Actions
// ------------------------------------
const backtestResetAction = () => ({
  type: BACKTEST_RESET
})
export const backtestSetStatusAction = (isActive) => ({
  type: BACKTEST_SET_STATUS,
  payload: {
    isActive
  }
})
export const backtestStartAction = (payload) => ({
  type: BACKTEST_START,
  payload
})
const backtestStartOkAction = (payload) => ({
  type: BACKTEST_START_OK,
  payload
})
const backtestLoadAction = (id) => ({
  type: BACKTEST_LOAD,
  payload: {
    id
  }
})
const backtestLoadOkAction = (payload) => ({
  type: BACKTEST_LOAD_OK,
  payload
})
const backtestGraphAction = () => ({
  type: BACKTEST_GRAPH
})
const backtestGraphOkAction = (payload) => ({
  type: BACKTEST_GRAPH_OK,
  payload
})
const backtestGraphTimeRangeChangedAction = (from, to) => ({
  type: BACKTEST_GRAPH_TIME_RANGE_CHANGED,
  payload: {
    from,
    to
  }
})
const backtestLogAction = (from, to) => ({
  type: BACKTEST_LOG,
  payload: {
    from,
    to
  }
})
const backtestLogOkAction = (payload) => ({
  type: BACKTEST_LOG_OK,
  payload
})
const backtestTradeAction = () => ({
  type: BACKTEST_TRADE
})
const backtestTradeOkAction = (payload) => ({
  type: BACKTEST_TRADE_OK,
  payload
})

// ------------------------------------
// Sagas
// ------------------------------------
function* backtestResetSaga(action) {
  if (action.payload.pathname.includes('/strategies/form')) {
    yield all([
      yield put(backtestResetAction()),
      yield put(graphClearAction('backtest')),
      yield put(logClearAction('backtest'))
    ])
  }
}

function* backtestStartSaga(action) {
  const payloadRequest = {}
  Object.keys(action.payload).filter(key => key !== 'params').forEach(key => {
    payloadRequest[key] = action.payload[key]
  })
  payloadRequest.params = {}
  Object.keys(action.payload.params[action.payload.definitionId]).forEach(key => {
    payloadRequest.params[key] = action.payload.params[action.payload.definitionId][key]
  })
  yield all([
    yield put(callPostApiAction('/api/backtests', payloadRequest, backtestStartOkAction)),
    yield put(graphClearAction('backtest')),
    yield put(logClearAction('backtest'))
  ])
}

function* backtestLoadSaga(action) {
  yield call(delay, 2000)
  yield put(callGetApiAction(
    `/api/backtests/${action.payload.id}`,
    backtestLoadOkAction, () => backtestLoadAction(action.payload.id)
  ))
}

function* backtestLoadOkSaga(action) {
  yield call(delay, 5000)
  const backtest = yield select((state) => state.backtest.info)
  if (backtest.status === 'IN_PROGRESS') {
    yield put(callGetApiAction(
      `/api/backtests/${action.payload.id}`, backtestLoadOkAction,
      () => backtestLoadAction(action.payload.id)
    ))
  }
  if (['DONE', 'ERROR', 'TIMEOUT'].includes(backtest.status)) {
    const graphInitPayload = {
      name: 'backtest',
      showPrices: true,
      fromMin: moment(backtest.start).unix(),
      from: moment(backtest.start).unix(),
      to: moment(backtest.end).unix(),
      toMax: moment(backtest.end).unix(),
      onTimeRangeChangedAction: backtestGraphTimeRangeChangedAction
    }
    const logInitPayload = {
      name: 'backtest',
      frequency: backtest.frequency,
      from: moment(backtest.start).unix(),
      to: moment(backtest.end).unix(),
      onSelectedEpochChange: backtestLogAction,
      selectedEpochFrom: moment(backtest.end).subtract(2, 'hours').unix(),
      selectedEpochTo: moment(backtest.end).unix()
    }
    yield all([
      yield put(graphInitAction(graphInitPayload)),
      yield put(logInitAction(logInitPayload)),
      yield put(backtestGraphAction()),
      yield put(backtestLogAction(logInitPayload.selectedEpochFrom, logInitPayload.selectedEpochTo)),
      yield put(backtestTradeAction())
    ])
  }
}

function* backtestGraphSaga() {
  const backtest = yield select((state) => state.backtest.info)
  const path = `/api/backtests/${backtest.id}/graphs?from=${moment(backtest.start).unix()}&to=${moment(backtest.end).unix()}`
  yield put(callGetApiAction(path, backtestGraphOkAction))
}

function* backtestGraphOkSaga(action) {
  yield put(graphAddDataAction('backtest', action.payload))
}

function* backtestGraphTimeRangeChangedSaga(action) {
  const backtest = yield select((state) => state.backtest.info)
  const pathGraph = `/api/backtests/${backtest.id}/graphs?from=${action.payload.from}&to=${action.payload.to}`
  const pathTrades = `/api/backtests/${backtest.id}/trades?from=${action.payload.from}&to=${action.payload.to}`
  yield all([
    yield put(callGetApiAction(pathGraph, backtestGraphOkAction)),
    yield put(callGetApiAction(pathTrades, backtestTradeOkAction))
  ])
}

function* backtestLogSaga(action) {
  const { from, to } = action.payload
  const backtestId = yield select((state) => state.backtest.info.id)
  const path = `/api/backtests/${backtestId}/logs?from=${from}&to=${to}`
  yield put(callGetApiAction(path, backtestLogOkAction))
}

function* backtestLogOkSaga(action) {
  yield put(logAddRecordsAction('backtest', action.payload))
}

function* backtestTradeSaga() {
  const backtest = yield select((state) => state.backtest.info)
  const path = `/api/backtests/${backtest.id}/trades?from=${moment(backtest.start).unix()}&to=${moment(backtest.end).unix()}`
  yield put(callGetApiAction(path, backtestTradeOkAction))
}

function* backtestTradeOkSaga(action) {
  yield put(logAddTradesAction('backtest', action.payload))
  const payload = action.payload.map(trx => {
    const key = trx.operation === 'BUY' ? 'buy' : 'sell'
    const item = { epochSecond: trx.epochSecond }
    item[key] = trx.price
    return item
  })
  yield put(graphAddDataAction('backtest', payload))
}


export function* backtestSaga() {
  yield all([
    yield takeEvery(LOCATION_CHANGE, backtestResetSaga),
    yield takeEvery(BACKTEST_START, backtestStartSaga),
    yield takeEvery(BACKTEST_START_OK, backtestLoadSaga),
    yield takeEvery(BACKTEST_LOAD, backtestLoadSaga),
    yield takeEvery(BACKTEST_LOAD_OK, backtestLoadOkSaga),
    yield takeEvery(BACKTEST_GRAPH, backtestGraphSaga),
    yield takeEvery(BACKTEST_GRAPH_OK, backtestGraphOkSaga),
    yield takeEvery(BACKTEST_GRAPH_TIME_RANGE_CHANGED, backtestGraphTimeRangeChangedSaga),
    yield takeEvery(BACKTEST_LOG, backtestLogSaga),
    yield takeEvery(BACKTEST_LOG_OK, backtestLogOkSaga),
    yield takeEvery(BACKTEST_TRADE, backtestTradeSaga),
    yield takeEvery(BACKTEST_TRADE_OK, backtestTradeOkSaga)
  ])
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  displayForm: false,
  displayResults: false,
  info: {
    status: 'NONE',
    progress: 0,
    finalWallet: {}
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [BACKTEST_RESET]: () => Object.assign({}, initialState),
  [BACKTEST_START]: () => Object.assign({}, initialState, {
    displayForm: true,
    displayResults: true
  }),
  [BACKTEST_SET_STATUS]: (state, action) => Object.assign({}, state, { displayForm: action.payload.isActive }),
  [BACKTEST_LOAD_OK]: (state, action) => Object.assign({}, state, { info: action.payload })
}

export default function (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
