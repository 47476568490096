import React from 'react'
import { Link } from 'react-router-dom'

const TopScreen = ({
  fullName, avatarUrl, creditsDetail, logout, showAbout, impersonating, i18next, changeLanguage
}) => (
  <header className='main-header'>

    <a href='' className='logo'>
      <span className='logo-mini'>
        <b>S</b>
R
      </span>
      <span className='logo-lg'>
        <img src='/assets/img/logo-small.png' alt='Coin Acrobat' style={{ width: '2em', display: 'inline' }} />
        <b>Coin</b>
Acrobat
      </span>
    </a>

    <nav className='navbar navbar-static-top' style={{ backgroundColor: impersonating ? '#f39c12' : '' }}>
      <a style={{ cursor: 'pointer' }} className='sidebar-toggle' data-toggle='push-menu' role='button'>
        <span className='sr-only'>Toggle navigation</span>
      </a>
      <div className='navbar-custom-menu'>
        <ul className='nav navbar-nav'>
          <li className='dropdown user user-menu'>
            <Link to='/credits'>
              {i18next.t('top.credit')}
:
              {creditsDetail.balance.toFixed(2)}
            </Link>

          </li>
          <li className='dropdown user user-menu'>
            <Link to='/credits' className={creditsDetail.userStatus === 'ACTIVE' ? 'label label-success' : 'label label-danger'}>
              {i18next.t(`app.credits.userStatus.values.${creditsDetail.userStatus}`)}
            </Link>
          </li>
          <li className='dropdown user user-menu'>
            <a>
              { impersonating
                ? (
                  <span style={{ float: 'left' }}>
                    {i18next.t('top.loggedAs')}
                    &nbsp;&nbsp;
                  </span>
                )
                : null }
              <img src={avatarUrl} className='user-image' alt='Avatar' />
              <span className='hidden-xs'>{fullName}</span>
            </a>
          </li>
          <li>
            <a style={{ cursor: 'pointer' }} data-toggle='dropdown' aria-expanded='false' title={i18next.t('homepage.language')}>
              <i className='fa fa-language' />
            </a>
            <ul className='dropdown-menu'>
              <li>
                <a style={{ cursor: 'pointer' }} onClick={() => changeLanguage('en')}>
                  {i18next.t('langs.en')}
                </a>
              </li>
              <li>
                <a style={{ cursor: 'pointer' }} onClick={() => changeLanguage('cs')}>
                  {i18next.t('langs.cs')}
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a style={{ cursor: 'pointer' }} onClick={logout} data-toggle='control-sidebar' title={i18next.t('homepage.logout')}>
              <span className='glyphicon glyphicon-log-out' />
            </a>
          </li>
          <li>
            <a style={{ cursor: 'pointer' }} onClick={showAbout} data-toggle='control-sidebar' title={i18next.t('homepage.about')}>
              <span className='fa fa-info-circle' />
            </a>
          </li>
        </ul>
      </div>

    </nav>
  </header>
)

export default TopScreen
