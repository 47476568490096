import React from 'react'
import ReactPlayer from 'react-player'

const DashboardCreditsScreen = ({ active, invoice, i18next }) => active && (
  <div className='col-md-12'>
    <div className='small-box bg-yellow'>
      <div className='inner'>
        <div className='box-header bg-yellow'>
          <h4 style={{ margin: 0 }}>
            <i className='fa fa-road' />
&nbsp;
            {i18next.t('app.dashboard.credits.title')}
          </h4>
        </div>
        <div className='col-md-4'>
          {i18next.t('app.dashboard.credits.description')}
        </div>
        <div className='col-md-4'>
          <div className='box-footer box-comments'>
            <form action='https://www.paypal.com/cgi-bin/webscr' method='post' target='_top'>
              <div className='form-group'>
                <input type='hidden' name='cmd' value='_xclick-subscriptions' />
                <input type='hidden' name='business' value='admin@osomahe.net' />
                <input type='hidden' name='lc' value='CZ' />
                <input type='hidden' name='item_name' value='Subscription' />
                <input type='hidden' name='item_number' value='PayPal-subscription' />
                <input type='hidden' name='no_note' value='1' />
                <input type='hidden' name='no_shipping' value='1' />
                <input type='hidden' name='rm' value='1' />
                <input type='hidden' name='return' value='https://www.coinacrobat.com/success' />
                <input type='hidden' name='cancel_return' value='https://www.coinacrobat.com/cancel' />
                <input type='hidden' name='src' value='1' />
                <input type='hidden' name='currency_code' value='EUR' />
                <input type='hidden' name='notify_url' value='https://www.coinacrobat.com/api/paypal/webhook' />
                <input type='hidden' name='bn' value='PP-SubscriptionsBF:btn_subscribeCC_LG.gif:NonHosted' />
                <input type='hidden' name='invoice' value={invoice} />
                <input type='hidden' name='custom' value='custom' />
                <table>
                  <tbody>
                    <tr>
                      <td><input type='hidden' name='on0' value='' /></td>
                    </tr>
                    <tr>
                      <td>
                        <select name='os0' defaultValue='1494 credits' className='form-control'>
                          <option value='821 credits'>{i18next.t('app.credits.paypal.821')}</option>
                          <option value='1494 credits'>{i18next.t('app.credits.paypal.1494')}</option>
                          <option value='2241 credits'>{i18next.t('app.credits.paypal.2241')}</option>
                          <option value='4482 credits'>{i18next.t('app.credits.paypal.4482')}</option>
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <input type='hidden' name='option_select0' value='821 credits' />
                <input type='hidden' name='option_amount0' value='10.99' />
                <input type='hidden' name='option_period0' value='M' />
                <input type='hidden' name='option_frequency0' value='1' />
                <input type='hidden' name='option_select1' value='1494 credits' />
                <input type='hidden' name='option_amount1' value='19.98' />
                <input type='hidden' name='option_period1' value='M' />
                <input type='hidden' name='option_frequency1' value='1' />
                <input type='hidden' name='option_select2' value='2241 credits' />
                <input type='hidden' name='option_amount2' value='29.97' />
                <input type='hidden' name='option_period2' value='M' />
                <input type='hidden' name='option_frequency2' value='1' />
                <input type='hidden' name='option_select3' value='4482 credits' />
                <input type='hidden' name='option_amount3' value='59.94' />
                <input type='hidden' name='option_period3' value='M' />
                <input type='hidden' name='option_frequency3' value='1' />
                <input type='hidden' name='option_index' value='0' />
              </div>
              <div className='form-group'>
                <input
                  type='image'
                  src='https://www.paypalobjects.com/en_US/i/btn/btn_subscribeCC_LG.gif'
                  border='0'
                  name='submit'
                  alt='PayPal - The safer, easier way to pay online!'
                />
                <img alt='' border='0' src='https://www.paypalobjects.com/en_US/i/scr/pixel.gif' width='1' height='1' />
              </div>
            </form>
          </div>
        </div>
        <div className='col-md-4'>
          <ReactPlayer
            url='https://www.youtube.com/watch?v=aUfjtWEhSEI&list=PLLDFInTMdfLA_0dGj6TLEcVOZUYTTeoyg'
            width='250px'
            height='133px'
            controls
          />
        </div>
        <a href='#' className='small-box-footer' style={{ clear: 'both' }}>
          &nbsp;
        </a>
      </div>
    </div>
  </div>
)
export default DashboardCreditsScreen
