import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import CredentialsFormScreen from './credentials-form.screen'
import { credentialsSaveAction } from '../credentials.module'
import i18n from '../../translations/i18n'

class CredentialsFormContainer extends PureComponent {
  render() {
    return (
      <div>
        <CredentialsFormScreen {...this.props} />
      </div>
    )
  }
}

const getCredentials = (id, state) => state.credentials.list.find(item => item.id === id)

const mapStateToProps = (state, ownProps) => ({
  initialValues: (ownProps.match.params.id) ? getCredentials(ownProps.match.params.id, state) : {},
  inProgress: state.credentials.inProgress,
  i18next: i18n()
})

const mapDispatchToProps = (dispatch) => ({
  saveCredentials: (values) => (
    dispatch(credentialsSaveAction(values.id, values.accountAlias, values.customerId, values.apiKey, values.secretKey))
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(CredentialsFormContainer)
