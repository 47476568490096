import React from 'react'
import CreditsSearch from '../search'
import CreditsTopup from '../topup'
import CreditsHow from '../how'
import CreditsPaypal from '../paypal'

const CreditsOverviewScreen = ({ i18next, creditsDetail }) => (
  <div>
    <section className='content-header'>
      <h1>
        {i18next.t('app.credits.title')}
      </h1>
    </section>

    <section className='content'>
      <div className='row'>
        <div className='col-md-5'>
          <div className='box box-info'>
            <div className='box-header with-border'>
              <h3 className='box-title'>{i18next.t('app.credits.summary')}</h3>
            </div>
            <div className='form-horizontal'>
              <div className='box-body'>
                <div className='form-group'>
                  <label className='col-sm-3'>{i18next.t('app.credits.userStatus.name')}</label>
                  <div className='col-sm-3'>
                    <span
                      className={creditsDetail.userStatus === 'ACTIVE' ? 'label label-success' : 'label label-danger'}
                    >
                      {i18next.t(`app.credits.userStatus.values.${creditsDetail.userStatus}`)}
                    </span>
                  </div>
                </div>
                <div className='form-group'>
                  <label className='col-sm-3'>{i18next.t('app.credits.currentBalance')}</label>
                  <div className='col-sm-3'>{creditsDetail.balance.toFixed(2)}</div>
                </div>
                <div className='form-group'>
                  <label className='col-sm-3'>{i18next.t('app.credits.userId')}</label>
                  <CreditsSearch />
                </div>
              </div>
            </div>
          </div>
        </div>

        <CreditsTopup />

        <CreditsPaypal widthCols='3' />

        <div
          className='col-md-9'
          dangerouslySetInnerHTML={{ __html: i18next.t('app.credits.tac', { interpolation: { escapeValue: false } }) }}
        />

        <CreditsHow />

        <div className='col-md-9'>
          <div className='box box-info'>
            <div className='box-header'>
              <h3 className='box-title'>{i18next.t('app.credits.monthlyHistory.title')}</h3>
            </div>
            <div className='box-body'>
              <div className='table-responsive'>
                <table className='table no-margin'>
                  <thead>
                    <tr>
                      <th>{i18next.t('app.credits.monthlyHistory.month')}</th>
                      <th>{i18next.t('app.credits.monthlyHistory.gain')}</th>
                      <th>{i18next.t('app.credits.monthlyHistory.spend')}</th>
                      <th>{i18next.t('app.credits.monthlyHistory.sum')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(creditsDetail.monthlyHistory).sort().reverse().map(key => (
                      <tr key={key}>
                        <td>{key}</td>
                        <td>{creditsDetail.monthlyHistory[key].gain.toFixed(2)}</td>
                        <td>{creditsDetail.monthlyHistory[key].spend.toFixed(2)}</td>
                        <td>
                          {(creditsDetail.monthlyHistory[key].gain
                            + creditsDetail.monthlyHistory[key].spend).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot style={{ fontWeight: 'bold' }}>
                    <tr>
                      <td>{i18next.t('app.credits.monthlyHistory.total')}</td>
                      <td>
                        {Object
                          .values(creditsDetail.monthlyHistory)
                          .map(val => val.gain).reduce((a, b) => a + b, 0)
                          .toFixed(2)}
                      </td>
                      <td>
                        {Object
                          .values(creditsDetail.monthlyHistory)
                          .map(val => val.spend).reduce((a, b) => a + b, 0)
                          .toFixed(2)}
                      </td>
                      <td>
                        {Object
                          .values(creditsDetail.monthlyHistory)
                          .map(val => val.gain + val.spend)
                          .reduce((a, b) => a + b, 0)
                          .toFixed(2)}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

export default CreditsOverviewScreen
