import React from 'react'

const TacScreen = ({
  showTac, agree, logout, i18next
}) => (
  <div className='modal fade in' id='tac' style={{ display: (showTac ? 'block' : 'none') }}>
    <div className='modal-dialog'>
      <div className='modal-content'>
        <div className='modal-header'>
          <h4 className='modal-title'>{i18next.t('app.tac.title')}</h4>
        </div>
        <div
          className='modal-body'
          dangerouslySetInnerHTML={{ __html: i18next.t('app.tac.content', { interpolation: { escapeValue: false } }) }}
        />
        <div className='modal-footer'>
          <button type='button' onClick={logout} className='btn btn-default pull-left'>
            {
            i18next.t('app.tac.logout')}
          </button>
          <button type='button' onClick={agree} className='btn btn-primary' data-dismiss='modal'>
            {i18next.t('app.tac.agree')}
          </button>
        </div>
      </div>
    </div>
  </div>
)

export default TacScreen
