import React from 'react'
import { Field, reduxForm } from 'redux-form'

const ContactFormScreen = ({ handleSubmit, send, i18next }) => (
  <div>
    <section className='content'>
      <div className='row'>
        <div className='col-md-6'>
          <div className='box box-primary'>
            <div className='box-header with-border'>
              <h3 className='box-title'>{i18next.t('app.contact.form.contactUs')}</h3>
            </div>
            <form onSubmit={handleSubmit(send)}>
              <div className='box-body'>
                <div className='form-group'>
                  <label htmlFor='senderEmail'>{i18next.t('app.contact.form.emailAddress')}</label>
                  <Field
                    name='senderEmail'
                    className='form-control'
                    component='input'
                    type='email'
                    placeholder={i18next.t('app.contact.form.emailPlaceholder')}
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='subject'>{i18next.t('app.contact.form.selectTopic')}</label>
                  <Field name='subject' className='form-control' component='select'>
                    <option value={i18next.t('app.contact.form.errorReport')}>
                      {i18next.t('app.contact.form.errorReport')}
                    </option>
                    <option value={i18next.t('app.contact.form.generalQuestion')}>
                      {i18next.t('app.contact.form.generalQuestion')}
                    </option>
                    <option value={i18next.t('app.contact.form.improvementSuggestions')}>
                      {i18next.t('app.contact.form.improvementSuggestions')}
                    </option>
                    <option value={i18next.t('app.contact.form.creditIssue')}>
                      {i18next.t('app.contact.form.creditIssue')}
                    </option>
                    <option value={i18next.t('app.contact.form.other')}>
                      {i18next.t('app.contact.form.other')}
                    </option>
                  </Field>
                </div>
                <div className='form-group'>
                  <label htmlFor='body'>Message</label>
                  <Field name='body' className='form-control' component='textarea' rows='3' type='text' />
                </div>
              </div>
              <div className='box-footer'>
                <button type='submit' className='btn btn-info pull-right'>{i18next.t('send')}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
)

export default reduxForm({ form: 'contact' })(ContactFormScreen)
