import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import DefinitionFormScreen from './definition-form.screen'
import { definitionSaveAction } from '../definition.module'
import i18n from '../../translations/i18n'

class DefinitionFormContainer extends PureComponent {
  render() {
    return (
      <div>
        <DefinitionFormScreen {...this.props} />
      </div>
    )
  }
}

const getDefinition = (id, state) => state.definitions.find(item => item.id === id)

const mapStateToProps = (state, ownProps) => ({
  i18next: i18n(),
  initialValues: (ownProps.match.params.id) ? getDefinition(ownProps.match.params.id, state) : {}
})

const mapDispatchToProps = (dispatch) => ({
  saveDefinition: values => dispatch(definitionSaveAction(values.id, values.name, values.description, values.classBody))
})

export default connect(mapStateToProps, mapDispatchToProps)(DefinitionFormContainer)
