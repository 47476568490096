import {
  all, put, select, takeEvery, takeLatest, delay
} from 'redux-saga/effects'
import { LOCATION_CHANGE, push } from 'react-router-redux'
import { LOGIN_SUCCESS } from '../auth'
import {
  callDeleteApiAction, callGetApiAction, callPostApiAction, callPutApiAction
} from '../api'

// ------------------------------------
// Constants
// ------------------------------------
const CREDENTIALS_LOAD = 'CREDENTIALS_LOAD'
export const CREDENTIALS_LOAD_OK = 'CREDENTIALS_LOAD_OK'

const CREDENTIALS_CREATE = 'CREDENTIALS_CREATE'
const CREDENTIALS_CREATE_OK = 'CREDENTIALS_CREATE_OK'
const CREDENTIALS_CREATE_ERROR = 'CREDENTIALS_CREATE_ERROR'

const CREDENTIALS_UPDATE = 'CREDENTIALS_UPDATE'
const CREDENTIALS_UPDATE_OK = 'CREDENTIALS_UPDATE_OK'

const CREDENTIALS_DELETE = 'CREDENTIALS_DELETE'
const CREDENTIALS_DELETE_OK = 'CREDENTIALS_DELETE_OK'

// ------------------------------------
// Actions
// ------------------------------------
export const credentialsLoadAction = () => ({
  type: CREDENTIALS_LOAD
})
const credentialsLoadOkAction = (payload) => ({
  type: CREDENTIALS_LOAD_OK,
  payload
})
export const credentialsSaveAction = (id, accountAlias, customerId, apiKey, secretKey) => ({
  type: id ? CREDENTIALS_UPDATE : CREDENTIALS_CREATE,
  payload: {
    id,
    accountAlias,
    customerId,
    apiKey,
    secretKey,
    status: 'UNKNOWN'
  }
})
const credentialsCreateOkAction = (payload) => ({
  type: CREDENTIALS_CREATE_OK,
  payload
})
const credentialsCreateErrorAction = () => ({
  type: CREDENTIALS_CREATE_ERROR
})
const credentialsUpdateOkAction = (payload) => ({
  type: CREDENTIALS_UPDATE_OK,
  payload
})
export const credentialsDeleteAction = (id) => ({
  type: CREDENTIALS_DELETE,
  payload: {
    id
  }
})
const credentialsDeleteOkAction = (id) => ({
  type: CREDENTIALS_DELETE_OK,
  payload: {
    id
  }
})

// ------------------------------------
// Sagas
// ------------------------------------
function* credentialsLoadSaga() {
  yield put(callGetApiAction('/api/credentials', credentialsLoadOkAction))
}

function* credentialsCreateSaga(action) {
  yield put(callPostApiAction(
    '/api/credentials',
    action.payload,
    credentialsCreateOkAction,
    credentialsCreateErrorAction
  ))
}

function* credentialsCreateOkSaga() {
  const pathname = yield select((state) => state.routing.location.pathname)
  if (pathname !== '/dashboard') {
    yield put(push('/credentials'))
  }
  yield delay(1000)
  yield put(credentialsLoadAction())
  yield delay(2000)
  yield put(credentialsLoadAction())
  yield delay(3000)
  yield put(credentialsLoadAction())
  yield delay(5000)
  yield put(credentialsLoadAction())
}

function* credentialsUpdateSaga(action) {
  yield put(callPutApiAction(`/api/credentials/${action.payload.id}`, action.payload, credentialsUpdateOkAction))
}

function* credentialsDeleteSaga(action) {
  yield put(callDeleteApiAction('/api/credentials/', action.payload.id, credentialsDeleteOkAction))
}

function* refreshSaga(action) {
  const { pathname } = action.payload
  if (pathname === '/credentials') {
    yield put(credentialsLoadAction())
  }
}

export function* credentialsSaga() {
  yield all([
    yield takeLatest(LOGIN_SUCCESS, credentialsLoadSaga),
    yield takeLatest(CREDENTIALS_LOAD, credentialsLoadSaga),
    yield takeEvery(CREDENTIALS_CREATE, credentialsCreateSaga),
    yield takeEvery(CREDENTIALS_CREATE_OK, credentialsCreateOkSaga),
    yield takeEvery(CREDENTIALS_UPDATE, credentialsUpdateSaga),
    yield takeEvery(CREDENTIALS_UPDATE_OK, credentialsCreateOkSaga),
    yield takeEvery(CREDENTIALS_DELETE, credentialsDeleteSaga),
    yield takeEvery(LOCATION_CHANGE, refreshSaga)
  ])
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [CREDENTIALS_LOAD_OK]: (state, action) => Object.assign({}, state, { list: action.payload }),
  [CREDENTIALS_DELETE_OK]: (state, action) => {
    const listNew = state.list.filter(cr => cr.id !== action.payload.id)
    return Object.assign({}, state, { list: [...listNew] })
  },
  [CREDENTIALS_CREATE]: (state) => Object.assign({}, state, { inProgress: true }),
  [CREDENTIALS_CREATE_OK]: (state, action) => (
    Object.assign({}, state, { list: [...state.list, action.payload] }, { inProgress: false })
  ),
  [CREDENTIALS_CREATE_ERROR]: (state) => Object.assign({}, state, { inProgress: false }),
  [CREDENTIALS_UPDATE_OK]: (state, action) => {
    const listNew = state.list.map(cr => {
      if (cr.id === action.payload.id) {
        return { ...cr, ...action.payload }
      }
      return cr
    })
    return Object.assign({}, state, { list: [...listNew] })
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  inProgress: false
}

export default function (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
