import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import i18n from '../../translations/i18n'
import BacktestFormScreen from './backtest-form.screen'
import { backtestSetStatusAction, backtestStartAction } from '../backtest.module'

class BacktestFormContainer extends PureComponent {
  render() {
    return (
      <div>
        <BacktestFormScreen {...this.props} />
      </div>
    )
  }
}

// TODO: Move it to some global space for simple reusing
function scrollToTop() {
  if (document.body.scrollTop !== 0 || document.documentElement.scrollTop !== 0) {
    window.scrollBy(0, -50)
    requestAnimationFrame(scrollToTop)
  }
}

const mapStateToProps = (state) => ({
  i18next: i18n(),
  currencies: state.price.currencies,
  isActive: state.backtest.displayForm
})

const mapDispatchToProps = (dispatch) => ({
  activate: () => dispatch(backtestSetStatusAction(true)),
  deactivate: () => dispatch(backtestSetStatusAction(false)),
  run: values => {
    scrollToTop()
    dispatch(backtestStartAction(values))
  }
})


export default connect(mapStateToProps, mapDispatchToProps)(BacktestFormContainer)
