import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { fileToUploadChooseAction, historyUploadAction } from '../history.module'
import HistoryFormScreen from './history-form.screen'
import i18n from '../../translations/i18n'

const getBase64 = (file, cb) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = function () {
    cb(reader.result)
  }
}

class HistoryFormContainer extends PureComponent {
  render() {
    return (
      <div>
        <HistoryFormScreen {...this.props} />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  exchangePairs: state.price.exchangePairs,
  i18next: i18n()
})

const mapDispatchToProps = (dispatch) => ({
  uploadHistory: values => dispatch(historyUploadAction(values.exchangePair)),
  fileToUploadChange: file => {
    getBase64(file, (base64Result) => {
      // remove string at the beginning data:text/csv;base64,
      dispatch(fileToUploadChooseAction(base64Result.substr(21)))
    })
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(HistoryFormContainer)
