import {
  all, put, select, takeEvery
} from 'redux-saga/effects'
import { priceDataLoadAction } from '../price/price.module'


// ------------------------------------
// Constants
// ------------------------------------
const GRAPH_INIT = 'GRAPH_INIT'
const GRAPH_CLEAR = 'GRAPH_CLEAR'
export const GRAPH_SET_LINE_VISIBILITY = 'GRAPH_SET_LINE_VISIBILITY'
const GRAPH_SET_TIME_RANGE = 'GRAPH_SET_TIME_RANGE'
const GRAPH_LOAD_TIME_RANGE = 'GRAPH_LOAD_TIME_RANGE'
const GRAPH_ADD_DATA = 'GRAPH_ADD_DATA'

// ------------------------------------
// Actions
// ------------------------------------
export const graphInitAction = (payload) => ({
  type: GRAPH_INIT,
  payload
})
export const graphClearAction = (name) => ({
  type: GRAPH_CLEAR,
  payload: {
    name
  }
})
export const graphAddDataAction = (name, data) => ({
  type: GRAPH_ADD_DATA,
  payload: {
    name,
    data
  }
})
export const graphSetLineVisibilityAction = (payload) => ({
  type: GRAPH_SET_LINE_VISIBILITY,
  payload
})
export const graphSetTimeRangeAction = (name, from, to) => ({
  type: GRAPH_SET_TIME_RANGE,
  payload: {
    name,
    from,
    to
  }
})
export const graphLoadTimeRangeAction = (name, from, to) => ({
  type: GRAPH_LOAD_TIME_RANGE,
  payload: {
    name,
    from,
    to
  }
})

// ------------------------------------
// Sagas
// ------------------------------------
function* graphSetLineVisibilitySaga(action) {
  const { graphName, value, code } = action.payload
  const { showPrices, from, to } = yield select((state) => state.graphs[graphName])
  const exchangePairs = yield select((state) => state.price.exchangePairs)
  if (showPrices && value && exchangePairs.map(ep => ep.code).includes(code)) {
    yield put(priceDataLoadAction(
      code,
      from,
      to,
      (priceData) => graphAddDataAction(graphName, priceData.data)
    ))
  }
}

function* graphLoadTimeRangeSaga(action) {
  const { name, from, to } = action.payload
  const { onTimeRangeChangedAction, visibleLines } = yield select((state) => state.graphs[name])
  if (onTimeRangeChangedAction) {
    yield put(onTimeRangeChangedAction(from, to))
  }
  const exchangePairs = yield select((state) => state.price.exchangePairs)
  const selectedPrice = visibleLines.filter(line => exchangePairs.map(ep => ep.code).includes(line))
  yield all(selectedPrice.map(code => put(priceDataLoadAction(
    code,
    from,
    to,
    (priceData) => graphAddDataAction(name, priceData.data)
  ))))
}

export function* graphSaga() {
  yield all([
    yield takeEvery(GRAPH_SET_LINE_VISIBILITY, graphSetLineVisibilitySaga),
    yield takeEvery(GRAPH_LOAD_TIME_RANGE, graphLoadTimeRangeSaga)
  ])
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}

// ------------------------------------
// Action Handlers
// ------------------------------------

const findNames = (data) => {
  if (data) {
    const names = new Set()
    data.forEach(item => Object.keys(item).filter(name => name !== 'epochSecond').forEach(name => names.add(name)))
    return [...names]
  }
  return []
}
const tradesFirstComparator = (a, b) => {
  if (a === 'buy') return -1
  if (b === 'buy') return 1
  if (a === 'sell') return -1
  if (b === 'sell') return 1
  return a.localeCompare(b)
}

const containsWithEpoch = (array, item) => array.map(e => e.epochSecond).includes(item.epochSecond)

const mergeArraysCompletely = (array1, array2) => {
  if (!array1 && array2) {
    return array2
  }
  if (array1 && !array2) {
    return array1
  }
  const array1Unique = array1.filter(item => !containsWithEpoch(array2, item))
  const array2Unique = array2.filter(item => !containsWithEpoch(array1, item))
  const combinedItems = array1
    .filter(item => containsWithEpoch(array2, item))
    .map(itemArray1 => {
      const itemArray2 = array2.find(item => itemArray1.epochSecond === item.epochSecond)
      return { ...itemArray1, ...itemArray2 }
    })

  return [...array1Unique, ...array2Unique, ...combinedItems].sort((a, b) => a.epochSecond - b.epochSecond)
}

const ACTION_HANDLERS = {
  [GRAPH_INIT]: (state, action) => {
    const newState = Object.assign({}, state)
    newState[action.payload.name] = {
      ...action.payload,
      lineData: [],
      lineNames: [],
      visibleLines: []
    }
    return newState
  },
  [GRAPH_ADD_DATA]: (state, action) => {
    const { name, data } = action.payload
    const newState = Object.assign({}, state)
    const lineNames = findNames(data)
    newState[name].lineData = mergeArraysCompletely(newState[name].lineData, data)
    lineNames
      .filter(lineName => !newState[name].lineNames.includes(lineName))
      .forEach(lineName => {
        newState[name].lineNames.push(lineName)
        if (!newState[name].visibleLines.includes(lineName)) {
          newState[name].visibleLines.push(lineName)
        }
      })
    newState[name].lineNames = newState[name].lineNames.sort(tradesFirstComparator)
    return newState
  },
  [GRAPH_SET_LINE_VISIBILITY]: (state, action) => {
    const newState = Object.assign({}, state)
    const graphState = newState[action.payload.graphName]
    if (action.payload.value && !graphState.visibleLines.includes(action.payload.code)) {
      graphState.visibleLines.push(action.payload.code)
    } else if (!action.payload.value && graphState.visibleLines.includes(action.payload.code)) {
      const index = graphState.visibleLines.indexOf(action.payload.code)
      graphState.visibleLines.splice(index, 1)
    }
    return newState
  },
  [GRAPH_SET_TIME_RANGE]: (state, action) => {
    const newState = Object.assign({}, state)
    const graphState = newState[action.payload.name]
    graphState.from = action.payload.from
    graphState.to = action.payload.to
    return newState
  },
  [GRAPH_CLEAR]: (state, action) => {
    const newState = {}
    Object.keys(state)
      .filter(key => key !== action.payload.name)
      .forEach(key => {
        newState[key] = Object.assign({}, state[key])
      })
    return newState
  }
}

export default function (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
