import { all, put, takeEvery } from 'redux-saga/effects'
import Notifications from 'react-notification-system-redux'

// ------------------------------------
// Constants
// ------------------------------------
const TOASTR_SUCCESS = 'TOASTR_SUCCESS'
const TOASTR_INFO = 'TOASTR_INFO'
const TOASTR_WARNING = 'TOASTR_WARNING'
const TOASTR_ERROR = 'TOASTR_ERROR'

// ------------------------------------
// Actions
// ------------------------------------
export const toastrSuccessAction = (msg, title) => ({
  type: TOASTR_SUCCESS,
  payload: {
    title,
    msg
  }
})
export const toastrInfoAction = (msg, title) => ({
  type: TOASTR_INFO,
  payload: {
    title,
    msg
  }
})
export const toastrWarningAction = (msg, title) => ({
  type: TOASTR_WARNING,
  payload: {
    title,
    msg
  }
})
export const toastrErrorAction = (msg, title) => ({
  type: TOASTR_ERROR,
  payload: {
    title,
    msg
  }
})

// ------------------------------------
// Sagas
// ------------------------------------
function* toastrSuccessSaga(action) {
  const notification = {
    title: action.payload.title ? action.payload.title : 'Success',
    message: action.payload.msg,
    position: 'tr',
    autoDismiss: 2
  }
  yield put(Notifications.success(notification))
}

function* toastrInfoSaga(action) {
  const notification = {
    title: action.payload.title ? action.payload.title : 'Info',
    message: action.payload.msg,
    position: 'tr',
    autoDismiss: 2
  }
  yield put(Notifications.info(notification))
}

function* toastrWarningSaga(action) {
  const notification = {
    title: action.payload.title ? action.payload.title : 'Warning',
    message: action.payload.msg,
    position: 'tr',
    autoDismiss: 2
  }
  yield put(Notifications.warning(notification))
}

function* toastrErrorSaga(action) {
  const notification = {
    title: action.payload.title ? action.payload.title : 'Error',
    message: action.payload.msg,
    position: 'tr',
    autoDismiss: 2
  }
  yield put(Notifications.error(notification))
}

export function* toastrSaga() {
  yield all([
    yield takeEvery(TOASTR_SUCCESS, toastrSuccessSaga),
    yield takeEvery(TOASTR_INFO, toastrInfoSaga),
    yield takeEvery(TOASTR_WARNING, toastrWarningSaga),
    yield takeEvery(TOASTR_ERROR, toastrErrorSaga)
  ])
}
