import React from 'react'
import { Link } from 'react-router-dom'
import Confirm from '../../confirm'

const formatNumber = (num, fractions = 4) => new Intl.NumberFormat(navigator.languages, { maximumFractionDigits: fractions }).format(num)

const StrategySummaryScreen = ({
  strategy, definitions, credentialsList, changeStatusStrategy, showDetail, i18next
}) => {
  if (strategy && credentialsList) {
    const definition = definitions && definitions.find(d => d.id === strategy.definitionId)
    const credentials = credentialsList.find(cr => cr.id === strategy.credentialsId)
    if (definition && credentials) {
      return (
        <div className='col-md-12'>

          <Confirm
            id={`confirm-status-${strategy.id}`}
            title={i18next.t('app.strategy.summary.statusChangeTitle')}
            body={strategy.status === 'RUNNING'
              ? i18next.t('app.strategy.summary.stopBody', { name: strategy.name })
              : i18next.t('app.strategy.summary.startBody', { name: strategy.name })}
            confirmAction={() => changeStatusStrategy(strategy)}
          />

          <div className='box box-info'>
            <div className='box-header with-border'>
              <h3 className='box-title'>{strategy && strategy.name}</h3>

              {showDetail && (
              <Link
                to={`/strategies/${strategy.id}`}
                className='btn btn-xs btn-info pull-right'
                style={{ marginRight: '5px' }}
                title={i18next.t('app.strategy.summary.detail')}
              >
                {i18next.t('app.strategy.summary.detail')}
              </Link>
              )}
              <Link
                to={`/strategies/form/${strategy.id}`}
                className='btn btn-xs btn-default pull-right'
                style={{ marginRight: '5px' }}
                title={i18next.t('app.strategy.summary.edit')}
              >
                <i className='fa fa-edit' />
              </Link>
              <button
                type='button'
                data-toggle='modal'
                data-target={`#confirm-status-${strategy.id}`}
                className='btn btn-xs btn-default pull-right'
                style={{ marginRight: '5px' }}
                title={strategy.status === 'RUNNING'
                  ? i18next.t('app.strategy.summary.start')
                  : i18next.t('app.strategy.summary.stop')}
              >
                {strategy.status === 'RUNNING' ? <i className='fa fa-stop' /> : <i className='fa fa-play' />}
              </button>
            </div>
            <div className='box-body'>
              <div className='col-md-4'>
                <div className='col-md-12'>
                  <label className='col-md-6'>{i18next.t('app.strategy.summary.status')}</label>
                  <div className='col-md-6'>
                    <span
                      className={strategy && strategy.status === 'RUNNING'
                        ? 'label label-success'
                        : 'label label-danger'}
                    >
                      {strategy && i18next.t(`app.strategy.common.status.values.${strategy.status}`)}
                    </span>
                  </div>
                </div>
                <div className='col-md-12'>
                  <label className='col-md-6'>{i18next.t('app.strategy.summary.algorithm')}</label>
                  <div className='col-md-6'>{definition && definition.name}</div>
                </div>
                <div className='col-md-12'>
                  <label className='col-md-6'>{i18next.t('app.strategy.summary.frequency')}</label>
                  <div
                    className='col-md-6'
                  >
                    {strategy && i18next.t(`app.strategy.common.frequency.values.${strategy.frequency}`)}
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='col-md-12'>
                  <label className='col-md-6'>{i18next.t('app.strategy.summary.balances')}</label>
                  {Object.keys(credentials.wallet)
                    .filter(currency => credentials.wallet[currency].total > 0.0001)
                    .map(currency => (
                      <span key={currency}>
                        <div className='col-md-6'>
                          {currency.toUpperCase()}
                          :
                          {' '}
                          {formatNumber(credentials.wallet[currency].total)}
                        </div>
                        <div className='col-md-6' />
                      </span>))

                  }
                </div>
              </div>
              <div className='col-md-4'>
                <div className='col-md-12'>
                  <label className='col-md-6'>{i18next.t('app.strategy.summary.totalValue')}</label>
                  <div className='col-md-6'>
USD:
                    {formatNumber(credentials.totalValues.usd, 2)}
                  </div>
                </div>
                <div className='col-md-12'>
                  <label className='col-md-6' />
                  <div className='col-md-6'>
EUR:
                    {formatNumber(credentials.totalValues.eur, 2)}
                  </div>
                </div>
                <div className='col-md-12'>
                  <label className='col-md-6' />
                  <div className='col-md-6'>
BTC:
                    {formatNumber(credentials.totalValues.btc, 5)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
  return (<div />)
}
export default StrategySummaryScreen
