import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import i18n from '../../translations/i18n'
import BacktestInfoScreen from './backtest-info.screen'

class BacktestInfoContainer extends PureComponent {
  render() {
    return (
      <BacktestInfoScreen {...this.props} />
    )
  }
}

const mapStateToProps = (state) => ({
  backtest: state.backtest.info,
  isActive: state.backtest.displayResults,
  i18next: i18n()
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(BacktestInfoContainer)
