import React from 'react'
import { Link } from 'react-router-dom'
import Confirm from '../../confirm'

const CredentialsListScreen = ({
  credentials, loadCredentials, deleteCredentials, i18next
}) => (
  <div>
    <section className='content'>
      {credentials && credentials.map(cr => (
        <Confirm
          key={cr.id}
          id={`confirm-${cr.id}`}
          title='Confirm remove'
          body={`Do you really want to remove credentials for ${cr.accountAlias} account?`}
          confirmAction={() => deleteCredentials(cr.id)}
        />
      ))}

      <div className='row'>
        <div className='col-md-6'>
          <div className='box box-info'>
            <div className='box-header'>
              <h3 className='box-title'>{i18next.t('app.credentials.list.title')}</h3>
              <div className='box-tools pull-right'>
                <button type='button' className='btn btn-box-tool' onClick={() => loadCredentials()}>
                  <i className='fa fa-refresh' />
                </button>
              </div>
            </div>
            <div className='box-body'>
              <div className='table-responsive'>
                <table className='table no-margin'>
                  <thead>
                    <tr>
                      <th>{i18next.t('app.credentials.list.accountAlias')}</th>
                      <th>{i18next.t('app.credentials.list.customerId')}</th>
                      <th>{i18next.t('app.credentials.list.status.name')}</th>
                      <th>{i18next.t('actions')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {credentials && credentials.map(cr => (
                      <tr key={cr.id}>
                        <td>{cr.accountAlias}</td>
                        <td>{cr.customerId}</td>
                        <td>{i18next.t(`app.credentials.list.status.values.${cr.status}`)}</td>
                        <td>
                          <Link to={`/credentials/form/${cr.id}`} className='btn btn-box-tool'>
                            <i className='fa fa-edit' />
                          </Link>
                          <button
                            type='button'
                            data-toggle='modal'
                            data-target={`#confirm-${cr.id}`}
                            className='btn btn-box-tool'
                          >
                            <i className='fa fa-times' />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='box-footer clearfix'>
              <Link to='/credentials/form' className='btn btn-sm btn-info btn-flat pull-left'>
                {i18next.t('new')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

export default CredentialsListScreen
