import auth0 from 'auth0-js'
import { END, eventChannel } from 'redux-saga'

export default class Auth {
  // url = 'http://localhost:3000'
  // url = 'https://www.coinacrobat.com'
  url = `${window.location.protocol}//${window.location.host}`;

  auth0 = new auth0.WebAuth({
    domain: 'osomahe.eu.auth0.com',
    clientID: 'xtzakbfwobQd3W153PS92AmTQaFSWKaj',
    redirectUri: `${this.url}/callback`,
    audience: 'https://osomahe.eu.auth0.com/userinfo',
    responseType: 'token id_token',
    scope: 'openid'
  });

  constructor() {
    this.login = this.login.bind(this)
    this.handleAuthentication = this.handleAuthentication.bind(this)
  }

  login() {
    this.auth0.authorize()
  }

  handleAuthentication(callback) {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        callback(authResult)
      }
    })
  }

  renewToken() {
    return eventChannel(emitter => {
      this.auth0.checkSession({}, (error, result) => {
        if (error) {
          emitter(END)
        } else {
          emitter(result)
        }
      })
      return () => {
        this.logout()
      }
    })
  }

  logout() {
    this.auth0.logout({ returnTo: this.url })
  }
}
