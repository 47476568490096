import React from 'react'
import 'rc-slider/assets/index.css'
import Slider, { createSliderWithTooltip } from 'rc-slider'
import { formatDateTime } from '../common'


const SliderWithTooltip = createSliderWithTooltip(Slider)

const countMax = (from, to) => (to - from)

const positionToEpoch = (value, from, to) => {
  const diff = (to - from) * value / countMax(from, to)
  return to - diff
}

const LogSliderScreen = ({
  from, to, onAfterChange
}) => (
  <SliderWithTooltip
    style={{ height: '30em' }}
    vertical
    min={0}
    max={countMax(from, to)}
    defaultValue={0}
    tipFormatter={value => formatDateTime(positionToEpoch(value, from, to))}
    tipProps={{ placement: 'right' }}
    onAfterChange={value => onAfterChange(positionToEpoch(value, from, to))}
    trackStyle={[{ backgroundColor: '#e9e9e9' }]}
  />
)

export default LogSliderScreen
