import moment from 'moment'
import {
  all, put, select, takeEvery, takeLatest, delay
} from 'redux-saga/effects'
import { LOCATION_CHANGE, push } from 'react-router-redux'
import { change } from 'redux-form'

import { LOGIN_SUCCESS } from '../auth'
import {
  callDeleteApiAction, callGetApiAction, callPostApiAction, callPutApiAction
} from '../api'
import { graphAddDataAction, graphClearAction, graphInitAction } from '../graph/graph.module'
import {
  logAddRecordsAction, logAddTradesAction, logClearAction, logInitAction
} from '../log/log.module'

// ------------------------------------
// Constants
// ------------------------------------
const STRATEGY_LOAD = 'STRATEGY_LOAD'
export const STRATEGY_LOAD_OK = 'STRATEGY_LOAD_OK'

const STRATEGY_CREATE = 'STRATEGY_CREATE'
const STRATEGY_CREATE_OK = 'STRATEGY_CREATE_OK'

const STRATEGY_UPDATE = 'STRATEGY_UPDATE'
const STRATEGY_UPDATE_OK = 'STRATEGY_UPDATE_OK'

const STRATEGY_DELETE = 'STRATEGY_DELETE'
const STRATEGY_DELETE_OK = 'STRATEGY_DELETE_OK'

const STRATEGY_START = 'STRATEGY_START'
const STRATEGY_START_OK = 'STRATEGY_START_OK'

const STRATEGY_STOP = 'STRATEGY_STOP'
const STRATEGY_STOP_OK = 'STRATEGY_STOP_OK'

const STRATEGY_CREATE_START = 'STRATEGY_CREATE_START'
const STRATEGY_CREATE_START_OK = 'STRATEGY_CREATE_START_OK'

const CREDENTIALS_CHANGE = 'CREDENTIALS_CHANGE'

const STRATEGY_GRAPH = 'STRATEGY_GRAPH'
const STRATEGY_GRAPH_OK = 'STRATEGY_GRAPH_OK'
const STRATEGY_GRAPH_TRADES_OK = 'STRATEGY_GRAPH_TRADES_OK'
const STRATEGY_LOG = 'STRATEGY_LOG'
const STRATEGY_LOG_OK = 'STRATEGY_LOG_OK'
const STRATEGY_LOG_TRADES_OK = 'STRATEGY_LOG_TRADES_OK'

// ------------------------------------
// Actions
// ------------------------------------
export const strategyLoadAction = () => ({
  type: STRATEGY_LOAD
})
const strategyLoadOkAction = (payload) => ({
  type: STRATEGY_LOAD_OK,
  payload
})

export const strategySaveAction = (payload) => ({
  type: payload.id ? STRATEGY_UPDATE : STRATEGY_CREATE,
  payload
})
const strategyCreateOkAction = (payload) => ({
  type: STRATEGY_CREATE_OK,
  payload
})
export const strategyCreateStartAction = (payload) => ({
  type: STRATEGY_CREATE_START,
  payload
})
const strategyCreateStartOkAction = (payload) => ({
  type: STRATEGY_CREATE_START_OK,
  payload
})
const strategyUpdateOkAction = (payload) => ({
  type: STRATEGY_UPDATE_OK,
  payload
})
export const strategyDeleteAction = (id) => ({
  type: STRATEGY_DELETE,
  payload: {
    id
  }
})
const strategyDeleteOkAction = (id) => ({
  type: STRATEGY_DELETE_OK,
  payload: {
    id
  }
})

export const credentialsChangeAction = (credentialsId) => ({
  type: CREDENTIALS_CHANGE,
  payload: {
    credentialsId
  }
})

export const strategyStartAction = (id) => ({
  type: STRATEGY_START,
  payload: {
    id
  }
})
const strategyStartOkAction = (payload) => ({
  type: STRATEGY_START_OK,
  payload
})
export const strategyStopAction = (id) => ({
  type: STRATEGY_STOP,
  payload: {
    id
  }
})
const strategyStopOkAction = (payload) => ({
  type: STRATEGY_STOP_OK,
  payload
})

const strategyGraphAction = (payload) => ({
  type: STRATEGY_GRAPH,
  payload
})
const strategyGraphOkAction = (payload) => ({
  type: STRATEGY_GRAPH_OK,
  payload
})
const strategyLogAction = (id, from, to) => ({
  type: STRATEGY_LOG,
  payload: {
    id,
    from,
    to
  }
})
const strategyLogOkAction = (payload) => ({
  type: STRATEGY_LOG_OK,
  payload
})
const strategyGraphTradesOkAction = (payload) => ({
  type: STRATEGY_GRAPH_TRADES_OK,
  payload
})
const strategyLogTradesOkAction = (payload) => ({
  type: STRATEGY_LOG_TRADES_OK,
  payload
})

// ------------------------------------
// Sagas
// ------------------------------------
function* strategyLoadSaga() {
  yield put(callGetApiAction('/api/strategies', strategyLoadOkAction))
}

const convertToPayload = (input) => {
  const output = {}
  Object.keys(input).filter(key => key !== 'params' && key !== 'backtest').forEach(key => {
    output[key] = input[key]
  })
  output.params = {}
  Object.keys(input.params[input.definitionId]).forEach(key => {
    output.params[key] = input.params[input.definitionId][key]
  })
  return output
}

function* strategyCreateSaga(action) {
  yield put(callPostApiAction('/api/strategies', convertToPayload(action.payload), strategyCreateOkAction))
}

function* strategyCreateStartSaga(action) {
  yield put(callPostApiAction('/api/strategies/simple', action.payload, strategyCreateStartOkAction))
}

function* strategyCreateStartOkSaga() {
  yield delay(1000)
  yield put(strategyLoadAction())
  yield delay(2000)
  yield put(strategyLoadAction())
  yield delay(3000)
  yield put(strategyLoadAction())
  yield delay(5000)
  yield put(strategyLoadAction())
}

function* strategyCreateOkSaga() {
  yield put(push('/strategies'))
  yield delay(1000)
  yield put(strategyLoadAction())
  yield delay(2000)
  yield put(strategyLoadAction())
  yield delay(3000)
  yield put(strategyLoadAction())
  yield delay(5000)
  yield put(strategyLoadAction())
}

function* strategyUpdateSaga(action) {
  const { payload } = action
  yield put(callPutApiAction(`/api/strategies/${payload.id}`, convertToPayload(payload), strategyUpdateOkAction))
}

function* strategyDeleteSaga(action) {
  yield put(callDeleteApiAction('/api/strategies/', action.payload.id, strategyDeleteOkAction))
}

function* credentialsChangeSaga(action) {
  if (action.payload.credentialsId) {
    const wallet = yield select(
      (state) => state.credentials.list.find(cr => cr.id === action.payload.credentialsId).wallet
    )
    const keys = Object.keys(wallet)
    for (let i = 0; i < keys.length; i += 1) {
      yield put(change('strategy', `backtest.balances.${keys[i]}`, wallet[keys[i]].total))
    }
  }
}


function* strategyStartSaga(action) {
  yield put(callPutApiAction(`/api/strategies/${action.payload.id}/start`, action.payload, strategyStartOkAction))
}

function* strategyStopSaga(action) {
  yield put(callPutApiAction(`/api/strategies/${action.payload.id}/stop`, action.payload, strategyStopOkAction))
}

function* strategyDetailSaga(action) {
  const { pathname } = action.payload
  if (pathname.startsWith('/strategies/') && !pathname.includes('/form')) {
    const index = pathname.lastIndexOf('/')
    const id = pathname.substring(index + 1)
    const strategy = yield select((state) => state.strategy.list.find(s => s.id === id))

    const epochTwoWeeksAgo = moment().subtract(2, 'weeks').unix()
    const createdAtEpoch = moment(strategy.createdAt).unix()
    const from = createdAtEpoch < epochTwoWeeksAgo ? epochTwoWeeksAgo : createdAtEpoch
    const graphInitPayload = {
      name: 'strategy',
      showPrices: true,
      fromMin: createdAtEpoch,
      from,
      to: moment().unix(),
      toMax: moment().unix(),
      onTimeRangeChangedAction: (from, to) => strategyGraphAction({ id, from, to })
    }

    const logInitPayload = {
      name: 'strategy',
      frequency: strategy.frequency,
      from: createdAtEpoch,
      to: moment().unix(),
      onSelectedEpochChange: (from, to) => strategyLogAction(id, from, to),
      selectedEpochFrom: moment().subtract(14, 'days').unix(),
      selectedEpochTo: moment().unix()
    }
    yield all([
      yield put(graphClearAction('strategy')),
      yield put(logClearAction('strategy')),
      yield put(graphInitAction(graphInitPayload)),
      yield put(logInitAction(logInitPayload)),
      yield put(strategyGraphAction({ id, from, to: moment().unix() })),
      yield put(strategyLogAction(id, logInitPayload.selectedEpochFrom, logInitPayload.selectedEpochTo))
    ])
  }
}

function* strategyGraphSaga(action) {
  const { id, from, to } = action.payload
  yield all([
    yield put(callGetApiAction(`/api/strategies/${id}/graphs?from=${from}&to=${to}`, strategyGraphOkAction)),
    yield put(callGetApiAction(`/api/strategies/${id}/trades?from=${from}&to=${to}`, strategyGraphTradesOkAction))
  ])
}

function* strategyGraphOkSaga(action) {
  yield put(graphAddDataAction('strategy', action.payload))
}

function* strategyGraphTradesOkSaga(action) {
  const payload = action.payload.map(trx => {
    const key = trx.operation === 'BUY' ? 'buy' : 'sell'
    const item = { epochSecond: trx.epochSecond }
    item[key] = trx.price
    return item
  })
  yield put(graphAddDataAction('strategy', payload))
}

function* strategyLogSaga(action) {
  const { id, from, to } = action.payload
  yield all([
    yield put(callGetApiAction(`/api/strategies/${id}/logs?from=${from}&to=${to}`, strategyLogOkAction)),
    yield put(callGetApiAction(`/api/strategies/${id}/trades?from=${from}&to=${to}`, strategyLogTradesOkAction))
  ])
}

function* strategyLogOkSaga(action) {
  yield put(logAddRecordsAction('strategy', action.payload))
}

function* strategyLogTradesOkSaga(action) {
  yield put(logAddTradesAction('strategy', action.payload))
}

function* refreshSaga(action) {
  const { pathname } = action.payload
  if (pathname === '/strategies') {
    yield put(strategyLoadAction())
  }
}

export function* strategySaga() {
  yield all([
    yield takeLatest(LOGIN_SUCCESS, strategyLoadSaga),
    yield takeLatest(STRATEGY_LOAD, strategyLoadSaga),
    yield takeEvery(STRATEGY_CREATE, strategyCreateSaga),
    yield takeEvery(STRATEGY_CREATE_OK, strategyCreateOkSaga),
    yield takeEvery(STRATEGY_CREATE_START, strategyCreateStartSaga),
    yield takeEvery(STRATEGY_CREATE_START_OK, strategyCreateStartOkSaga),
    yield takeEvery(STRATEGY_UPDATE, strategyUpdateSaga),
    yield takeEvery(STRATEGY_UPDATE_OK, strategyCreateOkSaga),
    yield takeEvery(STRATEGY_DELETE, strategyDeleteSaga),
    yield takeEvery(STRATEGY_START, strategyStartSaga),
    yield takeEvery(STRATEGY_STOP, strategyStopSaga),
    yield takeEvery(CREDENTIALS_CHANGE, credentialsChangeSaga),
    yield takeEvery(LOCATION_CHANGE, strategyDetailSaga),
    yield takeEvery(STRATEGY_GRAPH, strategyGraphSaga),
    yield takeEvery(STRATEGY_GRAPH_OK, strategyGraphOkSaga),
    yield takeEvery(STRATEGY_GRAPH_TRADES_OK, strategyGraphTradesOkSaga),
    yield takeEvery(STRATEGY_LOG, strategyLogSaga),
    yield takeEvery(STRATEGY_LOG_OK, strategyLogOkSaga),
    yield takeEvery(STRATEGY_LOG_TRADES_OK, strategyLogTradesOkSaga),
    yield takeEvery(LOCATION_CHANGE, refreshSaga)
  ])
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [STRATEGY_LOAD_OK]: (state, action) => Object.assign({}, state, { list: action.payload }),
  [STRATEGY_DELETE_OK]: (state, action) => {
    const listNew = state.list.filter(s => s.id !== action.payload.id)
    return Object.assign({}, state, { list: listNew })
  },
  [STRATEGY_CREATE_OK]: (state, action) => Object.assign({}, state, {
    list: [...state.list,
      {
        ...action.payload,
        status: 'STOPPED',
        createdAt: { epochSecond: moment().unix() }
      }]
  }),
  [STRATEGY_CREATE_START_OK]: (state, action) => Object.assign({}, state, {
    list: [...state.list,
      {
        ...action.payload,
        status: 'RUNNING',
        createdAt: { epochSecond: moment().unix() }
      }]
  }),
  [STRATEGY_UPDATE_OK]: (state, action) => {
    const listNew = state.list.map(s => {
      if (s.id === action.payload.id) {
        return { ...s, ...action.payload }
      }
      return s
    })
    return Object.assign({}, state, { list: listNew })
  },
  [STRATEGY_STOP_OK]: (state, action) => {
    const newList = Object.assign([], state.list).map(s => {
      if (s.id === action.payload.id) {
        return { ...s, status: 'STOPPED' }
      }
      return s
    })
    return Object.assign({}, state, { list: newList })
  },
  [STRATEGY_START_OK]: (state, action) => {
    const newList = Object.assign([], state.list).map(s => {
      if (s.id === action.payload.id) {
        return { ...s, status: 'RUNNING' }
      }
      return s
    })
    return Object.assign({}, state, { list: newList })
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  list: []
}

export default function (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
