import React, { Component } from 'react'
import { connect } from 'react-redux'

import CallbackScreen from './callback.screen'
import Auth from '../auth'
import { loginSuccessAction } from '../auth.module'

class CallbackContainer extends Component {
    auth = new Auth();

    handleAuthentication() {
      const { loginSuccess } = this.props
      this.auth.handleAuthentication(loginSuccess)
    }

    render() {
      this.handleAuthentication(this.props)
      return (
        <CallbackScreen {...this.props} />
      )
    }
}


const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => ({
  loginSuccess: (authResult) => dispatch(loginSuccessAction(authResult))
})

export default connect(mapStateToProps, mapDispatchToProps)(CallbackContainer)
