import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import StrategySummaryScreen from './strategy-summary.screen'
import i18n from '../../translations/i18n'
import { strategyStartAction, strategyStopAction } from '../strategy.module'

class StrategySummaryContainer extends PureComponent {
  render() {
    return (
      <StrategySummaryScreen {...this.props} />
    )
  }
}

const mapStateToProps = (state) => ({
  definitions: state.definitions,
  credentialsList: state.credentials.list,
  i18next: i18n()
})

const mapDispatchToProps = (dispatch) => ({
  changeStatusStrategy: (strategy) => {
    const strategyStop = strategyStopAction(strategy.id)
    const strategyStart = strategyStartAction(strategy.id)

    dispatch(strategy.status === 'RUNNING' ? strategyStop : strategyStart)
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(StrategySummaryContainer)
