import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import DashboardScreen from './dashboard.screen'
import i18n from '../../translations/i18n'

class DashboardContainer extends PureComponent {
  render() {
    return (
      <DashboardScreen {...this.props} />
    )
  }
}

const mapStateToProps = (state) => ({
  i18next: i18n(),
  strategies: state.strategy.list
})


export default connect(mapStateToProps, null)(DashboardContainer)
