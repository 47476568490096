import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import UserListScreen from './user-list.screen'
import { userLoadAction } from '../user.module'
import i18n from '../../translations/i18n'

class UserListContainer extends PureComponent {
  render() {
    return (
      <UserListScreen {...this.props} />
    )
  }
}

const mapStateToProps = (state) => ({
  users: state.users.all,
  i18next: i18n()
})

const mapDispatchToProps = (dispatch) => ({
  loadUsers: () => dispatch(userLoadAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(UserListContainer)
