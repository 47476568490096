import React from 'react'
import { reduxForm, Field } from 'redux-form'

const DashboardNotificationsScreen = ({
  active, canActivate, handleSubmit, submitAction, switchAccept, i18next
}) => active && (
  <form onSubmit={handleSubmit(submitAction)}>
    <div className='col-md-12'>
      <div className='small-box bg-aqua'>
        <div className='inner'>
          <div className='box-header bg-aqua'>
            <h4 style={{ margin: 0 }}>
              <i className='fa fa-envelope-o' />
&nbsp;
              {i18next.t('app.dashboard.notifications.title')}
            </h4>
          </div>
          <div className='col-md-4'>
            {i18next.t('app.dashboard.notifications.description')}
          </div>
          <div className='col-md-4'>
            <div className='form-group'>
              <Field
                name='email'
                className='form-control'
                component='input'
                type='email'
                placeholder='firstname.lastname@gmail.com'
              />
            </div>
          </div>
          <div className='col-md-4'>
            <div className='form-group'>
              <input
                id='notification-accept'
                name='accept'
                type='checkbox'
                onChange={(event) => switchAccept(event.target.checked)}
              />
              <label htmlFor='notification-accept'>{i18next.t('app.dashboard.notifications.accept')}</label>
            </div>
          </div>

        </div>

        <span className='small-box-footer' style={{ clear: 'both' }}>
          <button
            type='submit'
            className='btn btn-success btn-flat small-box-footer'
            disabled={!canActivate}
            style={{ padding: '0.1em 1em' }}
          >
            {i18next.t('app.dashboard.notifications.submit')}
          </button>
        </span>
      </div>
    </div>

  </form>
)

export default reduxForm({ form: 'dashboard-notifications' })(DashboardNotificationsScreen)
